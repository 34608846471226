import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { AppRoutingModule, routes } from './app-routing.module';
import {
  BrowserModule,
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { LocalStorageService, TranslationLoaderService } from './core/services';
import { ROOT_LEVEL_MODULES } from './core/constants';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { provideStore, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@src/environments/environment';
import { MarkdownModule } from 'ngx-markdown';
import { ApiModule, Configuration, ConfigurationParameters } from './core/api';
import { AuthModule } from './features/auth/auth.module';
import { jwtInterceptorFn } from './core/interceptors';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor, TimerInterceptor} from '@src/app/core/interceptors';
import {AuthService, BaseNetworkService, HttpService} from '@src/app/core/services';
import {BaseUrlInterceptor, baseUrlInterceptorFn} from '@src/app/core/interceptors/base.url.interceptor';
import {LayoutModule} from '@src/app/features/layout/layout.module';
import {AuthGuard} from '@src/app/core/guards';
import {HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';

const apiConfigFactory = () => {
  const params: ConfigurationParameters = {
    basePath: '',
    withCredentials: true,
  };
  return new Configuration(params);
};

export const appConfig: ApplicationConfig = {
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: BaseUrlInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: JwtInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TimerInterceptor,
    //   multi: true,
    // },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        },
      },
    },
    LocalStorageService,
    BaseNetworkService,
    HttpService,
    AuthService,
    TranslationLoaderService,
    AuthGuard,
    provideRouter(routes),
    importProvidersFrom(
      LayoutModule,
      ...ROOT_LEVEL_MODULES,
      BrowserModule,
      AppRoutingModule,
      ApiModule.forRoot(apiConfigFactory),
      AuthModule,
      StoreModule.forRoot([], {}),
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
      }),
      // provideStore(appStore),
      // provideEffects({}),
      EffectsModule.forRoot([]),
      MarkdownModule.forRoot(),

    ),
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
      }),
    ),
    provideAnimations(),
    
    provideHttpClient(
      withInterceptors([jwtInterceptorFn, baseUrlInterceptorFn])
    ),
  ],
};
