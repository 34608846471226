import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as en from '../assets/i18n/en.json';
import * as fr from '../assets/i18n/fr.json';
import { SharedDataService } from './core/services';
import { destroy } from './core/shared/helpers/destroy';

import { AppConfigurations } from '@src/app/core/config/app.config';
import { Title } from '@angular/platform-browser';
import { AuthActions } from './features/auth/core/store';

// import { AuthLayoutModule } from './auth/authLayout.module';
// import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
// import { TimerInterceptor } from './core/interceptors/timer.interceptor';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterOutlet], // AuthLayoutModule
  // providers: [
  //   {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: JwtInterceptor,
  //     multi: true
  //   },
  //   {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: TimerInterceptor,
  //     multi: true
  //   }
  // ],
})
export class AppComponent implements OnInit {
  private destroy$ = destroy();
  public title = 'sid-customer-frontend';
  /**
   * Initializes a new instance of the class.
   *
   * @param {any} document - The document object.
   * @param {Object} platformId - The platform ID.
   * @param {Title} titleService - The title service.
   * @param {TranslateService} translate - The translation service.
   * @param {SharedDataService} sharedDataService - The shared data service.
   */
  constructor(
    private store: Store,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private translate: TranslateService,
    public sharedDataService: SharedDataService,
  ) {
    this.translate.setTranslation('en', en, true);
    this.translate.setTranslation('fr', fr, true);
    sharedDataService.appConfig$.pipe(this.destroy$()).subscribe({
      next: appConfig => {
        const lang =
          this.sharedDataService.localService.get('language', false)?.code?.toLowerCase() || 'en';
        const code = lang || 'en';
        if (code != this.translate.currentLang?.toLowerCase()) {
          this.translate.setDefaultLang(code);
          this.translate.use(code);
        }
        if (this.document.documentElement.lang?.toLowerCase() !== code) {
          this.document.documentElement.lang = code;
        }
      },
    });
    this.sharedDataService.dataModified.next({ component: 'base', isModified: false, data: null }); 
  }

  /**
   * Initializes the component and sets the page title based on the translation of 'PAGE_TITLE'.
   * Loads the Google Maps API if the platform is a browser and sets the map key based on the application configuration.
   */
  ngOnInit() {
    this.translate.get('PAGE_TITLE').subscribe(name => {
      this.titleService.setTitle(name);
    });
    this.store.dispatch(AuthActions.loginCheck());
  }
}
