import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { User } from './core/api';
import RoleEnum = User.RoleEnum;

import * as routesList from './core/constants/routes.constant';
import { AuthGuard } from './core/guards';
import { BaseComponent } from './features/layout/views/base/base.component';
import { ErrorPageComponent } from './core/shared/components/error-page/error-page.component';
export const routes: Routes = [
  // {
  //   path: '',
  //   canActivate: [AuthGuard],
  //   data: { isFromAuth: true },
  //   loadChildren: () => import('./features').then(m => m.LandingModule),
  // },

  {
    path: '',
    loadComponent: () => import('./features/views/views.component')
      .then((comp) => comp.ViewsComponent),
    children: [
      {
        path: '',
        loadChildren: () => import('./features/views/views.routes')
          .then((views) => views.routes),
      }
    ]

  },

  {
    path: routesList.AUTH_PATH,
    canActivate: [AuthGuard],
    data: { isFromAuth: true },
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
  },
  // {
  //   path: '',
  //   component: BaseComponent,
  //   // canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: routesList.DASHBOARD_PATH,
  //       loadChildren: () => import('./features').then(m => m.DashboardModule),
  //     },



  //     { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  //     // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
  //   ],
  // },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
    },
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent,
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
