{
  "PAGE_TITLE": "S.I.D",
  "APP_TITLE": "Senegal",
  "ENGLISH": "English",
  "FRENCH": "French",
  "WELCOME": "Welcome To Senegal",
  "COMPONENTS": {
    "DASHBOARD": "Dashboard",
    "USERS": {
      "USER_PROFILE": "User Profile",
      "PERFORMED_ON": "Performed On",
      "ABOUT": "About",
      "ACTOR": "Actor",
      "TIME": "Time",
      "DATE": "Date",
      "MODULE": "Module",
      "SUB_MODULE": "Sub Module",
      "UPLOAD_PHOTO": "Upload Photo",
      "UPDATE": "Update",
      "FIRST_NAME": "First Name",
      "LAST_NAME": "Last Name",
      "DATE_OF_BIRTH": "Date Of Birth",
      "GENDER": "Gender",
      "MALE": "Male",
      "FEMALE": "Female",
      "ROLE": "Role",
      "CREATION_DATE": "Creation date",
      "EMAIL_ADDRESS": "Email Address",
      "CONTACT_NUMBER": "Contact Number",
      "SELECT_ROLES": "Select Roles",
      "EMAIL": "Email",
      "USER_ID": "Employee ID",
      "LANDLINE_NUMBER": "Landline Number",
      "MOBILE_NUMBER": "Mobile Number",
      "SEARCH_BY_COUNTRY": "Search by Country Name or Code",
      "PASSWORD": "Password",
      "NEW_PASSWORD": "New Password",
      "PASSWORD_INFO": "At least 8 characters, one capital letter, one number, and one special character",
      "CHANGE_PASSWORD": "Change Password",
      "OFFICIAL_DETAILS": "Official Details",
      "OFFICIAL_CONTACT_NUMBER": "Official Contact Number",
      "OFFICIAL_EMAIL_ADDRESS": "Official Email Address",
      "DESIGNATION": "Designation",
      "USER_ADDRESS": "User’s Address",
      "COUNTRY": "Country",
      "SELECT_COUNTRY": "Select Country",
      "CITY": "City",
      "SELECT_CITY": "Select City",
      "COMPLETE_ADDRESS": "Complete Address",
      "USER_DOCUMENTS": "User’s Documents",
      "UPLOAD": "Upload",
      "DRIVING_LICENSE_NUMBER": "Driving License Number",
      "DRIVING_LICENSE_EXPIRY_DATE": "Driving License Expiry Date",
      "NATIONAL_IDENTITY_NUMBER": "National Identity Number",
      "UPLOADED_ON": "Uploaded on",
      "DOWNLOAD": "Download",
      "USER_ACTIVITIES": "User Activities",
      "IMPORT_FILE_NAME": "import-users-template",
      "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Users - {{date}} - {{time}}",
      "LENGTH_SIX_DIGITS": "Length: 6 Digits",
      "LENGTH_EIGHT_SIXTEEN_CHARACTERS": "Length: 8 ~ 16 characters",
      "ONE_CAPITAL": "At least 1 uppercase letter",
      "ONE_LOWER": "At least 1 lowercase letter",
      "ONE_NUMBER": "At least 1 number",
      "PASSENGER": "Passenger",
      "VALIDATOR": "Validator"
    },
    "STOPS": {
      "TITLE": "Stop Details",
      "ADD": "Add Stop",
      "IMPORT_FILE_NAME": "import-stop-template",
      "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Stops - {{date}} - {{time}}",
      "STATION": "Station",
      "FIELDS": {
        "NAME": "Stop Name",
        "ENTER_STOP_NAME": "Enter Stop Name",
        "ZONE_SUBZONE": "Zone / Sub-Zone",
        "DESCRIPTION": "Description",
        "LATITUDE": "Latitude",
        "LONGITUDE": "Longitude",
        "GEO_LOCATION": "Geo Location",
        "SELECT_GEO_LOCATION": "Select Geo Location",
        "STOP_ID": "ID",
        "ENTER_STOP_ID": "Enter Stop Id",
        "TYPE": "Type",
        "SELECT_TYPE": "Select Type"
      }
    },
    "ROLES": {
      "CREATE_TITLE": "Add Role",
      "TITLE": "Role Details",
      "ROLE": "Role",
      "PERMISSION_TITLE": "Assign Permissions",
      "ADD": "Add Stop",
      "FIELDS": {
        "NAME": "Role Name",
        "NAME_PLACEHOLDER": "i.e Driver",
        "DESCRIPTION_PLACEHOLDER": "i.e Driver",
        "DESCRIPTION": "Description",
        "LATITUDE": "Latitude",
        "LONGITUDE": "Longitude",
        "GEO_LOCATION": "Geo Location",
        "SELECT_GEO_LOCATION": "Select Geo Location"
      }
    },
    "LINES": {
      "CREATE_TITLE": "Add Line",
      "TITLE": "Line Details",
      "RETURN_TO_GARAGE": "Return to Garage",
      "BUS_PREPARATION": "Bus Preparation",
      "LINE_CONFIGURATION": "Line Configuration",
      "OUT_STOPS": "Out Stops",
      "IN_STOPS": "In Stops",
      "OUT_SEGMENTS": "Out Segments",
      "IN_SEGMENTS": "In Segments",
      "ADD": "Add Stop",
      "STOPS": "Stops",
      "FIELDS": {
        "NAME": "Line Name",
        "TYPE": "Line Type",
        "BY_STOPS": "By Stops",
        "MINUTES": "Minutes",
        "BY_SEGMENTS": "By Segments",
        "SELECT_TYPE": "Select Type",
        "NAME_PLACEHOLDER": "i.e Driver",
        "DESCRIPTION_PLACEHOLDER": "i.e Driver",
        "DESCRIPTION": "Description",
        "PREPARATION_TIME": "Preparation Time",
        "DRIVING_TO_START_STOP_TIME": "Garage to Out-Line Start Stop Time",
        "SHORTEST_OUT_LINE_DISTANCE": "Shortest Out-Line Distance",
        "AVERAGE_OUT_LINE_DISTANCE": "Out-Line Distance",
        "AVERAGE_EMPTY_OUT_TRIP_TIME": "Average Empty Out-Trip Time",
        "AVERAGE_EMPTY_IN_TRIP_TIME": "Average Empty In-Trip Time",
        "DRIVING_FROM_START_STOP": "In-Line Start Stop to Garage Time",
        "SHORTEST_IN_LINE_DISTANCE": "Shortest In-Line Distance",
        "AVERAGE_IN_LINE_DISTANCE": "In-Line Distance",
        "CLOSURE_TIME": "Closure Time",
        "INTERMEDIATE_STOP": "Intermediate Stop",
        "START_SEGMENT": "Start Segment",
        "FOLLOW_UP_SEGMENTS": "Follow-up Segments",
        "FOLLOW_UP_SEGMENT": "Follow-up Segment",
        "INTERMEDIATE_SEGMENT": "Intermediate Segment",
        "ESTIMATED_RUNTIME": "Estimated Runtime",
        "ESTIMATED_OUT_SEGMENTS_RUNTIME": "Estimated Out Segments Runtime (Default Pattern)",
        "NUMBER_PLACEHOLDER": 0,
        "END_STOP": "End Stop",
        "START_STOP": "Start Stop",
        "STARTING_STOP": "Starting Stop",
        "RELATED_PATTERNS": "Related Patterns",
        "PATTERNS": "Patterns",
        "LONGITUDE": "Longitude",
        "GEO_LOCATION": "Geo Location",
        "SELECT_GEO_LOCATION": "Select Geo Location",
        "HANDOVER_TIME": "Handover Time"
      }
    },
    "SEGMENTS": {
      "TITLE": "Segment Details",
      "FIELDS": {
        "NAME": "Segment Name",
        "SYSTEM_PATTERN": "Patterns",
        "DESCRIPTION": "Description",
        "INTERMEDIATE_STOP": "Intermediate Stop",
        "END_STOP": "End Stop",
        "START_STOP": "Start Stop",
        "STARTING_STOP": "Starting Stop",
        "ENDING_STOP": "Ending Stop",
        "PATTERNS": "Patterns",
        "MINUTES": "Minutes"
      }
    },
    "LOGIN": {
      "TITLE": "Login",
      "FIELDS": {
        "EMAIL": "Email Address",
        "PASSWORD": "Password",
        "FORGOT_PASSWORD": "Forgot Password",
        "OTP": "OTP",
        "ENTER_OTP": "Enter OTP",
        "OTP_SENT_TO": "OTP is sent to ",
        "CHECK_INBOX": "please check your inbox",
        "INCASE_YOU_DIDNT_RECEIVE": "incase you didn’t receive it please click",
        "CREDENTIALS_VERIFIED": "Your credentials have been verified. Please proceed to enter the OTP for further authentication.",
        "PLEASE_CHECK_INBOX": "An OTP will be sent to your email address.",
        "UNABLE_TO_LOGIN": "Unable to login",
        "RESEND_OTP": "Resend OTP",
        "USER_LOGGED_IN": "User Logged In Successfully",
        "UNABLE_TO_VERIFY": "Unable to verify OTP",
        "OTP_DISABLED": "Resend OTP button disabled for",
        "OTP_SENT": "An OTP has been sent to your email.",
        "RESET_LINK": "Entered email address is valid. Reset password link sent successfully",
        "UNABLE_TO_RESET": "Unable to reset password",
        "RESET_LINK_INVALID": "Reset password link is invalid",
        "PASSWORD_RESET_SUCCESSFULLY": "Password reset successfully",
        "PASSWORD_DONOT_MATCH": "Passwords do not match",
        "RESET_PASSWORD_LINK_SENT": "Reset password link will be sent to your email address",
        "RESEND_LINK": "Resend Link",
        "SET_NEW_PASSWORD": "Set New Password",
        "ENTER_NEW_PASSWORD": "Enter New Password",
        "CONFIRM_NEW_PASSWORD": "Confirm New Password",
        "PASSWORD_VALIDATION": "At least 8 characters, one capital letter, one number and one special character"
      }
    },
    "URBAN_FARES": {
      "TITLE": "Add Fares",
      "EDIT_TITLE": "Urban Fares Details",
      "FIELDS": {
        "FARE_NAME": "Fare Name",
        "TYPE_FARE_NAME": "Enter Fare Name",
        "COUNTRY_NAME": "Country Name",
        "SELECT_COUNTRY_NAME": "Select Country Name",
        "CITY_NAME": "City Name",
        "SELECT_CITY_NAME": "Select City Name",
        "VALID_FROM": "Valid From",
        "SUB_ZONES_FARES_STRUCTURE": "Sub-Zones Fares Structure",
        "ZONES_FARES_STRUCTURE": "Zones Fares Structure",
        "SAME_SUB_ZONE": "Same Sub-Zone",
        "SECOND_SUB_ZONE": "2nd Sub-Zone",
        "THIRD_SUB_ZONE": "3rd Sub-Zone",
        "FOURTH_SUB_ZONE": "4th Sub-Zone & More",
        "FIRST_ZONE": "Crossing First Zone",
        "SECOND_ZONE": "Crossing 2nd Zone",
        "THIRD_ZONE": "Crossing 3rd Zone",
        "FOURTH_ZONE": "Crossing 4th Zone & More",
        "DUPLICATE": "Duplicate Fare"
      }
    },
    "REPORTS": {
      "TOTAL_PASSENGERS": "Total Passengers",
      "POSITION": "Position",
      "SCHEDULE_TIME": "Schedule Time",
      "REAL_TIME": "Real Time",
      "GAP": "Gap",
      "TRIP_STATE": "Trip State",
      "REASON_FOR_DELAY": "Reason For Delay",
      "PHYSICAL_TICKETS_USED": "#Physical Tickets Used",
      "DIGITAL_TICKETS_USED": "#Digital Tickets Used",
      "CARDS_ENTRIES": "#Cards Entries",
      "CARDS_EXITS": "#Cards Exits",
      "CARDS_ENTRY_VS_EXIT": "#Cards Entry Vs Exit",
      "UNBLOCKS": "#Unblocks",
      "PASSES_USED": "#Passes Used",
      "VEHICLE_VIN": "Vehicle VIN",
      "CREATED_DATE": "Created Date",
      "TOTAL_DISTANCE_TRAVELLED": "Total Distance Travelled",
      "TRIP_DISTANCE": "Trip Distance",
      "NON_TRIP_DISTANCE": "Non Trip Distance",
      "TRIPS_MADE": "#Trips Made",
      "BUS_CONDUCTOR_NAME": "Bus Conductor Name",
      "CUSTOMER_PROFILE_ID": "Customer Profile ID",
      "CARD_EXPIRY": "Card Expiry",
      "OLD_BALANCE": "Old Balance",
      "UPDATED_BALANCE": "Updated Balance",
      "NEW_BALANCE": "New Balance",
      "CASH_COLLECTED": "Cash Collected",
      "FINE_COLLECTED": "Fine Collected",
      "SALES_AGENT_NAME": "Sales Agent Name",
      "SHIPMENT_TYPE": "Shipment Type",
      "SHIPMENT_FARE": "Shipment Fare",
      "POS_OPERATOR_NAME": "POS Operator Name",
      "CARD_EXPIRY_DATE": "Card Expiry Date",
      "PREVIOUS_BALANCE": "Previous Balance",
      "REQUESTED_DATE_TIME": "Requested Date/Time",
      "SENDER_NAME": "Sender Name",
      "COLLECTOR_NAME": "Collector Name",
      "WEIGHT": "Weight",
      "CARD_TYPE": "Card Type",
      "ADULT": "Adult",
      "CHILD": "Child",
      "ADULT_CHILD": "Adult / Child",
      "PERSONALIZED": "Personalized",
      "TICKET_FINE_CARD_ID": "Ticket / Fine / Card ID",
      "TICKET_CARD_ID": "Card ID / Ticket",
      "RIDERSHIP_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Ridership Report - {{date}} - {{time}}",
      "CUSTOMERS_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Customers Report - {{date}} - {{time}}",
      "SALES_AGENT_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Sales Agent Report - {{date}} - {{time}}",
      "FINANCES_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Finances Report - {{date}} - {{time}}",
      "BUS_CONDUCTOR_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Attendant Report - {{date}} - {{time}}",
      "LINE_COMPARISON_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Line Comparison Report - {{date}} - {{time}}",
      "POS_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - POS Operator Report - {{date}} - {{time}}",
      "SHIPMENT_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Shipment Report - {{date}} - {{time}}",
      "VEHICLE_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Vehicle Report - {{date}} - {{time}}"
    },
    "PASSES_FARES": {
      "TITLE": "Add Pass",
      "EDIT_TITLE": "Pass Details",
      "FIELDS": {
        "LOGO": "Logo",
        "PASS_NAME": "Pass Name",
        "PASS_CATEGORY": "Pass Category",
        "SELECT_PASS_CATEGORY": "Select Pass Category",
        "OPTIONAL_LOGO": "Optional Logo",
        "UPLOAD_LOGO": "Upload Logo",
        "PRICE": "Price",
        "STATUS": "Status",
        "DISCOUNT": "Discount",
        "BEGIN_DATE": "Begin Date",
        "END_DATE": "End Date",
        "DISCOUNT_FIELD": "Discount Field",
        "DISCOUNT_PERCENTAGE": "Discount %",
        "DISCOUNT_AMOUNT": "Discount Amount in %",
        "ENTER_DISCOUNT_AMOUNT": "Enter Discount Amount in %",
        "FREE_PASS_DETAILS": "Free Pass Details",
        "SPECIAL_PASS_DETAILS": "Special Pass Details",
        "NEW_PASS_DETAILS": "Add Pass Details"
      }
    },
    "INTERCITY_FARES": {
      "TITLE": "Add Fares",
      "EDIT_TITLE": "Intercity Fares Details",
      "LUGGAGE": "Luggage",
      "PRICE_PER_KILO": "Price Per Additional Kilo",
      "FARES": "Fares",
      "PASSENGER": "Passenger",
      "SMALL_ENVELOPE": "Small Envelope",
      "LARGE_ENVELOPE": "Large Envelope",
      "PARCEL": "Parcel",
      "LOAD_STOPS_VARIATION": "Load Stops Variation",
      "LINE_OUT": "Line Out",
      "LINE_IN": "Line In",
      "FARE_NAME": "Fare Name",
      "FIELDS": {
        "VALID_FROM": "Valid From",
        "SAME_SUB_ZONE": "Same Sub-Zone"
      }
    },
    "TRANSACTION_SETTLEMENT": {
      "TITLE": "Transaction Settlement",
      "TICKET_IDS": "Ticket IDs",
      "TICKET_ID": "Ticket ID",
      "NOT_FOUND_TRANSACTIONS": "The shift has no transactions",
      "NOT_FOUND": "Record does not exist!",
      "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Transaction Settlement - {{date}} - {{time}}",
      "TAB_COMMERCIAL_AGENT": {
        "TITLE": "Commercial Sales Agent",
        "NAME": "Name",
        "DEVICE_ID": "Device ID",
        "START_TIME": "Start Time",
        "END_TIME": "End Time",
        "CUMULATIVE_AMOUNT": "Cumulative Amount",
        "PAID_AMOUNT": "Paid Amount",
        "SETTLED_BY": "Settled By"
      },
      "TAB_SALES_AGENT": {
        "TITLE": "Sales Agent"
      },
      "TAB_ATTENDANT": {
        "TITLE": "Attendant"
      },
      "DETAILS": {
        "TITLE": "Transaction Settlement Details",
        "USER_NAME": "User Name",
        "USER_ROLE": "User Role",
        "DEVICE_ID": "Device ID",
        "SHIFT": "Shift",
        "TOTAL_COLLECTED_AMOUNT": "Total Collected Amount",
        "TAB_CARDS": "Cards",
        "DATE": "DATE",
        "TIME": "TIME",
        "TOTAL": "TOTAL",
        "FEE": "FEE",
        "DATE_AND_TIME": "Date & Time",
        "PASS_PRICE": "PASS PRICE",
        "PASS_NAME": "Pass Name (Category)",
        "CARD_NUMBER": "Card Number",
        "CARD_PRICE": "Card Price",
        "TOP_UP": "Top Up",
        "PASSES_TAB": "Passes",
        "STOP_FROM": "Stop From",
        "STOP_TO": "Stop To",
        "CURRENT_BALANCE": "Current Balance",
        "REMAINING_BALANCE": "Remaining Balance",
        "TOP_UP_AMOUNT": "Top Up Amount",
        "SETTLED_BY": "Settled By",
        "PRICE_PER_SEAT": "Price Per Seat",
        "PRICE_PER_SEAT_ADULT": "Price Per Seat (ADULT)",
        "PRICE_PER_SEAT_CHILD": "Price Per Seat (CHILD)",
        "TOTAL_COLLECTED_ADULTS": "Total Collected Adults",
        "TOTAL_COLLECTED_CHILDREN": "Total Collected Children",
        "TOTAL_COLLECTED_CASH": "Total Collected Cash",
        "TOTAL_COLLECTED_CARD": "Total Collected Card",
        "NUMBER_OF_SEAT": "Number of Seats",
        "TOTAL_PRICE": "Total Price",
        "NUMBER_OF_ADULTS": "Number of Adults",
        "NUMBER_OF_CHILDREN": "Number of Children",
        "TAB_TOP_UP": "Top Up",
        "TAB_TICKETS": "Tickets",
        "TICKET_TYPE": "Ticket Type",
        "LINE_NAME": "LINE NAME",
        "TAB_FINE": "Fine",
        "AMOUNT": "AMOUNT",
        "FINE_REFERENCE": "Fine Reference",
        "TAB_SHIPMENT_LUGGAGE": "Shipment / Luggage",
        "QUANTITY": "Quantity",
        "UNIT_PRICE": "Unit Price",
        "EMPLOYEE_ID": "Employee Id",
        "EMPLOYEE_NAME": "Employee Name",
        "TOTAL_REVENUE": "Total Revenue",
        "CARD_REVENUE": "Card Revenue",
        "CARD_AMOUNT": "Card Amount",
        "CASH_AMOUNT": "Cash Amount",
        "TOTAL_CASH_REVENUE": "Total Cash Revenue",
        "CHILD_REVENUE": "Child Revenue",
        "ADULT_REVENUE": "Adult Revenue",
        "SHIFT_DATE_TIME": "Shift Date & Time",
        "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Attendants - {{date}} - {{time}}"
      }
    },
    "PATTERNS": {
      "TITLE": "Pattern Details",
      "FIELDS": {
        "NAME": "Pattern Name",
        "WEEKDAY_EVENING": "Weekday Evening",
        "TYPE": "Pattern Type",
        "SELECT_PATTERN_TYPE": "Select Pattern Type",
        "DESCRIPTION": "Description",
        "OCCURRENCE": "Occurrence",
        "SELECT_OCCURRENCE": "Select Occurrence",
        "ALL_DAY": "All Day",
        "ONE_TIME": "One Time",
        "REPEAT_WEEKDAYS": "Repeat Weekdays",
        "REPEAT_WEEKENDS": "Repeat Weekends",
        "DAILY": "Daily",
        "CUSTOM_DATE": "Custom Date"
      }
    },
    "ZONES": {
      "TITLE": "Zone Details",
      "PARENT_ZONE": "Parent Zone",
      "SUB_ZONE": "Sub Zone",
      "SUB_ZONES": "Sub Zones",
      "NUMBER_SUB_ZONES": "#Sub Zones",
      "NEW_SUB_ZONE": "Add Sub Zone",
      "REMOVING_LAST_POINT": "Remove Last Point",
      "REMOVING_ALL_POINTS": "Remove All Points",
      "SUB_ZONE_NAME": "Sub Zone Name",
      "SUB_ZONE_DESCRIPTION": "Sub Zone Description",
      "ADD_ZONE": "Add Zone",
      "ADD_SUB_ZONE": "Add Sub Zone",
      "SUB_ZONE_DETAILS": "Sub Zone Details",
      "ZONE_DETAILS": "Zone Details",
      "ZONE_NAME": "Zone Name"
    },
    "TIMETABLE": {
      "TITLE": "Timetable",
      "TIMETABLE_NAME": "TimeTable Name",
      "TAB_TIMELINE": "Timeline",
      "TAB_TIMETABLE": "Timetable",
      "FILTER_BY": "Filter By",
      "BLOCK": "Block",
      "NEW": "New",
      "STOPS": "Stops",
      "EMPTY_TRIP_IS_SHORTER": "Empty Trip is shorter than",
      "TIME_EXCEEDED_BY": "Time Exceeded By",
      "REMOVE": "Remove",
      "STATISTICAL_INFO": "Statistical Info",
      "NUMBER_OF_BLOCK": "Number of block",
      "TOTAL_TRIPS_MADE": "Total trips",
      "FIRST_DEPARTURE_TIME": "First departure time",
      "DUPLICATE_TIMETABLES": "Duplicate Timetables",
      "TOTAL_KMS_TRAVELED": "Total KMs traveled",
      "LAST_ARRIVAL_TIME": "Last arrival time",
      "TOTAL_TIME_ON_ROAD": "Total time on the road",
      "TOTAL_REST_TIME": "Total rest time",
      "PREP_TIME_GARAGE": "Prep Time Garage",
      "OUT_TRIP": "Out Trip",
      "IN_TRIP": "In Trip",
      "REST_TIME": "Rest Time",
      "PREP_CLOSURE_TIME": "Prep/Closure Time",
      "EMPTY_TRIP": "Empty Trip",
      "LEGEND": "Legend",
      "PUBLISHED": "Published",
      "DRAFT": "Set As Draft",
      "TIMELINE": {
        "TITLE": "Timeline Details",
        "FIELDS": {
          "NAME": "Timetable Name (Auto Generated)",
          "LINE": "Line",
          "LINE_DATE": "Line Date"
        }
      },
      "FIELDS": {
        "DATE_FROM": "Date From",
        "DATE_TO": "Date To"
      },
      "TABLE": {
        "TITLE": "Trips Backlog",
        "TRIP_NAME": "TRIP NAME",
        "START_TIME": "START TIME",
        "EST_END_TIME": "EST END TIME",
        "RUNTIME": "RUNTIME",
        "TYPE": "Type",
        "DIRECTION": "DIRECTION"
      },
      "BUTTONS": {
        "LOAD_TRIPS": "Load Trips",
        "RELOAD_TRIPS": "Reload Trips",
        "ADD_TO_BLOCK": "Add Selection to Block",
        "MOVE_TO_BLOCK": "Move Selection to Block"
      }
    },
    "SCHEDULE": {
      "SERVICES": {
        "SERVICE_CREATION_NAME": "SERVICE CREATION NAME",
        "SERVICES": "#Services",
        "BLOCKS": "#BLOCKS",
        "LINE": "Line",
        "DATE_FROM": "DATE FROM",
        "DATE_TO": "DATE TO",
        "STATUS": "STATUS",
        "TITLE": "Service Creation",
        "DIVISION_METHOD": "Division Method",
        "ASSIGN_TO_DIVISION": "Assign To Division Method",
        "SERVICE": "Service",
        "DURATION": "Duration",
        "FIELDS": {
          "LINE": "Line",
          "TYPE": "Line Type",
          "DATE_FROM": "Date From",
          "DATE_TO": "Date To",
          "SELECT_LINE": "Select Line",
          "PREP_TIME": "Prep Time",
          "HANDOVER_TIME": "Hand Over Time"
        },
        "SERVICE_CONFIG": {
          "PARENT_TIMETABLE": "PARENT TIMETABLE",
          "BLOCK_NAME": "BLOCK NAME",
          "DATE": "DATE",
          "ASSIGNED_TO": "ASSIGNED TO",
          "TAB_1": "Fetching Blocks",
          "TAB_2": "Creating Services"
        },
        "TABLE": {
          "DAY": "Day",
          "LINE_NAME": "Line Name",
          "TRIPS": "Trips",
          "BLOCKS": "Blocks"
        },
        "FETCH": "Fetch Timetables"
      },
      "EMPLOYEE_ROSTER": {
        "TITLE": "Employee Roster",
        "CALENDAR": {
          "TITLE": "Teams"
        },
        "PLAN_NAME": "PLAN NAME",
        "LAST_PLANNED_DATE": "PLANNED FROM",
        "PLANNED_UNTIL": "PLANNED UNTIL",
        "SERVICES": "#SERVICES",
        "ROWS": "#ROWS",
        "STATUS": "STATUS",
        "MODAL": {
          "TITLE": "Assign Employees"
        },
        "FIELDS": {
          "CONDUCTEUR": "Driver",
          "RECEVEUR": "Attendant"
        }
      },
      "EMPLOYEE_ENGAGEMENT": {
        "TITLE": "Employees",
        "EXPORT_TITLE": "Are you sure you want to export?",
        "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Employee Roster - {{date}} - {{time}}"
      },
      "PLANS": {
        "TITLE": "Plan Details",
        "NEW_PLANS": "Add Plan",
        "EDIT_PLANS": "Edit Plan",
        "PLANS_NAME": "Plan Name",
        "LAST_PLANNED_DATE": "Last Planned Date +1",
        "PLANNED_FROM": "Planned From",
        "PLANNED_UNTIL": "Planned Until",
        "TIME_FROM": "Time From",
        "TIME_TO": "Time To",
        "UNASSIGN": "Unassign",
        "UNASSIGN_SERVICES": "Unassigned Services",
        "STANDBY_TIME_TITLE": "Create Standby Time",
        "SERVICES": "#Services",
        "ASSIGN_SELECTED_SERVICES": "Assign selected service to",
        "REASSIGN_SELECTED_SERVICES": "Reassign selected service to",
        "ROWS": "#Rows",
        "DESCRIPTION": "Description",
        "ENTER_PLANS_NAME": "Enter Plan Name",
        "PLANS_DESCRIPTION": "Plan Description",
        "FINE_AMOUNT": "Fine Amount",
        "ENTER_FINE_AMOUNT": "Enter Fine Amount",
        "DATE_RANGE": "Date Range",
        "ROW_PLANNING": "Row Planning",
        "ROSTER_PLANNING_DETAILS": "Roster Planning Details",
        "PLAN_NAME_AUTO_GENERATED": "Plan Name (Auto Generated)",
        "LINE_TYPE": "Line Type",
        "LAST_PLAN_DATE": "Last Plan Date +1",
        "PLANNING_UNTIL": "Planning Until",
        "DAY": "DAY",
        "ROWS_TITLE": "Rows",
        "ROW_TITLE": "Row",
        "DURATION": "DURATION",
        "TIME_INTERVAL": "TIME INTERVAL",
        "LINE_NAME": "LINE NAME",
        "BUTTONS": {
          "ASSIGN_TO_DIVISION": "Assign To Division Method",
          "LOAD_SERVICES": "Load Services",
          "SAVE": "Save & View Plan"
        }
      }
    },
    "TRIPS": {
      "TITLE": "Trip Details",
      "LINE_DISPLAY": "Line Display",
      "FIELDS": {
        "NAME": "Trip Name",
        "EST_DURATION": "Est. Duration",
        "DATE_AND_TIME": "Date & Time",
        "TRIP_DIRECTION": "Direction",
        "TYPE": "Line Type",
        "SELECT_PATTERN_TYPE": "Select Trip Type",
        "DESCRIPTION": "Description",
        "DIRECTION": "Trip Direction",
        "TRIP_DATE": "Trip Date",
        "TRIP_TIME": "Trip Time",
        "SELECT_DIRECTION": "Select Trip Direction",
        "GENERATED_TRIP_NAME": "Generated Trip Name",
        "ESTIMATED_TRIP_RUNTIMES": "Estimated Trip Runtimes",
        "MINUTES": "Minutes",
        "LINE": "Line",
        "SELECT_LINE": "Select Line",
        "DUPLICATE": "Duplicate",
        "MAP_VIEW": "Map View",
        "VIEW_ON_MAP": "View On Map",
        "DATE_FROM": "Date From",
        "DATE_TO": "Date To",
        "TRIP_TYPE": "Trip Type",
        "START_TIME": "Start Time",
        "DRIVER_START_TIME": "Driving Start Time",
        "DRIVER_END_TIME": "Driving End Time",
        "END_TIME": "End Time",
        "INTERVAL_TIME": "Interval Time",
        "CONFIRM": "Confirm"
      },
      "CHOOSE_DEPARTURE": "Choose Custom Departure Times",
      "TIMES_DELPER": "Departure Times Helper",
      "TIMES_HELPER_TOOLTIP": "By selecting a start time, end time, and an interval you can generate equally spaced departure times. You can always edit later!",
      "SHOW": "Show ",
      "DEPARTURES": " Departures",
      "ADD_DEPARTURE": "Add Departure Time",
      "CONFIRM_SUMMARY": "Confirm Summary",
      "GENERATE": "Generate",
      "COMMENCING": "Commencing at",
      "CONCLUDING": "and concluding at",
      "DAYS": "on selected days",
      "TIMEFRAME": "within the timeframe of",
      "TO": "to",
      "CREATION": "Expect the creation of",
      "ACCORDINGLY": "accordingly.",
      "PLEASE_SELECT_WEEK_DAYS": "Please select at least one week day"
    },
    "TRANSACTION": {
      "NAME": "Transaction Name",
      "TYPE": "Type",
      "DATE_RANG": "Date Range",
      "TIME_RANG": "Time Range",
      "REPEAT": "Repeat"
    },
    "FRAUD_TYPES": {
      "TITLE": "Violation Details",
      "NEW_FRAUD": "Add Violation",
      "EDIT_FRAUD": "Edit Violation",
      "FRAUD_NAME": "Violation Name",
      "DESCRIPTION": "Description",
      "ENTER_FRAUD_NAME": "Enter Violation Name",
      "FRAUD_DESCRIPTION": "Violation Description",
      "FINE_AMOUNT": "Fine Amount",
      "ENTER_FINE_AMOUNT": "Enter Fine Amount"
    },
    "HOLIDAYS": {
      "TITLE": "Holidays",
      "CREATE_HOLIDAY": "Add Holiday",
      "EDIT_HOLIDAY": "Edit Holiday",
      "HOLIDAY_NAME": "Holiday Name",
      "STARTING_DATE": "Starting Date",
      "ENDING_DATE": "Ending Date",
      "DDD_AVAILABILITY": "DDD Availability",
      "URBAN_AVAILABILITY": "Urban Availability",
      "INTERCITY_AVAILABILITY": "Intercity Availability",
      "AVAILABILITY": "Availability",
      "DDD": "DDD",
      "URBAN_TRIPS": "Urban Trips",
      "INTERCITY_TRIPS": "Intercity Trips"
    },
    "VEHICLE_DISPATCHING": {
      "TITLE": "Vehicle Dispatching",
      "TIME": "Time",
      "LINE_NAME": "Line Name",
      "BLOCK_NAME": "Block Name",
      "TEAM": "Team",
      "NUMBER_OF_TRIPS": "#Trips",
      "NUMBER_OF_SERVICES": "#Services",
      "DATE": "Date",
      "BUS": "Bus",
      "TRIPS": "Trips",
      "CONDUCTOR": "Conductor",
      "CONDUCTOR_NAME": "Conductor Name",
      "ATTENDANT_NAME": "Attendant Name",
      "SELECT_CONDUCTOR": "Select Conductor",
      "ATTENDANT": "Attendant",
      "SELECT_ATTENDANT": "Select Attendant",
      "REPLACEMENT_ATTENDANT": "Replacement Attendant",
      "REPLACEMENT_DRIVER": "Replacement Driver",
      "REPLACEMENT_SECONDARY_DRIVER": "Replacement Secondary Driver",
      "DRIVER": "Driver",
      "SELECT_DRIVER": "Select Driver",
      "SECONDARY_DRIVER": "Secondary Driver",
      "SELECT_SECONDARY_DRIVER": "Select Secondary Driver",
      "ASSIGNMENTS": "Assignments",
      "REASSIGN": "Reassign",
      "START_TIME": "Start Time",
      "END_TIME": "End Time",
      "ASSIGN_BUS": "Assign Bus",
      "MAIN_BUS": "Main Bus",
      "SELECT_MAIN_BUS": "Select Main Bus",
      "REPLACEMENT_BUS": "Replacement Bus",
      "SELECT_REPLACEMENT_BUS": "Select Replacement Bus"
    },
    "CALENDAR": {
      "TODAY": "Today",
      "HOLIDAY": "Holiday",
      "DAYS_OFF": "Days off",
      "NO_ERRORS": "No Errors",
      "ERRORS": "Errors",
      "SERVICE_VIOLATION": "Service Violation",
      "DAY_OF_VIOLATION": "Day Off Violation",
      "HOURS_LESS_THEN_35": "Hours less than 35 hrs",
      "HOURS_MORE_THAN_48": "Hours more than 48 hrs",
      "MORE_THEN_ONE_DAY_OF_VIOLATION": "More than 1 day off",
      "NOT_ENOUGH_REST": "Not enough rest time",
      "ZERO_DAY_OF_VIOLATION": "0 Days off",
      "DELETE_ROW": "Are you sure you want to delete the row?"
    },
    "NFC_CARDS": {
      "NFC_CARDS": "NFC Cards",
      "CARD_DETAILS": "Card Details",
      "SERIAL_NUMBER_SIGN": "Serial #",
      "SERIAL_NUMBER": "Serial Number",
      "TICKET_SERIAL_NUMBER": "Ticket Serial Number",
      "TRANSACTION_REFERENCE": "Transaction Reference",
      "ISSUED_DATE": "Issued Date",
      "EXPIRY_DATE": "Expiry Date",
      "PASS_NAME": "Pass Name",
      "CARDHOLDER_NAME": "Cardholder Name",
      "CUSTOMER_TYPE": "Customer Type",
      "CUSTOMER_STATUS": "Customer Status",
      "CUSTOMER_NAME": "Customer Name",
      "WALLET_BALANCE": "Wallet Balance",
      "TRANSACTIONS_DONE": "Transactions Done",
      "TRANSACTION_TYPE": "Transaction Type",
      "BLOCKED_AMOUNT": "Blocked Amount",
      "DATE": "Date",
      "TIME": "Time",
      "AMOUNT": "Amount",
      "BALANCE": "Balance",
      "TOP_UP": "Top-Up",
      "TIME_BASED_PASS": "Time-Based Pass",
      "FREE_PASS": "Free Pass",
      "SHIPMENT": "Shipment",
      "TICKET": "Ticket",
      "PASSES": "Passes",
      "PASS": "Pass",
      "SHIFT_CASH": "Shift Cash",
      "URBAN": "Urban",
      "INTERCITY": "Intercity",
      "TOPUP": "Top-Up",
      "FINE": "Fine",
      "CARD": "Card",
      "CARD_ID": "Card ID",
      "EMPLOYEE": "Employee",
      "STUDENT": "Student",
      "REGULAR": "Regular",
      "SPECIAL": "Special",
      "MILITARY": "Military",
      "NORMAL": "Normal",
      "RETIRED": "Retired",
      "DDDEMPLOYEE": "DDD Employee",
      "FREELANCER": "Freelancer",
      "DESCRIPTION": "Description",
      "FEES": "Fees",
      "CURRENT_BALANCE": "Current Balance",
      "IMPORT_FILE_NAME": "import-nfc-cards-template",
      "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - NFC Cards - {{date}} - {{time}}",
      "NO_TAP_OUT": "No Tap Out",
      "URBAN-TICKET": "Urban Ticket",
      "URBAN-UNBLOCK": "Urban Unblock",
      "URBAN-CARD": "Urban Card",
      "INTERCITY-PASSENGER": "Intercity Passenger",
      "INTERCITY-TICKET": "Intercity Ticket",
      "UNBLOCK": "Unblock"
    },
    "DEVICES": {
      "DEVICES": "Devices",
      "NEW_DEVICES": "Add Device",
      "EDIT_DEVICES": "Edit Device",
      "DEVICE_ID": "Device ID",
      "ENTER_DEVICE_ID": "Enter Device ID",
      "DEVICE_TYPE": "Device Type",
      "SELECT_DEVICE_TYPE": "Select Device Type",
      "TYPE": "Type",
      "LOCATION": "Location",
      "INSERT_LOCATION": "Insert Location",
      "ENTER_LOCATION": "Enter Location",
      "DEVICE_LOCATION": "Device Location",
      "SELECT_DEVICE_LOCATION": "Select Device Location",
      "SELECT_USER": "Select User",
      "SELECT_VEHICLE": "Select Vehicle",
      "DESCRIPTION": "Description",
      "MORE_DESCRIPTION": "More Info On Device"
    },
    "VEHICLES": {
      "LISTING": "Vehicles",
      "ADD_VEHICLE": "Add Vehicle",
      "EDIT_VEHICLE": "Edit Vehicle",
      "VEHICLE_DETAILS": "Vehicle Details",
      "VEHICLE_NAME": "Vehicle Name",
      "ENTER_VEHICLE_NAME": "Enter Vehicle Name",
      "LICENSE_NUMBER": "License #",
      "MANUFACTURER": "Manufacturer",
      "SELECT_MANUFACTURER": "Select Manufacturer",
      "ENTER_MANUFACTURER": "Enter Manufacturer Name",
      "MODEL_YEAR": "Model Year",
      "TYPE": "Type",
      "SELECT_TYPE": "Select Type",
      "CAPACITY": "Capacity",
      "ENTER_CAPACITY": "Enter Capacity",
      "COLOR": "Color",
      "SELECT_COLOR": "Select Color",
      "CREATION_DATE": "Creation date",
      "VEHICLE_TYPE": "Vehicle Type",
      "VEHICLE_INTERNAL_ID": "Vehicle Internal ID",
      "ENTER_VEHICLE_INTERNAL_ID": "Enter Vehicle Internal ID",
      "PLATE_NUMBER": "Plate Number",
      "ENTER_PLATE": "Enter License Number",
      "VIN_NUMBER": "VIN Number",
      "ENTER_VIN_NUMBER": "Enter VIN Number",
      "MODELYEAR": "Model/Year",
      "ENTER_MODELYEAR": "Enter Model/Year"
    },
    "NOTIFICATIONS": {
      "HEADING": "Notifications",
      "CREATE": "Add Notification",
      "STATISTICS": "Notifications Statistics",
      "TEMPLATE": "Notifications Templates",
      "DETAILS": "Notifications Details",
      "AUDIENCE": "Notifications Audience",
      "TITLE": "Title",
      "ENTER_TITLE": "Enter Title",
      "DESCRIPTION": "Description",
      "DATE_AND_TIME": "Date & Time",
      "USER": "User",
      "INSERT": "Insert",
      "TARGET_AUDIENCE": "Target Audience",
      "SELECT_TARGET_AUDIENCE": "Select Target Audience",
      "SELECT_DATA": "Select Data",
      "SEND_NOW": "Send Now",
      "SCHEDULE_FOR_LATER": "Schedule for Later",
      "START_DATE": "Start Date",
      "TIME": "Time",
      "ALL_DRIVERS": "All Drivers",
      "ALL_ATTENDANTS": "All Attendants",
      "ALL_INSPECTORS": "All Inspectors",
      "ALL_SALES_AGENT": "All Sales Agent",
      "ALL_COMMERCIAL_SALES_AGENT": "All Commercial Sales Agent",
      "SELECTED_LINES": "Targeted Lines",
      "SELECTED_USERS": "Targeted Users",
      "GENERAL_BROADCAST": "General Broadcast",
      "TEMPLATES": [
        {
          "TITLE": "Maintenance Alert",
          "DESCRIPTION": "Please be informed that scheduled maintenance will take place tomorrow [from - to]. Your services might be temporarily unavailable."
        },
        {
          "TITLE": "Policy Update",
          "DESCRIPTION": "Please note that our company policies have been updated. Review the new policies in the portal to stay informed."
        },
        {
          "TITLE": "Training Session",
          "DESCRIPTION": "A mandatory training session on the new ticketing system will be held on [Date & Time] in [Location]."
        },
        {
          "TITLE": "Service Interruption",
          "DESCRIPTION": "Please ensure all safety protocols are followed during your shifts. Your safety and the safety of our passengers is our top priority."
        },
        {
          "TITLE": "New Passes",
          "DESCRIPTION": "New Passes are available for passengers on [selected months]. Inform your customers to check the website for more details."
        },
        {
          "TITLE": "Holiday Schedule",
          "DESCRIPTION": "Please be aware of the upcoming holiday schedule changes. Check the updated schedule on the portal and plan accordingly."
        },
        {
          "TITLE": "System Update",
          "DESCRIPTION": "A system update will be performed tonight at midnight. Please save your work and log out before this time to avoid data loss."
        },
        {
          "TITLE": "Meeting Reminder",
          "DESCRIPTION": "A reminder for the scheduled meeting with the operations team [Date & Time]. Your attendance is required."
        },
        {
          "TITLE": "Roadblock Ahead",
          "DESCRIPTION": "Please be advised that there is a roadblock on [Route Name] due to construction work. Expect delays and plan your trip accordingly."
        },
        {
          "TITLE": "Emergency Roadblock",
          "DESCRIPTION": "Due to an accident, there is an emergency roadblock on [Area Name]. The road is expected to be cleared in approximately [Number of hours]."
        },
        {
          "TITLE": "Temporary Closure",
          "DESCRIPTION": "Please note that [Area Name] is temporarily closed for a public event. Follow the posted detour signs for alternative routes."
        },
        {
          "TITLE": "Construction Roadblock",
          "DESCRIPTION": "There is a scheduled roadblock on [Area Name] for road repairs. The block will be in place [From - To]."
        },
        {
          "TITLE": "Police Roadblock",
          "DESCRIPTION": "There is an ongoing police investigation causing a roadblock on [Area Name]. Expect delays and rerouted traffic."
        },
        {
          "TITLE": "Peak Hour Inspections",
          "DESCRIPTION": "Focus on ticket and card validations during peak hours today. High traffic expected on [Route Names]."
        },
        {
          "TITLE": "Increased Inspections Required",
          "DESCRIPTION": "Due to recent fraud incidents, increased ticket and card inspections are required on all evening routes. Ensure to conduct thorough checks."
        },
        {
          "TITLE": "Price Adjustment Notification",
          "DESCRIPTION": "Please note that there will be a price adjustment for our services [starting next quarter]. Inform your clients and address any concerns they might have."
        },
        {
          "TITLE": "Temporary Card Unavailability",
          "DESCRIPTION": "Due to a system upgrade, card issuance will be temporarily unavailable on [Friday from 2 PM to 6 PM]. Plan your activities accordingly."
        },
        {
          "TITLE": "Card Serial Number Restriction",
          "DESCRIPTION": "This is to inform you that the following card serial number [insert card serial number] is restricted from use effective immediately. Kindly ensure this card is not issued to any clients or used for transactions."
        },
        {
          "TITLE": "Important Announcement",
          "DESCRIPTION": "We have an important announcement:[Announcement Details] Please ensure that this information is communicated to all relevant parties and adhered to as required."
        },
        {
          "TITLE": "Passenger Count Confirmation",
          "DESCRIPTION": "Confirm the passenger count on [Bus 9] matches with the fare collection. Report any discrepancies immediately."
        }
      ]
    },
    "PASSENGER_NOTIFICATIONS": {
      "HEADING": "Passenger Notifications",
      "CREATE": "Add Passenger Notification",
      "STATISTICS": "Passenger Notifications Statistics",
      "TEMPLATE": "Passenger Notifications Templates",
      "DETAILS": "Passenger Notifications Details",
      "AUDIENCE": "Notifications Audience",
      "TITLE": "Title",
      "ENTER_TITLE": "Enter Title",
      "DESCRIPTION": "Description",
      "DATE_AND_TIME": "Date & Time",
      "USER": "User",
      "INSERT": "Insert",
      "TARGET_AUDIENCE": "Target Audience",
      "SELECT_TARGET_AUDIENCE": "Select Target Audience",
      "SELECT_DATA": "Select Data",
      "SEND_NOW": "Send Now",
      "SCHEDULE_FOR_LATER": "Schedule for Later",
      "START_DATE": "Start Date",
      "TIME": "Time",
      "ALL_DRIVERS": "All Drivers",
      "ALL_ATTENDANTS": "All Attendants",
      "ALL_INSPECTORS": "All Inspectors",
      "ALL_SALES_AGENT": "All Sales Agent",
      "ALL_COMMERCIAL_SALES_AGENT": "All Commercial Sales Agent",
      "SELECTED_LINES": "Targeted Lines",
      "SELECTED_USERS": "Targeted Users",
      "GENERAL_BROADCAST": "General Broadcast",
      "TEMPLATES": [
        {
          "TITLE": "Maintenance Alert",
          "DESCRIPTION": "Please be informed that scheduled maintenance will take place tomorrow [from - to]. Your services might be temporarily unavailable."
        },
        {
          "TITLE": "Policy Update",
          "DESCRIPTION": "Please note that our company policies have been updated. Review the new policies in the portal to stay informed."
        },
        {
          "TITLE": "Training Session",
          "DESCRIPTION": "A mandatory training session on the new ticketing system will be held on [Date & Time] in [Location]."
        },
        {
          "TITLE": "Service Interruption",
          "DESCRIPTION": "Please ensure all safety protocols are followed during your shifts. Your safety and the safety of our passengers is our top priority."
        },
        {
          "TITLE": "New Passes",
          "DESCRIPTION": "New Passes are available for passengers on [selected months]. Inform your customers to check the website for more details."
        },
        {
          "TITLE": "Holiday Schedule",
          "DESCRIPTION": "Please be aware of the upcoming holiday schedule changes. Check the updated schedule on the portal and plan accordingly."
        },
        {
          "TITLE": "System Update",
          "DESCRIPTION": "A system update will be performed tonight at midnight. Please save your work and log out before this time to avoid data loss."
        },
        {
          "TITLE": "Meeting Reminder",
          "DESCRIPTION": "A reminder for the scheduled meeting with the operations team [Date & Time]. Your attendance is required."
        },
        {
          "TITLE": "Roadblock Ahead",
          "DESCRIPTION": "Please be advised that there is a roadblock on [Route Name] due to construction work. Expect delays and plan your trip accordingly."
        },
        {
          "TITLE": "Emergency Roadblock",
          "DESCRIPTION": "Due to an accident, there is an emergency roadblock on [Area Name]. The road is expected to be cleared in approximately [Number of hours]."
        },
        {
          "TITLE": "Temporary Closure",
          "DESCRIPTION": "Please note that [Area Name] is temporarily closed for a public event. Follow the posted detour signs for alternative routes."
        },
        {
          "TITLE": "Construction Roadblock",
          "DESCRIPTION": "There is a scheduled roadblock on [Area Name] for road repairs. The block will be in place [From - To]."
        },
        {
          "TITLE": "Police Roadblock",
          "DESCRIPTION": "There is an ongoing police investigation causing a roadblock on [Area Name]. Expect delays and rerouted traffic."
        },
        {
          "TITLE": "Peak Hour Inspections",
          "DESCRIPTION": "Focus on ticket and card validations during peak hours today. High traffic expected on [Route Names]."
        },
        {
          "TITLE": "Increased Inspections Required",
          "DESCRIPTION": "Due to recent fraud incidents, increased ticket and card inspections are required on all evening routes. Ensure to conduct thorough checks."
        },
        {
          "TITLE": "Price Adjustment Notification",
          "DESCRIPTION": "Please note that there will be a price adjustment for our services [starting next quarter]. Inform your clients and address any concerns they might have."
        },
        {
          "TITLE": "Temporary Card Unavailability",
          "DESCRIPTION": "Due to a system upgrade, card issuance will be temporarily unavailable on [Friday from 2 PM to 6 PM]. Plan your activities accordingly."
        },
        {
          "TITLE": "Card Serial Number Restriction",
          "DESCRIPTION": "This is to inform you that the following card serial number [insert card serial number] is restricted from use effective immediately. Kindly ensure this card is not issued to any clients or used for transactions."
        },
        {
          "TITLE": "Important Announcement",
          "DESCRIPTION": "We have an important announcement:[Announcement Details] Please ensure that this information is communicated to all relevant parties and adhered to as required."
        },
        {
          "TITLE": "Passenger Count Confirmation",
          "DESCRIPTION": "Confirm the passenger count on [Bus 9] matches with the fare collection. Report any discrepancies immediately."
        }
      ]
    },
    "COLLECTION_REVENUE": {
      "TITLE": "Collection Revenue",
      "CARDS": "Cards",
      "TICKETS": "Tickets",
      "TRANSACTION_ID": "Transaction ID",
      "NFC_CARD_NUMBER": "NFC Card Number",
      "PASS_NAME": "Pass Name",
      "PASS_TYPE": "Pass Type",
      "PASS_VALIDITY": "Pass Validity",
      "TRIP_NAME": "Trip Name",
      "LINE_NAME": "Line Name",
      "BUS_NAME": "Bus Name",
      "START_DATE_TIME": "Start Date & Time",
      "START_STOP": "Start Stop",
      "END_DATE_TIME": "End Date & Time",
      "END_STOP": "End Stop",
      "BLOCKED_AMOUNT": "Blocked Amount",
      "TAPPED_OUT": "Tapped Out",
      "TRIP_FARE": "Trip Fare",
      "FARE_COLLECTED": "Fare Collected",
      "DATE_TIME_OF_COLLECTION": "Date & Time of Collection",
      "AMOUNT_COLLECTED": "Amount Collected",
      "PAPER_TICKET_NUMBER": "Paper Ticket Number"
    },
    "ERROR_PAGE": {
      "TITLE": "Page Not Found",
      "TITLE_500": "Internal server error",
      "TITLE_ACCESS_DENIED": "Access Denied",
      "TITLE_SOMETHING_WENT_WRONG": "Something went wrong",
      "TITLE_INTERNAL_SERVER": "Internal server error",
      "DESCRIPTION": "The link you followed may be broken or the page may have been removed.",
      "DESCRIPTION_500": "There wan an error. Please try agin later.",
      "DESCRIPTION_ACCESS_DENIED": "You do not have permission to view this page.",
      "DESCRIPTION_SOMETHING_WENT_WRONG": "Looks like something went wrong. We're working on it",
      "BACK_TO_HOMEPAGE": "Back To Homepage"
    }
  },
  "COMMON": {
    "TEXT": {
      "URBAN": "Urban",
      "INTERCITY": "Intercity",
      "ACTIVE": "Active",
      "INACTIVE": "Inactive",
      "DRAFT": "Draft",
      "PUBLISHED": "Published",
      "PENDING": "Pending",
      "IN_PROGRESS": "In Progress",
      "SENT": "Sent",
      "SCHEDULED": "Scheduled",
      "SETTLED": "Settled",
      "UNSETTLED": "Unsettled",
      "DAMAGED": "Damaged",
      "OBSOLETE": "Obsolete",
      "INREPAIR": "In-Repair",
      "DONE": "Done",
      "ANONYMOUS": "Anonymous",
      "BLACKLISTED": "Blacklisted",
      "NEW": "New",
      "ASSIGNED": "Assigned",
      "NOT_ASSIGNED": "Not Assigned",
      "READY": "Ready",
      "CANCELLED": "Cancelled",
      "SEARCH": "Search",
      "DATE": "Date",
      "DEPARTURE_TIME": "Departure Time",
      "START_DATE": "Start Date",
      "END_DATE": "End Date",
      "START_TIME": "Start Time",
      "END_TIME": "End Time",
      "SELECT_START_TIME": "Select Start Time",
      "SELECT_END_TIME": "Select End Time",
      "ADD_CUSTOM_TIME": "Add Custom Time 00:00",
      "ENDS_ON": "Ends On",
      "SELECT_DATE": "Select Date",
      "NEVER_ENDS": "Never Ends",
      "OUT_OF_SERVICE": "Out Of Service",
      "OUTOFSERVICE": "Out Of Service",
      "MAINTENANCE": "Maintenance",
      "DURATION": "Duration",
      "MONDAY": "Monday",
      "TUESDAY": "Tuesday",
      "WEDNESDAY": "Wednesday",
      "THURSDAY": "Thursday",
      "FRIDAY": "Friday",
      "SATURDAY": "Saturday",
      "SUNDAY": "Sunday",
      "SECONDS": "Seconds",
      "VALID_HOURS": "Please enter valid hours (0-23) and minutes (0-59)",
      "TIME_FORMAT": "Please add time in this format: 00:00",
      "OCCURRENCE": "Occurrence",
      "NO_TRIPS_ADDED": "No trips added",
      "STATUS": "Status",
      "LINE": "Line",
      "DATE_FROM": "Date From",
      "DATE_TO": "Date To",
      "BASED_ON": "Based On",
      "LOCATIONS": "Locations",
      "COUNTRY": "Country",
      "ZONES_AVAILABILITY": "Zones Availability",
      "MODULE": "Module",
      "DIRECTION": "Direction",
      "PASS_CATEGORY": "Pass Category",
      "CITY": "City",
      "ROLE": "Role",
      "VEHICLE": "Vehicle",
      "CONDUCTEUR_RECEVEUR": "Driver / Attendant",
      "TYPE_PLACEHOLDER": "Type",
      "FROM_DATE": "From Date",
      "TO_DATE": "To Date",
      "TYPE": "Type",
      "SELECTED_PATTERNS_OF_SAME_TYPE": "Selected patterns of the same type cannot overlap",
      "REPEATED_WEEKDAY": "on weekdays ",
      "REPEATED_WEEKEND": "on weekends ",
      "ONE_TIME": "one time ",
      "DAILY": "daily ",
      "CUSTOM": "custom ",
      "ON_MONDAY": "on Monday ",
      "ON_TUESDAY": "on Tuesday ",
      "ON_WEDNESDAY": "on Wednesday ",
      "ON_THURSDAY": "on Thursday ",
      "ON_FRIDAY": "on Friday ",
      "ON_SATURDAY": "on Saturday ",
      "ON_SUNDAY": "on Sunday ",
      "AND": " and ",
      "ON": "on"
    },
    "VALIDATION": {
      "NOT_FOUND": "Not Found",
      "REQUIRED": "Required",
      "INVALID_FORMAT": "Invalid Format",
      "INVALID_DATE_RANGE": "Invalid Date Range",
      "INVALID_PASSWORD_FORMAT": "Incorrect Password Format",
      "USER_ID_VALIDATION": "Employee ID should be 6 to 8 characters",
      "START_END_STOPS_REQUIRED": "Start and end stops are required!",
      "INVALID_STOPS_DATA": "Invalid stops data! Please enter complete data",
      "INVALID_BLOCKS_DATA": "Invalid blocks data! Please enter complete data",
      "INVALID_PATTERNS_DATA": "Invalid patterns data! Please enter complete data",
      "PATTERNS_REQUIRED": "Patterns are required!",
      "INVALID_EMAIL_FORMAT": "Invalid email format",
      "ENTER_REQUIRED_FIELDS": "Please enter the required fields!",
      "MAX_DISCOUNT_VALUE": "Discount value should be between 0 - 100",
      "MAX_CAPACITY_VALUE": "Capacity value should be between 0 - 100",
      "DRIVERS_ERROR": "This driver is already assigned for another shift!",
      "DRIVERS_ERROR_THIS": "This driver is already assigned to this shift.",
      "CONDUCTORS_ERROR": "This attendant is already assigned for another shift!",
      "CONDUCTORS_ERROR_THIS": "This attendant is already assigned to this shift."
    }
  },
  "GENERAL": {
    "BUTTONS": {
      "SELECT": "Select",
      "NEW": "New",
      "REFRESH": "Refresh",
      "ANONYMOUS": "Anonymous",
      "BLACKLISTED": "Blacklisted",
      "ASSIGNED": "Assigned",
      "VIEW_ON_MAP": "View On Map",
      "CANCEL": "Cancel",
      "APPLY": "Apply",
      "INSERT": "Insert",
      "CONFIRM": "Confirm",
      "LEAVE_PAGE": "Leave Page",
      "FILTERS": "Filters",
      "ACTIONS": "Actions",
      "EXPIRED": "Expired",
      "UPCOMING": "Upcoming",
      "TODAY": "Today",
      "TOMORROW": "Tomorrow",
      "YESTERDAY": "Yesterday",
      "LAST_7_DAYS": "Last 7 Days",
      "CURRENT": "Current",
      "PAST": "Past",
      "NEXT": "Next",
      "CLOSE": "Close",
      "SUBMIT": "Submit",
      "DELETE": "Delete",
      "SAVE": "Save",
      "UPDATE": "Update",
      "RESET": "Reset",
      "ADD": "Add",
      "EDIT": "Edit",
      "CREATE": "Create",
      "ACTIVATE": "Activate",
      "DEACTIVATE": "Deactivate",
      "PENDING": "Pending",
      "DAMAGED": "Damaged",
      "OBSOLETE": "Obsolete",
      "OUT_OF_SERVICE": "Out Of Service",
      "INREPAIR": "In-Repair",
      "MAINTENANCE": "Maintenance",
      "DUPLICATE": "Duplicate",
      "DRAFT": "Set as Draft",
      "SAVE_DRAFT": "Save as Draft",
      "SAVE_PENDING": "Set as Pending",
      "SAVE_CHANGES": "Save changes",
      "PUBLISH": "Publish",
      "READY": "Ready",
      "MARK_AS_READY": "Mark As Ready",
      "UNASSIGN": "Unassign",
      "SELECT_ALL": "Select All",
      "UNSELECT_ALL": "Unselect All",
      "SAVE_DRAFT_GRAPH": "Save As Draft & View Graph",
      "DOWNLOAD_TEMPLATE": "Download Template",
      "DOWNLOAD_SELECTED_ROWS": "Download Selected Rows",
      "DOWNLOAD_FILTERED_ROWS": "Download Filtered Rows",
      "IMPORT": "Import",
      "EXPORT": "Export",
      "SET_AS_DRAFT": "Set as Draft",
      "SET_AS_PENDING": "Set as Pending",
      "SAVE_CONFIGURE": "Save & Configure Services",
      "VIEW_ALL_ZONES": "View All Zones"
    }
  },
  "MSGS": {
    "GENERAL": {
      "INVALID_PHONE_NUMBER": "Invalid phone number",
      "REQUIRED_EMAIL": "Email is required",
      "SOMETHING_WRONG": "Something went wrong",
      "SELECT_ROW": "Please select at least one row.",
      "SELECT_FILTER": "Please select at least one filter.",
      "CONFIRM_DEPARTURE_TIMES": "Please confirm departure times to proceed",
      "CONFIRM_PUBLISH_SERVICES": "Publishing a Service Creation is irreversible, the Service Creation cannot be changed afterward! New timetables created later within this date range no longer be included.",
      "DRAFT_TIMETABLES-DETECTED": "We detect Draft Timetables within the same date range. By publishing, these timetables and new ones will no longer be included.",
      "INVALID_FILE": "Invalid file type! Only jpg, jpeg, png and pdf are allowed",
      "INVALID_IMAGE": "Invalid image type! Only jpg, jpeg, png is allowed",
      "DONE": "Done",
      "ALL_ZONES": "All Zones",
      "ARE_YOU_SURE": "Are you sure you want to perform this action ?",
      "NO_RESULTS_FOUND": "No Results Found",
      "NO_TRANSACTION_EXISTS": "No transaction was found!",
      "NO_RESULTS_FOUND_GENERIC": "Record does not exist! Click on New or refine your search.",
      "FILE_TOO_LARGE": "The selected file is too large. Please select a file that is less than 200 KB.",
      "IMAGE_TOO_LARGE": "The selected image is too large. Please select an image that is less than 2 MB.",
      "STATUS_UPDATED_SUCCESS": "Status updated successfully",
      "LOADING": "Loading please wait.",
      "START_DATE_ERROR": "Date from must be earlier than date to.",
      "START_TIME_ERROR": "Start time must be earlier than end time.",
      "END_TIME_ERROR": "End time must be later than start time.",
      "INVALID_END_TIME": "Invalid end time",
      "INVALID_START_TIME_DRIVING": "There should be no driving gap between the End and the Start Time.",
      "INVALID_START_TIME_DRIVING_REST_TIME": "The start time for driving should match the start time of the next trip.",
      "INVALID_START_TIME": "Invalid start time",
      "START_END_TIME_SAME": "Start time and end time cannot be the same",
      "YES": "YES",
      "NO": "NO",
      "SEGMENT": "Segment",
      "STOP": "Stop",
      "BLOCKS_MISSING": "Trip blocks are required",
      "UNASSIGN_ROWS": "Are you sure you want to unassign",
      "MARK_PLAN_READY_CONFIRMATION": "Are you sure you want to Mark this plan ready?",
      "UNSAVED_CHANGES": "Are you sure you want to leave the screen without saving the changes?",
      "VIEW_MAP_MIN_STOPS_ERROR": "There should be atleast 2 stops to view map",
      "DUPLICATE_END_TIME": "Invalid Data! Two services shouldn’t have same end time",
      "DUPLICATE_START_TIME": "Invalid Data! Two services shouldn’t have same start time",
      "INVALID_START_END_TIME": "Start time or end time cannot be '00:00' in a service.",
      "INVALID_START_TIME_OVERLAP": "Start Time of the services should not overlap.",
      "SERVICE_EXCEEDS_10_HRS": "Service duration exceeds 10 hours",
      "END_TIME_AFTER_START_TIME": "End Time of service cannot be after the start time of next service",
      "INVALID_START_TIME_DIFFERENCE": "The time difference between the end tme of the previous service and the start time of the next service should be at least one minute",
      "START_END_TIME_OVERLAP_TRIPS": "Start time or end time overlap with existing trips",
      "SHOW_DEPARTURES_VALIDATION_ERROR": "Invalid input: Start time, end time, or interval is missing or invalid."
    },
    "DEVICES": {
      "DELETE_DEVICE_TYPE": "Are you sure you want to delete this device?",
      "CREATE_DEVICE_TYPE": "Device created successfully",
      "SAVE_AS_DRAFT_DEVICE_TYPE": "Device saved as draft successfully",
      "UPDATE_DEVICE_TYPE": "Device details updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update device",
      "UNABLE_TO_CREATE": "Unable to create device",
      "DELETE_DEVICE_TYPE_SUCCESS": "Device deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete device",
      "UPDATE_DEVICE_TYPE_STATUS": "The Selected device(s) status updated successfully",
      "UNABLE_TO_UPDATE_DEVICE_TYPE_STATUS": "Unable to update device status"
    },
    "VEHICLE_DISPATCHING": {
      "UPDATE_VEHCILE_ASSIGNMENT": "Vehicle assignment added successfully",
      "UPDATE_REPLACEMENT_VEHCILE_ASSIGNMENT": "Replacement Vehicle assignment added successfully",
      "UPDATE_REPLACEMENT_ASSIGNMENT": "Replacement assignment updated successfully",
      "SAME_VEHICLES": "Same vehicle cannot be assigned as a replacement. Please select different vehicle.",
      "SELECT_DRIVER": "Please select driver or secondary driver in order to continue.",
      "SELECT_DRIVER_CONDUCTOR": "Please select driver or attendant in order to continue.",
      "ERROR_DRIVERS": "Same drivers cannot be assigned as a replacement. Please select different driver."
    },
    "USERS": {
      "EDIT_SUPER_ADMIN": "Super Admin cannot be edited",
      "DELETE_TEXT": "Are you sure you want to delete this user?",
      "CREATE_TEXT": "User created successfully",
      "SAVE_AS_DRAFT_TEXT": "User saved as pending successfully",
      "UPDATE_TEXT": "User detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update user",
      "DELETE_TEXT_SUCCESS": "User deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete user",
      "UPDATE_TEXT_STATUS": "The Selected user(s) status updated successfully",
      "UNABLE_TO_UPDATE_TEXT_STATUS": "Unable to update user status",
      "UPDATE_PASSWORD": "User password updated successfully",
      "UNABLE_TO_UPDATE_PASSWORD": "Unable to update user password",
      "DELETE_USER": "Are you sure you want to delete this user?",
      "CREATE_USER": "User created successfully",
      "SAVE_AS_DRAFT_USER": "User saved as draft successfully",
      "UPDATE_USER_PROFILE": "User profile updated successfully",
      "UPDATE_PROFILE": "Profile Updated Successfully! Please refresh the page or log out and log back in to view the changes.",
      "UNABLE_TO_UPDATE_PROFILE": "Unable to update user profile",
      "UPDATE_USER_ADDRESS": "User address updated successfully",
      "UNABLE_TO_UPDATE_ADDRESS": "Unable to update user address",
      "UPDATE_USER_DETAILS": "User detail updated successfully",
      "UNABLE_TO_UPDATE_DETAILS": "Unable to update user details",
      "UPDATE_USER_DOCUMENTS": "User document updated successfully",
      "UPDATE_UPLOAD_USER_DOCUMENTS": "User documents updated and uploaded successfully",
      "UNABLE_TO_UPDATE_DOCUMENTS": "Unable to update user documents",
      "UNABLE_TO_CREATE": "Unable to create user",
      "DELETE_DOCUMENT_SUCCESS": "Document deleted successfully",
      "UNABLE_TO_DELETE_DOCUMENT": "Unable to delete document",
      "DOWNLOAD_DOCUMENT_SUCCESS": "Document downloaded successfully",
      "UNABLE_TO_DOWNLOAD_DOCUMENT": "Unable to download document",
      "DELETE_USER_SUCCESS": "User deleted successfully",
      "UPDATE_USER_STATUS": "The Selected user(s) status updated successfully",
      "UNABLE_TO_UPDATE_USER_STATUS": "Unable to update user status",
      "UNABLE_TO_DOWNLOAD_TEMPLATE": "Unable to download template",
      "UNABLE_TO_EXPORT": "Unable to export users",
      "UNABLE_TO_EXPORT_NFC": "Unable to export NFC cards",
      "ONLY_EXCEL_FILE_ERROR": "Invalid file type! Only .xls and .xlsx are allowed",
      "ONLY_CSV_FILE_ERROR": "Invalid file type! Only .csv is allowed",
      "SELECT_FILE": "Please choose a file.",
      "IMPORT_USERS": "The import process has started. You will be notified via email upon its completion.",
      "UNABLE_TO_IMPORT": "The import process could not be started. Revise the submitted file",
      "UNABLE_TO_DELETE_SUPER_ADMIN": "One or more selected users have the 'super_admin' role. Users with 'super_admin' roles cannot be deleted.",
      "OWN_PROFILE_DELETION": "You cannot deactivate or delete your user profile",
      "USER_HAS_NO_ACTIVITIES": "The user has no activities",
      "SUPER_ADMIN_STATUS_CHANGE": "User of this role cannot change status of Super Admin"
    },
    "TRANSACTION_SETTLEMENT": {
      "FILTERS_REQUIRED_MESSAGE": "Specify the filters required to generate the report."
    },
    "ROLES": {
      "DELETE_ROLES": "Are you sure you want to delete this role?",
      "CREATE_TEXT": "Role created successfully",
      "SAVE_AS_DRAFT_TEXT": "Role saved as draft successfully",
      "CREATE_TEXT_PERMISSION": "Permission created successfully",
      "SAVE_AS_DRAFT_TEXT_PERMISSION": "Permission saved as draft successfully",
      "CREATE_ROLE_FIRST": "Create role first to assign it's permissions",
      "UPDATE_TEXT_PERMISSION": "Permission detail updated successfully",
      "UPDATE_TEXT": "Role detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update role",
      "UNABLE_TO_CREATE": "Unable to create role",
      "UNABLE_TO_UPDATE_PERMISSION": "Unable to update permission",
      "UNABLE_TO_CREATE_PERMISSION": "Unable to create permission",
      "DELETE_TEXT_SUCCESS": "Role deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete role",
      "UPDATE_TEXT_STATUS": "The Selected role(s) status updated successfully",
      "UNABLE_TO_UPDATE_TEXT_STATUS": "Unable to update role status"
    },
    "VEHICLES": {
      "SELECT_ROW": "Please select at least one row.",
      "CREATE_TEXT": "Vehicle created successfully",
      "SAVE_AS_DRAFT_TEXT": "Vehicle saved as draft successfully",
      "UPDATE_TEXT": "Vehicle details updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update vehicle",
      "UNABLE_TO_CREATE": "Unable to create vehicle",
      "DELETE_TEXT_SUCCESS": "Vehicle deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete vehicle",
      "UPDATE_TEXT_STATUS": "The Selected vehicle(s) status updated successfully",
      "UNABLE_TO_UPDATE_TEXT_STATUS": "Unable to update vehicle status",
      "DELETE_MESSAGE": "Are you sure you want to delete this vehicle ?",
      "MODELYEAR": "The value should be between {{yearThirtyYearsAgo}} - {{currentYear}}"
    },
    "STOPS": {
      "STOPS_ZONE_TOOLTIP": "Stops not belonging to Sub-Zones can only be used for Intercity lines.",
      "DELETE_STOP": "Are you sure you want to delete this stop?",
      "CREATE_STOP": "Stop created successfully",
      "SAVE_AS_DRAFT_STOP": "Stop saved as draft successfully",
      "UPDATE_STOP": "Stop detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update stop",
      "UNABLE_TO_CREATE": "Unable to create stop",
      "DELETE_STOP_SUCCESS": "Stop deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete stop",
      "UPDATE_STOP_STATUS": "The Selected stop(s) status updated successfully",
      "UNABLE_TO_UPDATE_STOP_STATUS": "Unable to update stop status",
      "UNABLE_TO_DOWNLOAD_TEMPLATE": "Unable to download template",
      "UNABLE_TO_EXPORT": "Unable to export stops",
      "ONLY_EXCEL_FILE_ERROR": "Invalid file type! Only .xls and .xlsx are allowed",
      "ONLY_CSV_FILE_ERROR": "Invalid file type! Only .csv is allowed",
      "SELECT_FILE": "Please choose a file.",
      "IMPORT_STOPS": "The import process has started. You will be notified via email upon its completion.",
      "UNABLE_TO_IMPORT": "The import process could not be started. Revise the submitted file"
    },
    "SEGMENTS": {
      "SAME_STOPS": "You cannot create segment with same stops",
      "SAME_PATTERNS": "You cannot create segment with same patterns",
      "DELETE_SEGMENT": "Are you sure you want to delete this segment?",
      "CREATE_SEGMENT": "Segment created successfully",
      "SAVE_AS_DRAFT_SEGMENT": "Segment saved as draft successfully",
      "UPDATE_SEGMENT": "Segment detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update segment",
      "UNABLE_TO_CREATE": "Unable to create segment",
      "DELETE_SEGMENT_SUCCESS": "Segment deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete segment",
      "UPDATE_SEGMENT_STATUS": "The Selected segment(s) status updated successfully",
      "UNABLE_TO_UPDATE_SEGMENT_STATUS": "Unable to update segment status",
      "OVER_LAPPING_PATTERNS": "Two normal patterns that are overlapping cannot be added"
    },
    "PATTERNS": {
      "DELETE_SYSTEM_PATTERN": "System patterns cannot be deleted",
      "DELETE_SYSTEM_PATTERN_ERROR": "System patterns cannot be deleted or updated",
      "DELETE_PATTERN": "Are you sure you want to delete this pattern?",
      "CREATE_PATTERN": "Pattern created successfully",
      "SAVE_AS_DRAFT_PATTERN": "Pattern saved as draft successfully",
      "UPDATE_PATTERN": "Pattern detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update pattern",
      "UNABLE_TO_CREATE": "Unable to create pattern",
      "DELETE_PATTERN_SUCCESS": "Pattern deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete pattern",
      "UPDATE_PATTERN_STATUS": "The Selected pattern(s) status updated successfully",
      "UNABLE_TO_UPDATE_PATTERN_STATUS": "Unable to update pattern status",
      "TIME_VALIDATION": "End time should not be before start time."
    },
    "URBAN_FARES": {
      "DELETE_URBAN_FARE": "Are you sure you want to delete this urban fare?",
      "CREATE_URBAN_FARE": "Urban fare created successfully",
      "SAVE_AS_DRAFT_URBAN_FARE": "Urban fare saved as draft successfully",
      "UPDATE_URBAN_FARE": "Urban fare detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update urban fare",
      "UNABLE_TO_CREATE": "Unable to create urban fare",
      "DELETE_URBAN_FARE_SUCCESS": "Urban fare deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete urban fare",
      "UPDATE_URBAN_FARE_STATUS": "The Selected urban fare(s) status updated successfully",
      "UNABLE_TO_UPDATE_URBAN_FARE_STATUS": "Unable to update urban fare status",
      "FARE_DUPLICATED": "Fare duplicated successfully",
      "UNABLE_TO_DUPLICATE_FARE": "Unable to duplicate the fare."
    },
    "FRAUD_TYPES": {
      "DELETE_FRAUD_TYPE": "Are you sure you want to delete this violation type?",
      "CREATE_FRAUD_TYPE": "Violation type created successfully",
      "SAVE_AS_DRAFT_FRAUD_TYPE": "Violation type saved as draft successfully",
      "UPDATE_FRAUD_TYPE": "Violation type details updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update violation type",
      "UNABLE_TO_CREATE": "Unable to create violation type",
      "DELETE_FRAUD_TYPE_SUCCESS": "Violation type deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete violation type",
      "UPDATE_FRAUD_TYPE_STATUS": "The Selected violation type(s) status updated successfully",
      "UNABLE_TO_UPDATE_FRAUD_TYPE_STATUS": "Unable to update violation type status"
    },
    "HOLIDAYS": {
      "DELETE_HOLIDAYS": "Are you sure you want to delete this holiday?",
      "CREATE_HOLIDAYS": "Holiday created successfully",
      "SAVE_AS_DRAFT_HOLIDAYS": "Holiday saved as draft successfully",
      "UPDATE_HOLIDAYS": "Holiday details updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update holiday",
      "UNABLE_TO_CREATE": "Unable to create holiday",
      "DELETE_HOLIDAYS_SUCCESS": "Holiday deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete holiday",
      "UPDATE_HOLIDAYS_STATUS": "The Selected holiday(s) status updated successfully",
      "UNABLE_TO_UPDATE_HOLIDAYS_STATUS": "Unable to update holiday status"
    },
    "PASSES_FARES": {
      "DELETE_PASSES_FARE": "Are you sure you want to delete this pass?",
      "CREATE_PASSES_FARE": "Pass created successfully",
      "SAVE_AS_DRAFT_PASSES_FARE": "Pass saved as draft successfully",
      "UPDATE_PASSES_FARE": "Pass detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update pass",
      "UNABLE_TO_CREATE": "Unable to create pass",
      "DELETE_PASSES_FARE_SUCCESS": "Pass deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete pass",
      "UPDATE_PASSES_FARE_STATUS": "The Selected pass(es) status updated successfully",
      "UNABLE_TO_UPDATE_PASSES_FARE_STATUS": "Unable to update pass status"
    },
    "INTERCITY_FARES": {
      "DELETE_INTERCITY_FARE": "Are you sure you want to delete this intercity fare?",
      "CREATE_INTERCITY_FARE": "Intercity fare created successfully",
      "SAVE_AS_DRAFT_INTERCITY_FARE": "Intercity fare saved as draft successfully",
      "UPDATE_INTERCITY_FARE": "Intercity fare detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update intercity fare",
      "UNABLE_TO_CREATE": "Unable to create intercity fare",
      "DELETE_INTERCITY_FARE_SUCCESS": "Intercity fare deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete intercity fare",
      "UPDATE_INTERCITY_FARE_STATUS": "The Selected intercity fare(s) status updated successfully",
      "UNABLE_TO_UPDATE_INTERCITY_FARE_STATUS": "Unable to update intercity fare status",
      "SELECT_LINE": "Please Select Line",
      "LOAD_VARIATIONS": "Please load variations!",
      "NO_STOPS": "You can't add fare for this line because there are no stops."
    },
    "LINES": {
      "START_STOP_STATION_ERROR": "Start Stop is advised as a Station",
      "END_STOP_STATION_ERROR": "End Stop is advised as a Station",
      "START_END_OUT_IN_ERROR": "The Start-Stop for the Out-trip should be the  End-Stop for the In-trip",
      "END_START_OUT_IN_ERROR": "The End-Stop for the Out trip should be the Start-Stop for the In-trip",
      "SAME_IN_STOPS": "A Line cannot have the same stop added as multiple times",
      "SAME_OUT_STOPS": "A Line cannot have the same stop added as multiple times",
      "DELETE_LINE": "Are you sure you want to delete this line?",
      "CREATE_LINE": "Line created successfully",
      "SAVE_AS_DRAFT_LINE": "Line saved as draft successfully",
      "CREATE_LINE_FIRST": "Save line first to update it's details",
      "UPDATE_LINE": "Line detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update line",
      "UNABLE_TO_CREATE": "Unable to create line",
      "DELETE_LINE_SUCCESS": "Line deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete line",
      "UPDATE_LINE_STATUS": "The Selected line(s) status updated successfully",
      "UNABLE_TO_UPDATE_LINE_STATUS": "Unable to update line status",
      "OCCURS_FROM_NEVER_ENDS": "Occurs {{occurrence}} from {{from}} ({{fromTime}} - {{toTime}}), and never ends",
      "OCCURS_FROM_UNTIL": "Occurs {{occurrence}} from {{from}} ({{fromTime}} - {{toTime}}), Until {{to}}"
    },
    "TRIPS": {
      "DELETE_TRIP": "Are you sure you want to delete this trip?",
      "CREATE_TRIP": "Trip created successfully",
      "CREATE_TRIPS": "Trips created successfully",
      "SAVE_AS_DRAFT_TRIP": "Trip saved as draft successfully",
      "UPDATE_TRIP": "Trip detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update trip",
      "UNABLE_TO_CREATE": "Unable to create trip",
      "UNABLE_TO_CREATES": "Unable to create trips",
      "DELETE_TRIP_SUCCESS": "Trip deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete trip",
      "UPDATE_TRIP_STATUS": "The Selected trip(s) status updated successfully",
      "UNABLE_TO_UPDATE_TRIP_STATUS": "Unable to update trip status",
      "TRIP_DUPLICATED": "Trip duplicated successfully",
      "UNABLE_TO_TRIP_DUPLICATE": "Unable to duplicate the trip.",
      "TRIP_TIME_ERROR": "Invalid time format. Please enter the time in hh:mm format, where hours are between 00 and 23 and minutes are between 00 and 59.",
      "DEPARTURE_TIME_EXISTS_ALREADY": "Departure time exists already",
      "DEPARTURE_TIMES_MISSING": "Please enter departure times",
      "CREATE_DUPLICATE_TRIPS": "Success: {{tripsCount}} out of {{totalTripsCount}} were created, as the remaining {{duplicateCount}} already exist!",
      "CREATE_WITHOUT_DUPLICATE_TRIPS": "Success: {{tripsCount}} Trips are currently being created. Please check back in a few moments."
    },
    "NOTIFICATIONS": {
      "DELETE_NOTIFICATION": "Are you sure you want to delete this notification?",
      "CREATE_NOTIFICATION": "Notification created successfully",
      "CREATE_NOTIFICATIONS": "Notifications created successfully",
      "SAVE_AS_DRAFT_NOTIFICATION": "Notification saved as draft successfully",
      "UPDATE_NOTIFICATION": "Notification detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update notification",
      "UNABLE_TO_CREATE": "Unable to create notification",
      "UNABLE_TO_CREATES": "Unable to create notifications",
      "DELETE_NOTIFICATION_SUCCESS": "Notification deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete notification",
      "UPDATE_NOTIFICATION_STATUS": "The Selected notification(s) status updated successfully",
      "UNABLE_TO_UPDATE_NOTIFICATION_STATUS": "Unable to update notification status"
    },
    "TIMETABLE": {
      "CREATE": "Timetable created successfully",
      "INVALID_DATE": "Date To cannot be greater than Date From",
      "INVALID_WEEKDAY": "Invalid selection",
      "OCCURRENCES_MISSING_OR_NOT_COMPATIBLE": "Occurrences are either missing or not compatible with the selected date range.",
      "NO_RESULT_FOUND": "No trips available for the selected Line on that date",
      "SAVE_AS_DRAFT": "Timetable saved as draft successfully",
      "UNABLE_TO_CREATE": "Unable to create trip",
      "UPDATE_TIMETABLE_STATUS": "The Selected timetable(s) status updated successfully",
      "UNABLE_TO_UPDATE_STATUS": "Unable to update trip status",
      "DUPLICATE_SINGLE": "Only single Timetable can be selected for duplicate",
      "DUPLICATE_TIMETABLE_SELECT": "Please selecte a Timetable",
      "DUPLICATE_SUCCESS": "Timetable duplicated successfully!",
      "NO_TRIPS": "No Trips Added",
      "TRIPS_OVERLAPPING": "Trips Overlapping Detected!",
      "INVALID_WEEKDAYS": "Please select at least one day of the week",
      "PUBLISH_TIMETABLE": "Publishing a timetable is irreversible, the timetable cannot be changed afterward!",
      "PUBLISH_TIMETABLE_TRIPS": "Publishing a timetable is irreversible, the timetable cannot be changed afterward! All created Trips that are not assigned to blocks will no longer be useful, so please delete them or assign them."
    },
    "PLAN": {
      "CREATE": "Plan created successfully",
      "SERVICE_ASSIGN_TO_ROW": "Services assigned to row successfully",
      "SERVICE_UNASSIGN_TO_ROW": "Services unassigned to row successfully",
      "INVALID_DATE": "Planning Until cannot be less than From Last Plan Date",
      "SAVE_AS_DRAFT": "Plan saved as draft successfully",
      "UNABLE_TO_CREATE": "Unable to create Plan",
      "UPDATE_STATUS": "The Selected plan(s) status updated successfully",
      "UNABLE_TO_UPDATE_STATUS": "Unable to update trip status",
      "DUPLICATE_SINGLE": "Only single Plan can be selected for duplicate",
      "DUPLICATE_SELECT": "Please selecte a Plan",
      "SELECT_ONE_SERVICE": "Please select at least one service",
      "PLAN_LIMIT": "You are only allowed to plan for the next two months at a time.",
      "TIME_LIMIT": "Time To should be greater than Time From.",
      "SERVICE_OVERLAP": "Service’s shifts cannot overlap.",
      "INTERCITY_TO_URBAN_ASSIGNMENT_ERROR": "Issue with service assignment from Intercity to Urban",
      "OVERLAPPING_PAYLOAD_SHIFTS_ERROR": "A shift already exists for the selected parameters",
      "DATE_RANGE_ERROR": "Issue with the selected date range",
      "OVERLAPPING_EXISTING_SHIFTS_ERROR": "Service’s shifts cannot overlap",
      "DUPLICATE_SUCCESS": "Plan duplicated successfully!"
    },
    "SCHEDULE_SERVICE_GROUP": {
      "ADD_SERVICE_GROUP": "Service group added successfully",
      "ADD_SERVICE_GROUP_ERROR": "Unable to add service group",
      "EDIT_SERVICE_GROUP": "Service group edited successfully",
      "EDIT_SERVICE_GROUP_ERROR": "Unable to edit service group",
      "DELETE_SERVICE_GROUP": "Service group deleted successfully",
      "DELETE_SERVICE_GROUP_ERROR": "Unable to delete service group"
    },
    "NFC_CARDS": {
      "DELETE_CARD": "Are you sure you want to delete this nfc card?",
      "CREATE_CARD": "Nfc card created successfully",
      "SAVE_AS_DRAFT_CARD": "Nfc card saved as draft successfully",
      "UPDATE_CARD": "Card details updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update card",
      "UNABLE_TO_CREATE": "Unable to create card",
      "DELETE_CARD_SUCCESS": "Card deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete card",
      "UPDATE_CARD_STATUS": "The Selected card(s) status updated successfully",
      "UNABLE_TO_UPDATE_CARD_STATUS": "Unable to update card status",
      "DELETE_MSG": "Only cards with status 'New' can be deleted"
    },
    "ZONE": {
      "INVALID_SUBZONE": "Please add Sub-Zone",
      "INVALID_ZONE": "Please add a Zone",
      "DELETE_ZONE": "Are you sure you want to delete this zone?",
      "DELETE_SUB_ZONE": "Are you sure you want to delete this sub-zone?",
      "DELETE_ZONE_SUCCESS": "Zone deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete zone",
      "MSG_MAX_ZONE_ALLOWED": "Maximum allowed points are 70"
    }
  },
  "BREADCRUMBS": {
    "REPORTS": {
      "CUSTOMERS_REPORT": "Customers Report",
      "FINANCES_REPORT": "Finance Report",
      "LINE_COMPARISON_REPORT": "Line Comparison Report",
      "RIDERSHIP_REPORT": "Ridership Report",
      "VEHICLE_REPORT": "Vehicle Reports",
      "BUS_CONDUCTOR_REPORT": "Attendant Report",
      "SALES_AGENT_REPORT": "Sales Agent Report",
      "POS_OPERATOR_REPORT": "POS Operator Report",
      "SHIPMENT_REPORT": "Shipment Report"
    },
    "USER_MANAGEMENT": {
      "TITLE": "User Management",
      "USER_LISTING": "Users",
      "ADMIN_USER": "Admin User",
      "APP_USER": "App User",
      "ADD_USER": "Add User",
      "EDIT_USER": "Edit User"
    },
    "DASHBOARD": {
      "TITLE": "Dashboard"
    },
    "PROFILE": {
      "TITLE": "Profile"
    },
    "SYSTEM_LOGS": {
      "TITLE": "System Logs"
    },
    "E_TICKETING": {
      "TITLE": "Automated Fare Collection",
      "TITLE_MINI": "AFC",
      "PASSES_MANAGEMENT": "Passes Management",
      "CREATE_PASS": "Add New Pass",
      "PASS_DETAIL": "Pass Details",
      "INTERCITY_FARES": "Intercity Fares",
      "ADD_INTERCITY_FARE": "Add Intercity Fares",
      "DETAIL_INTERCITY_FARE": "Intercity Fares Details",
      "URBAN_FARES": "Urban Fares",
      "ADD_URBAN_FARE": "Add Urban Fares",
      "DETAIL_URBAN_FARE": "Urban Fares Details",
      "ZONE_LISTING": "Zones",
      "ADD_ZONE": "Add Zone",
      "DETAIL_ZONE": "Zone Detail",
      "FRAUDS": "Violations & Fines",
      "NEW_FRAUD": "Add Violation",
      "FRAUD_DETAILS": "Violation Details",
      "TRANSACTION_SETTLEMENT": "Transaction Settlement",
      "TRANSACTION_SETTLEMENT_DETAILS": "Transaction Settlement Details"
    },
    "ROLES_AND_PERMISSIONS": {
      "TITLE": "Roles & Permissions",
      "ACTIVE_ROLE_LIST": "Active Role List",
      "CREATE_ROLE": "Add Role",
      "EDIT_ROLE": "Edit Role"
    },
    "NETWORK_AND_PLANNING": {
      "TITLE": "Network",
      "STOPS": "Stops",
      "ADD_STOP": "Add Stop",
      "EDIT_STOP": "Edit Stop",
      "SEGMENTS": "Segments",
      "ADD_SEGMENT": "Add Segment",
      "EDIT_SEGMENT": "Edit Segment",
      "PATTERNS": "Patterns",
      "ADD_PATTERN": "Add Pattern",
      "EDIT_PATTERN": "Edit Pattern",
      "LINES": "Lines",
      "ADD_LINE": "Add Line",
      "EDIT_LINE": "Edit Line",
      "TRIPS": "Trips",
      "ADD_TRIP": "Add Trip",
      "EDIT_TRIP": "Edit Trip",
      "ADD_TRIP_BULK": "Add Trip By Bulk",
      "TIMETABLE": "Timetable",
      "ADD_TIMETABLE": "Add Timetable",
      "EDIT_TIMETABLE": "Edit Timetable"
    },
    "SCHEDULE": {
      "TITLE": "Planning",
      "SERVICE": "Services Creation",
      "ADD_SERVICE": "Add Services Creation",
      "EDIT_SERVICE": "Edit Service Creation",
      "EMPLOYEE_ROSTER": "Employee Roster Listing",
      "HOLIDAYS": "Holidays",
      "VEHICLE_DISPATCHING": "Vehicle Dispatching",
      "VEHICLE_DISPATCHING_DETAIL": "Detail"
    },
    "ROSTER": {
      "SERVICE_ROSTER_LISTING": "Service Roster Listing"
    },
    "SCHEDULE_EMPLOYEE_ENGAGEMENT": "Employee Engagement",
    "COLLECTION_REVENUE": "Collection Revenue",
    "FLEET_MANAGEMENT": {
      "TITLE": "Fleet Management",
      "VEHICLES_MANAGEMENT": "Vehicles Management"
    }
  },
  "PAGINATION": {
    "SHOWING": "Showing",
    "TO": "to",
    "OF": "of",
    "ENTERIES": "entries"
  },
  "MENU": {
    "Main": "Main",
    "DASHBOARD": "Dashboard",
    "MODULE": "MODULE",
    "ADMIN_USERS": "Admin Users",
    "APP_USERS": "App Users",
    "ORDERS": "Orders",
    "RETURNS": "Returns",
    "PAYMENTS_METHODS": "Payments Methods",
    "DELIVERY_METHODS": "Delivery Methods",
    "PRODUCTS": "Products",
    "CATEGORIES": "Categories",
    "PAGES": "Pages",
    "SETTINGS": "Settings",
    "USER_MANAGEMENT": "User Management",
    "SYSTEM_SETTINGS": "System Settings"
  
  },
  "TABLE": {
    "BASED_ON": "Based On",
    "PERMISSIONS": "Permissions"
  },
  "CONSTANTS": {
    "DEACTIVATED": "Deactivated",
    "DELETED": "Deleted",
    "ADMIN_USER": "Admin User",
    "APP_USER": "App User",
    "STOP": "Stop",
    "STATION": "Station",
    "SEGMENT": "Segment",
    "URBAN": "Urban",
    "INTERCITY": "Intercity",
    "SINGLE": "Single",
    "BULK": "Bulk",
    "IN": "In",
    "OUT": "Out",
    "ONBOARD": "On-Board",
    "HANDHELD": "Hand-Held",
    "COMMERCIAL": "Commercial",
    "POINT_OF_SALES": "Point Of Sales",
    "FX925": "FX925 Validator",
    "FX205": "FX205 Mobile/Android",
    "FX408": "FX408 Tablet/Android",
    "INNER_HANOVER_DISPLAY": "Inner Hanover Display",
    "OUTER_HANOVER_DISPLAY": "Outer Hanover Display",
    "LAPTOP": "Laptop",
    "CAMERA": "Camera",
    "THERMAL_PRINTER": "Thermal Printer",
    "CARD_PRINTER": "Card Printer",
    "FREE": "Free Pass",
    "SPECIAL": "Special Pass",
    "TIME_BASED": "Time-Based Pass",
    "NORMAL_PRIORITY": "Normal Priority",
    "SYSTEM": "System",
    "HIGH_PRIORITY": "High Priority",
    "ONE_TIME": "One Time",
    "REPEATED_WEEKDAY": "Repeat Weekdays",
    "REPEATED_WEEKEND": "Repeat Weekends",
    "DAILY": "Daily",
    "CUSTOM": "Custom",
    "STATUS": {
      "ACTIVE": "Active",
      "INACTIVE": "Inactive",
      "DRAFT": "Draft",
      "PENDING": "Pending",
      "PUBLISHED": "Published",
      "IN_PROGRESS": "In Progress",
      "READY": "Ready",
      "CANCELLED": "Cancelled",
      "DONE": "Done",
      "OUTOFSERVICE": "Out Of Service",
      "MAINTENANCE": "Maintenance",
      "SETTLED": "Settled",
      "UNSETTLED": "Unsettled",
      "SENT": "Sent"
    },
    "ROLE": {
      "DRIVER": "Driver",
      "ATTENDANT": "Attendant"
    }
  }
}
