import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHandlerFn,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService, SharedDataService } from '../services';
import {
  DEFAULT_INVALID_TOKEN_SERVER_RESPONSE,
  DEFAULT_INVALID_TOKEN_SIGNATURE_SERVER_RESPONSE,
} from '@src/app/core/constants/system.constant';
import { AuthService } from '@src/app/core/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import {CURRENT_USER_DATA} from '@src/app/core/constants/system.constant';
import { inject } from '@angular/core';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   * Constructs a new instance of the class.
   *
   * @param {SharedDataService} sharedDataService - The shared data service.
   * @param {LocalStorageService} localStorage - The local storage service.
   * @param {AuthService} authService - The authentication service.
   * @param {Router} router - The router service.
   */
  constructor(
    private sharedDataService: SharedDataService,
    private localStorage: LocalStorageService,
    private localService: LocalStorageService,
    private authService: AuthService,
    private router: Router,
  ) {}
  /**
   * Intercepts the HTTP request and handles the response.
   *
   * @param {HttpRequest<any>} request - The HTTP request to be intercepted.
   * @param {HttpHandler} next - The next handler in the chain.
   * @return {Observable<HttpEvent<any>>} The observable that emits the HTTP event.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const userLanguage = navigator.language;
    const langCode =
      this.sharedDataService.localService
        .getDataInLocalStorage('language', false)
        ?.code?.toLowerCase() || 'fr';
    request = request.clone({
      withCredentials: true,
      setHeaders: { 'Accept-Language': langCode },
    });
    return next.handle(request).pipe(
      tap({
      /**
       * Handles the next HTTP event.
       *
       * @param {HttpEvent<any>} event - The HTTP event to handle.
       */
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // if any response says, token not provided
            if (
              event?.body?.message === DEFAULT_INVALID_TOKEN_SERVER_RESPONSE ||
              event?.body?.message === DEFAULT_INVALID_TOKEN_SIGNATURE_SERVER_RESPONSE ||
              event?.status === 401 ||
              event?.body?.status === 401
            ) {
              // this.handleLogout();
              console.log("logout")
            }
          }
        },
    /**
     * Handles the error response.
     *
     * @param {any} err - The error response.
     */
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.handleLogout();
            }
          }
        },
      }),
    );
  }
  /**
   * Handles the logout process.
   *
   * This function clears the local storage except for the 'senegal-app-config' and 'language' keys.
   * It also sets the 'returnUrl' key in the local storage if the current URL does not include 'no-access' or 'otp'.
   * The 'returnUrl' value is a JSON object containing the current URL, the current timestamp, and the employee ID from the 'senegal user'.
   * If the 'senegal user' data is not present or cannot be parsed, the employee ID is set to 'notFound'.
   * After a 500ms delay, the function sets the user details and system defaults to null, clears the 'senegal user' data in the local storage,
   * and redirects the user to the '/auth/login' page.
   *
   * @return {void} This function does not return a value.
   */
  private handleLogout(): void {
    this.sharedDataService.showLoadingBar(false);
    this.localService.clearDataInLocalStorage();




    const currentUrl = this.router.url;

    this.sharedDataService.showLoadingBar(false);
    this.localStorage.clearLocalStorageExceptOneKey(['senegal-app-config', 'language']);
    setTimeout(() => {
      // location.replace('/auth/signin');
      if (localStorage.getItem('returnUrl')) {
        localStorage.removeItem('returnUrl');
      }
      if (!currentUrl.includes('no-access') && !currentUrl.includes('otp')) {
        localStorage.setItem(
          'returnUrl',
          JSON.stringify({
            url: currentUrl,
            time: new Date().getTime(),
            id:
              (localStorage.getItem(CURRENT_USER_DATA) &&
                JSON.parse(localStorage.getItem(CURRENT_USER_DATA) || 'null').employeeId) ||
              'notFound',
          }),
        );
      }
      this.authService.setUserDetails(null);
      this.authService.setSystemDefaults(null);
      localStorage.setItem(CURRENT_USER_DATA, '');
      location.replace('/auth/login');
    }, 500);
  }
}




// intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

//   request = request.clone({
//     setHeaders: {
//       'x-locale': '',
//     }
//   });
//   // Checking token only for Auth apis
//   const url: string = request.url.toLowerCase();
//   const isTokenRequired = url.includes('/logout') || !url.includes('/auth/');
//   if (isTokenRequired) {
//     const token = this.localService.getToken();
//     if (token) {
//       request = request.clone({
//         setHeaders: {
//           Authorization: `Bearer ${token}`,
//         }
//       });
//     }
//   }

//   return next.handle(request)
//     .pipe(
//       tap({
//         next: (event: HttpEvent<any>) => {
//           if (event instanceof HttpResponse) {
//             // if any response says, token not provided
//             if (event.body.message === DEFAULT_INVALID_TOKEN_SERVER_RESPONSE ||
//               event.body.message === DEFAULT_INVALID_TOKEN_SIGNATURE_SERVER_RESPONSE ||
//               event.status == 401 || event?.body?.status == 401) {
//               this.handleLogout();
//             }
//           }
//         },
//         error: (err: any) => {
//           if (err instanceof HttpErrorResponse) {
//             if (err.status == 401) {
//               this.handleLogout();
//             }
//           }
//         }
//       })
//     );
// }


function handleLogout(
  localStorageService: LocalStorageService
): void {
  localStorageService.clearDataInLocalStorage();
  location.replace('/login');
}
export const jwtInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const localStorageService = inject(LocalStorageService);
  request = request.clone({
    setHeaders: {
      'x-locale': '',
    }
  });
  // Checking token only for Auth apis
  const url: string = request.url.toLowerCase();
  const isTokenRequired = !url.includes('/login');

  if (isTokenRequired) {
    const token = localStorageService.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        }
      });
    }
  }

  return next(request)
    .pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // if any response says, token not provided
            if (!isTokenRequired &&
              (event.body.message === DEFAULT_INVALID_TOKEN_SERVER_RESPONSE ||
              event.body.message === DEFAULT_INVALID_TOKEN_SIGNATURE_SERVER_RESPONSE ||
              event.status == 401 || event?.body?.status == 401)) {
              handleLogout(
                localStorageService
              );
            }
          }
        },
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (!isTokenRequired && err.status == 401) {
              handleLogout(
                localStorageService
              );
            }
          }
        }
      })
    );
};