{
  "PAGE_TITLE": "DDD - Centre de contrôle",
  "APP_TITLE": "Sénégal",
  "ENGLISH": "Anglais",
  "FRENCH": "Français",
  "WELCOME": "Bienvenue au Sénégal",
  "COMPONENTS": {
    "DASHBOARD": "Tableau de bord",
    "USERS": {
      "USER_PROFILE": "Profil de l'utilisateur",
      "PERFORMED_ON": "Exécuté sur",
      "ABOUT": "À propos",
      "ACTOR": "Acteur",
      "TIME": "Heure",
      "DATE": "Date",
      "MODULE": "Module",
      "SUB_MODULE": "Sous-Module",
      "UPLOAD_PHOTO": "Télécharger la photo",
      "UPDATE": "Mise à jour",
      "FIRST_NAME": "Prénom",
      "LAST_NAME": "Nom de famille",
      "DATE_OF_BIRTH": "Date de naissance",
      "GENDER": "Genre",
      "MALE": "Homme",
      "FEMALE": "Femme",
      "ROLE": "Rôle",
      "CREATION_DATE": "Date de création",
      "EMAIL_ADDRESS": "Adresse E-mail",
      "CONTACT_NUMBER": "Numéro de contact",
      "SELECT_ROLES": "Sélectionner les rôles",
      "EMAIL": "E-mail",
      "USER_ID": "Identifiant de l'employé",
      "LANDLINE_NUMBER": "Numéro de téléphone fixe",
      "MOBILE_NUMBER": "Numéro de téléphone portable",
      "SEARCH_BY_COUNTRY": "Recherche par nom ou code de pays",
      "PASSWORD": "Mot de passe",
      "NEW_PASSWORD": "Nouveau mot de passe",
      "PASSWORD_INFO": "Au moins 8 caractères, une lettre majuscule, un chiffre et un caractère spécial",
      "CHANGE_PASSWORD": "Modifier le mot de passe",
      "OFFICIAL_DETAILS": "Détails officiels",
      "OFFICIAL_CONTACT_NUMBER": "Numéro de contact officiel",
      "OFFICIAL_EMAIL_ADDRESS": "Adresse E-mail officielle",
      "DESIGNATION": "Statut d'Emploi",
      "USER_ADDRESS": "Adresse de l'utilisateur",
      "COUNTRY": "Pays de résidence",
      "SELECT_COUNTRY": "Sélectionner le pays",
      "CITY": "Ville",
      "SELECT_CITY": "Sélectionner la ville",
      "COMPLETE_ADDRESS": "Compléter l'adresse",
      "USER_DOCUMENTS": "Documents de l'utilisateur",
      "UPLOAD": "Télécharger",
      "DRIVING_LICENSE_NUMBER": "Numéro de permis de conduire",
      "DRIVING_LICENSE_EXPIRY_DATE": "Date d'expiration du permis de conduire",
      "NATIONAL_IDENTITY_NUMBER": "Numéro d'identité nationale",
      "UPLOADED_ON": "Téléchargé le",
      "DOWNLOAD": "Télécharger",
      "USER_ACTIVITIES": "Activités de l'utilisateur",
      "IMPORT_FILE_NAME": "modèle-importation-utilisateurs",
      "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Utilisateurs - {{date}} - {{time}}",
      "LENGTH_SIX_DIGITS": "Longueur : 6 chiffres",
      "LENGTH_EIGHT_SIXTEEN_CHARACTERS": "Longueur : 8 à 16 caractères",
      "ONE_CAPITAL": "Au moins 1 lettre majuscule",
      "ONE_LOWER": "Au moins 1 lettre minuscule",
      "ONE_NUMBER": "Au moins 1 chiffre",
      "PASSENGER": "Passager",
      "VALIDATOR": "Validateur"
    },
    "STOPS": {
      "TITLE": "Détails de l'arrêt",
      "ADD": "Ajouter un arrêt",
      "IMPORT_FILE_NAME": "modèle-importation-arrêt ",
      "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Arrêts - {{date}} - {{time}}",
      "STATION": "Station",
      "FIELDS": {
        "NAME": "Nom de l'arrêt",
        "ENTER_STOP_NAME": "Entrez le Nom de l'Arrêt",
        "ZONE_SUBZONE": "Zone / Sous-zone",
        "DESCRIPTION": "Description",
        "LATITUDE": "Latitude",
        "LONGITUDE": "Longitude",
        "GEO_LOCATION": "Géolocalisation",
        "SELECT_GEO_LOCATION": "Sélectionner la géolocalisation",
        "STOP_ID": "ID",
        "ENTER_STOP_ID": "Entrer l'ID de l'arrêt",
        "TYPE": "Type",
        "SELECT_TYPE": "Sélectionner le Type"
      }
    },
    "ROLES": {
      "CREATE_TITLE": "Ajouter un rôle",
      "TITLE": "Détails du rôle",
      "ROLE": "Rôle",
      "PERMISSION_TITLE": "Attribuer des autorisations",
      "ADD": "Ajouter un arrêt",
      "FIELDS": {
        "NAME": "Nom du rôle",
        "NAME_PLACEHOLDER": "ex. Chauffeur",
        "DESCRIPTION_PLACEHOLDER": "ex. Chauffeur",
        "DESCRIPTION": "Description du rôle",
        "LATITUDE": "Latitude",
        "LONGITUDE": "Longitude",
        "GEO_LOCATION": "Géolocalisation",
        "SELECT_GEO_LOCATION": "Sélectionner la géolocalisation"
      }
    },
    "LINES": {
      "CREATE_TITLE": "Ajouter une ligne",
      "TITLE": "Détails de la ligne",
      "RETURN_TO_GARAGE": "Retour au garage",
      "BUS_PREPARATION": "Préparation des bus",
      "LINE_CONFIGURATION": "Configuration des lignes",
      "OUT_STOPS": "Arrêts de sortie",
      "IN_STOPS": "Arrêts d'entrée",
      "OUT_SEGMENTS": "Sections sortants",
      "IN_SEGMENTS": "Sections entrants",
      "ADD": "Ajouter un arrêt",
      "STOPS": "Arrêts",
      "FIELDS": {
        "NAME": "Nom de la ligne",
        "TYPE": "Type",
        "BY_STOPS": "Par arrêt",
        "MINUTES": "Minutes",
        "BY_SEGMENTS": "Par Sections",
        "SELECT_TYPE": "Sélectionner le type",
        "NAME_PLACEHOLDER": "ex. Chauffeur",
        "DESCRIPTION_PLACEHOLDER": "ex. Chauffeur",
        "DESCRIPTION": "Description",
        "PREPARATION_TIME": "Temps de préparation",
        "DRIVING_TO_START_STOP_TIME": "Temps du garage à l'arrêt de départ de la ligne sortante",
        "SHORTEST_OUT_LINE_DISTANCE": "Distance la plus courte des lignes sortantes",
        "AVERAGE_OUT_LINE_DISTANCE": "Distance des lignes sortantes",
        "AVERAGE_EMPTY_OUT_TRIP_TIME": "Temps moyen du voyage sortant vide",
        "AVERAGE_EMPTY_IN_TRIP_TIME": "Temps moyen du voyage entrant vide",
        "DRIVING_FROM_START_STOP": "Heure de début et d'arrêt de la ligne entrante au garage",
        "SHORTEST_IN_LINE_DISTANCE": "Distance la plus courte des lignes entrantes",
        "AVERAGE_IN_LINE_DISTANCE": "Distance des lignes entrantes",
        "CLOSURE_TIME": "Temps de fermeture",
        "INTERMEDIATE_STOP": "Arrêt intermédiaire",
        "START_SEGMENT": "Sections de départ",
        "FOLLOW_UP_SEGMENTS": "Sections de suivi",
        "FOLLOW_UP_SEGMENT": "Sections de suivi",
        "INTERMEDIATE_SEGMENT": "Sections intermédiaire",
        "ESTIMATED_RUNTIME": "Durée d'exécution estimée",
        "ESTIMATED_OUT_SEGMENTS_RUNTIME": "Durée d'exécution estimée des sections de sortie (schéma par défaut)",
        "NUMBER_PLACEHOLDER": 0,
        "END_STOP": "Fin de l'arrêt",
        "START_STOP": "Début de l'arrêt",
        "STARTING_STOP": "Arrêt de départ",
        "RELATED_PATTERNS": "Modèles liés",
        "PATTERNS": "Modèles",
        "LONGITUDE": "Longitude",
        "GEO_LOCATION": "Géolocalisation",
        "SELECT_GEO_LOCATION": "Sélectionner la géolocalisation",
        "HANDOVER_TIME": "Durée de remise"
      }
    },
    "SEGMENTS": {
      "TITLE": "Détails de la Section",
      "FIELDS": {
        "NAME": "Nom de la Section",
        "SYSTEM_PATTERN": "Modèle du Système",
        "DESCRIPTION": "Description ",
        "INTERMEDIATE_STOP": "Arrêt intermédiaire",
        "END_STOP": "Arrêt final",
        "START_STOP": "Arrêt de départ",
        "STARTING_STOP": "Arrêt de départ",
        "ENDING_STOP": "Arrêt final",
        "PATTERNS": "Modèles",
        "MINUTES": "Minutes"
      }
    },
    "LOGIN": {
      "TITLE": "Connexion",
      "FIELDS": {
        "EMAIL": "Adresse e-mail",
        "PASSWORD": "Mot de passe",
        "FORGOT_PASSWORD": "Mot de passe oublié",
        "OTP": "CUU",
        "ENTER_OTP": "Saisir le CUU",
        "OTP_SENT_TO": "Le CUU est envoyé à",
        "CHECK_INBOX": "veuillez vérifier votre boîte de réception",
        "INCASE_YOU_DIDNT_RECEIVE": "Si vous ne l'avez pas reçu, cliquez sur",
        "CREDENTIALS_VERIFIED": "Vos informations d'identification ont été vérifiées. Veuillez saisir le CUU pour une authentification ultérieure.",
        "PLEASE_CHECK_INBOX": "Un CUU sera envoyé à votre adresse e-mail",
        "UNABLE_TO_LOGIN": "Impossible de se connecter",
        "RESEND_OTP": "Renvoyer le CUU",
        "USER_LOGGED_IN": "L'utilisateur s'est connecté avec succès",
        "UNABLE_TO_VERIFY": "Impossible de vérifier le CUU",
        "OTP_DISABLED": "Le bouton Renvoyer le CUU est désactivé pour",
        "OTP_SENT": "Un CUU a été envoyé à votre adresse e-mail",
        "RESET_LINK": "L'adresse e-mail saisie est valide. Le lien de réinitialisation du mot de passe a été envoyé avec succès",
        "UNABLE_TO_RESET": "Impossible de réinitialiser le mot de passe",
        "RESET_LINK_INVALID": "Le lien de réinitialisation du mot de passe n'est pas valide",
        "PASSWORD_RESET_SUCCESSFULLY": "Réinitialisation du mot de passe réussie",
        "PASSWORD_DONOT_MATCH": "Les mots de passe ne correspondent pas",
        "RESET_PASSWORD_LINK_SENT": "Le lien de réinitialisation du mot de passe sera envoyé à votre adresse e-mail",
        "RESEND_LINK": "Renvoyer le lien",
        "SET_NEW_PASSWORD": "Définir un nouveau mot de passe",
        "ENTER_NEW_PASSWORD": "Saisir le nouveau mot de passe",
        "CONFIRM_NEW_PASSWORD": "Confirmer le nouveau mot de passe",
        "PASSWORD_VALIDATION": "Au moins 8 caractères, une lettre majuscule, un chiffre et un caractère spécial."
      }
    },
    "URBAN_FARES": {
      "TITLE": "Ajouter des tarifs",
      "EDIT_TITLE": "Détails des tarifs urbains",
      "FIELDS": {
        "FARE_NAME": "Nom du Tarif",
        "TYPE_FARE_NAME": "Entrez le Nom du Tarif",
        "COUNTRY_NAME": "Nom du pays",
        "SELECT_COUNTRY_NAME": "Sélectionner le nom du pays",
        "CITY_NAME": "Nom de la ville",
        "SELECT_CITY_NAME": "Sélectionnez le nom de la ville",
        "VALID_FROM": "Valable à partir de",
        "SUB_ZONES_FARES_STRUCTURE": "Structure Tarifaire par Sous-Zones",
        "ZONES_FARES_STRUCTURE": "Structure Tarifaire par Zones",
        "SAME_SUB_ZONE": "Même sous-zone",
        "SECOND_SUB_ZONE": "2ème sous-zone",
        "THIRD_SUB_ZONE": "3ème sous-zone",
        "FOURTH_SUB_ZONE": "4ème sous-zone et plus",
        "FIRST_ZONE": "Franchissement de la 1ère zone",
        "SECOND_ZONE": "Franchissement de la 2ème zone",
        "THIRD_ZONE": "Franchissement de la 3ème zone",
        "FOURTH_ZONE": "Franchissement de la 4ème zone et plus",
        "DUPLICATE": "Dupliquer le Tarif"
      }
    },
    "REPORTS": {
      "TOTAL_PASSENGERS": "Passagers Totaux",
      "POSITION": "Position",
      "SCHEDULE_TIME": "Heure Programmée",
      "REAL_TIME": "Heure Réelle",
      "GAP": "Écart",
      "TRIP_STATE": "État du Voyage",
      "REASON_FOR_DELAY": "Raison du Retard",
      "PHYSICAL_TICKETS_USED": "#Billets Physiques Utilisés",
      "DIGITAL_TICKETS_USED": "#Billets Numériques Utilisés",
      "CARDS_ENTRIES": "#D'entrées des Cartes",
      "CARDS_EXITS": "#De Sorties des Cartes",
      "CARDS_ENTRY_VS_EXIT": "#De Différences d'Entrées/Sorties des Cartes",
      "UNBLOCKS": "#Déblocages",
      "PASSES_USED": "#Abonnements Utilisés",
      "VEHICLE_VIN": "VIN du Véhicule",
      "CREATED_DATE": "Date de Création",
      "TOTAL_DISTANCE_TRAVELLED": "Distance Totale Parcourue",
      "TRIP_DISTANCE": "Distance du Voyage",
      "NON_TRIP_DISTANCE": "Distance Hors Voyage",
      "TRIPS_MADE": "#Voyages Réalisés",
      "BUS_CONDUCTOR_NAME": "Nom du Receveur",
      "CUSTOMER_PROFILE_ID": "ID du Profil Client",
      "CARD_EXPIRY": "Expiration de la Carte",
      "OLD_BALANCE": "Ancien Solde",
      "UPDATED_BALANCE": "Solde Mis à Jour",
      "NEW_BALANCE": "Nouveau Solde",
      "CASH_COLLECTED": "Argent Collecté",
      "FINE_COLLECTED": "Amende Collectée",
      "SALES_AGENT_NAME": "Nom de l'Agent de Point de Ventes",
      "SHIPMENT_TYPE": "Type d'Expédition",
      "SHIPMENT_FARE": "Tarif d'Expédition",
      "POS_OPERATOR_NAME": "Nom de l'Opérateur POS",
      "CARD_EXPIRY_DATE": "Date d'Expiration de la Carte",
      "PREVIOUS_BALANCE": "Solde Précédent",
      "REQUESTED_DATE_TIME": "Date/Heure de la Demande",
      "SENDER_NAME": "Nom de l'Expéditeur",
      "COLLECTOR_NAME": "Nom du Collecteur",
      "WEIGHT": "Poids",
      "CARD_TYPE": "Type de Carte",
      "ADULT": "Adulte",
      "CHILD": "Enfant",
      "ADULT_CHILD": "Adulte / Enfant",
      "PERSONALIZED": "Personnalisé",
      "TICKET_FINE_CARD_ID": "Billet / Amende / ID de la carte",
      "TICKET_CARD_ID": "Carte d'identité / Billet",
      "RIDERSHIP_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Rapport de Fréquentation - {{date}} - {{time}}",
      "CUSTOMERS_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Rapport Clients - {{date}} - {{time}}",
      "SALES_AGENT_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Rapport des Agents de Point de Ventes - {{date}} - {{time}}",
      "FINANCES_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Rapport Financier - {{date}} - {{time}}",
      "BUS_CONDUCTOR_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Rapport du Receveur - {{date}} - {{time}}",
      "LINE_COMPARISON_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Rapport de Comparaison des Lignes - {{date}} - {{time}}",
      "POS_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Rapport des Opérateurs POS - {{date}} - {{time}}",
      "SHIPMENT_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Rapport d'Expédition - {{date}} - {{time}}",
      "VEHICLE_DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Rapport des Véhicules - {{date}} - {{time}}"
    },
    "PASSES_FARES": {
      "TITLE": "Ajouter un Abonnement",
      "EDIT_TITLE": "Détails de l'abonnement",
      "FIELDS": {
        "LOGO": "Logo",
        "PASS_NAME": "Nom de l'Abonnement",
        "PASS_CATEGORY": "Catégorie de l'abonnement",
        "SELECT_PASS_CATEGORY": "Sélectionnez la catégorie de l'abonnement",
        "OPTIONAL_LOGO": "Logo optionnel",
        "UPLOAD_LOGO": "Télécharger le logo",
        "PRICE": "Prix",
        "STATUS": "Statut",
        "DISCOUNT": "Remise",
        "BEGIN_DATE": "Date de début",
        "END_DATE": "Date de fin",
        "DISCOUNT_FIELD": "Champ d'annulation",
        "DISCOUNT_PERCENTAGE": "% de Réduction",
        "DISCOUNT_AMOUNT": "Montant de la remise en %",
        "ENTER_DISCOUNT_AMOUNT": "Saisir le montant de la remise en %",
        "FREE_PASS_DETAILS": "Détails de l'abonnement gratuit",
        "SPECIAL_PASS_DETAILS": "Détails de l'Abonnement Spécial",
        "NEW_PASS_DETAILS": "Ajouter les Détails de l'Abonnement"
      }
    },
    "INTERCITY_FARES": {
      "TITLE": "Ajouter des tarifs",
      "EDIT_TITLE": "Détails des tarifs interurbains",
      "LUGGAGE": "Bagages",
      "PRICE_PER_KILO": "Prix par kilo supplémentaire",
      "FARES": "Tarifs",
      "PASSENGER": "Passager",
      "SMALL_ENVELOPE": "Petite Enveloppe",
      "LARGE_ENVELOPE": "Grande Enveloppe",
      "PARCEL": "Colis",
      "LOAD_STOPS_VARIATION": "Variation des Arrêts de Chargement",
      "LINE_OUT": "Ligne de Départ",
      "LINE_IN": "Ligne d'Arrivée",
      "FARE_NAME": "Nom du Tarif",
      "FIELDS": {
        "VALID_FROM": "Valable à partir de",
        "SAME_SUB_ZONE": "Même sous-zone"
      }
    },
    "TRANSACTION_SETTLEMENT": {
      "TITLE": "Règlement des Transactions",
      "TICKET_IDS": "Numéros de billets",
      "TICKET_ID": "Numéro de billet",
      "NOT_FOUND_TRANSACTIONS": "Le service n'a aucune transaction.",
      "NOT_FOUND": "L'enregistrement n'existe pas !",
      "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Règlement de Transaction - {{date}} - {{time}}",
      "TAB_COMMERCIAL_AGENT": {
        "TITLE": "Agent de ventes commerciales",
        "NAME": "Nom",
        "DEVICE_ID": "ID de l'Appareil",
        "START_TIME": "Heure de Début",
        "END_TIME": "Heure de Fin",
        "CUMULATIVE_AMOUNT": "Montant Cumulé",
        "PAID_AMOUNT": "Montant Payé",
        "SETTLED_BY": "Réglé Par"
      },
      "TAB_SALES_AGENT": {
        "TITLE": "Agent de point de ventes"
      },
      "TAB_ATTENDANT": {
        "TITLE": "Receveur"
      },
      "DETAILS": {
        "TITLE": "Détails du Règlement des Transactions",
        "USER_NAME": "Nom d'Utilisateur",
        "USER_ROLE": "Rôle de l'utilisateur",
        "DEVICE_ID": "ID de l'Appareil",
        "SHIFT": "Service",
        "TOTAL_COLLECTED_AMOUNT": "Montant Total Collecté",
        "TAB_CARDS": "Cartes",
        "DATE": "DATE",
        "TIME": "HEURE",
        "TOTAL": "TOTAL",
        "FEE": "FRAIS",
        "DATE_AND_TIME": "Date et Heure",
        "PASS_PRICE": "PRIX DE L'ABONNEMENT",
        "PASS_NAME": "Nom de l'Abonnement (Catégorie)",
        "CARD_NUMBER": "Numéro de Carte",
        "CARD_PRICE": "Prix de la Carte",
        "TOP_UP": "Recharge",
        "PASSES_TAB": "Abonnements",
        "STOP_FROM": "Arrêt De",
        "STOP_TO": "Arrêt À",
        "CURRENT_BALANCE": "Solde Actuel",
        "REMAINING_BALANCE": "Solde Restant",
        "TOP_UP_AMOUNT": "Montant de la Recharge",
        "SETTLED_BY": "Réglé Par",
        "PRICE_PER_SEAT": "Prix Par Siège",
        "PRICE_PER_SEAT_ADULT": "Prix Par Siège (ADULTE)",
        "PRICE_PER_SEAT_CHILD": "Prix Par Siège (ENFANT)",
        "TOTAL_COLLECTED_ADULTS": "Total Collecté d'Adultes",
        "TOTAL_COLLECTED_CHILDREN": "Total Collecté d'Enfants",
        "TOTAL_COLLECTED_CASH": "Total Collecté en Espèces",
        "TOTAL_COLLECTED_CARD": "Total Collecté par Carte",
        "NUMBER_OF_SEAT": "Nombre de Sièges",
        "TOTAL_PRICE": "Prix Total",
        "NUMBER_OF_ADULTS": "Nombre d'Adultes",
        "NUMBER_OF_CHILDREN": "Nombre d'Enfants",
        "TAB_TOP_UP": "Recharge",
        "TAB_TICKETS": "Billets",
        "TICKET_TYPE": "Type de Billet",
        "LINE_NAME": "NOM DE LA LIGNE",
        "TAB_FINE": "Amende",
        "AMOUNT": "MONTANT",
        "FINE_REFERENCE": "Référence de l'Amende",
        "TAB_SHIPMENT_LUGGAGE": "Expédition / Bagages",
        "QUANTITY": "Quantité",
        "UNIT_PRICE": "Prix Unitaire",
        "EMPLOYEE_ID": "ID de l'Employé",
        "EMPLOYEE_NAME": "Nom de l'Employé",
        "TOTAL_REVENUE": "Revenu Total",
        "CARD_REVENUE": "Revenu par Carte",
        "CARD_AMOUNT": "Montant de la carte",
        "CASH_AMOUNT": "Montant en espèces",
        "TOTAL_CASH_REVENUE": "Revenu Total en Espèces",
        "CHILD_REVENUE": "Revenu des Enfants",
        "ADULT_REVENUE": "Revenu des Adultes",
        "SHIFT_DATE_TIME": "Date et Heure du Service",
        "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Receveurs - {{date}} - {{time}}"
      }
    },
    "PATTERNS": {
      "TITLE": "Détails du modèle",
      "FIELDS": {
        "NAME": "Nom du Modèle",
        "WEEKDAY_EVENING": "Soir de semaine",
        "TYPE": "Type de modèle ",
        "SELECT_PATTERN_TYPE": "Sélectionner le type du modèle",
        "DESCRIPTION": "Description ",
        "OCCURRENCE": "Occurrence",
        "SELECT_OCCURRENCE": "Sélectionner l'occurrence",
        "ALL_DAY": "Toute la journée",
        "ONE_TIME": "Une fois",
        "REPEAT_WEEKDAYS": "Répéter les jours de la semaine",
        "REPEAT_WEEKENDS": "Répéter les week-ends",
        "DAILY": "Tous les jours",
        "CUSTOM_DATE": "Date personnalisée"
      }
    },
    "ZONES": {
      "TITLE": "Détails de la zone",
      "PARENT_ZONE": "Zone Principale",
      "SUB_ZONE": "Sous-zone",
      "SUB_ZONES": "Sous-zones",
      "NUMBER_SUB_ZONES": "#Sous-zones",
      "NEW_SUB_ZONE": "Ajouter une Sous-Zone",
      "REMOVING_LAST_POINT": "Supprimer le dernier point",
      "REMOVING_ALL_POINTS": "Supprimer tous les points",
      "SUB_ZONE_NAME": "Nom de la sous-zone",
      "SUB_ZONE_DESCRIPTION": "Description de la sous-zone",
      "ADD_ZONE": "Ajouter une zone",
      "ADD_SUB_ZONE": "Ajouter une sous-zone",
      "SUB_ZONE_DETAILS": "Détails de la sous-zone",
      "ZONE_DETAILS": "Détails de la zone",
      "ZONE_NAME": "Nom de la zone"
    },
    "TIMETABLE": {
      "TITLE": "Horaire",
      "TIMETABLE_NAME": "Nom de l'horaire",
      "TAB_TIMELINE": "Ligne du temps",
      "TAB_TIMETABLE": "Horaire",
      "FILTER_BY": "Filtrer par",
      "BLOCK": "Position",
      "NEW": "Nouveau",
      "STOPS": "Arrêts",
      "EMPTY_TRIP_IS_SHORTER": "Le voyage à vide dure moins de",
      "TIME_EXCEEDED_BY": "Temps dépassé de",
      "REMOVE": "Supprimer",
      "STATISTICAL_INFO": "Données statistiques",
      "NUMBER_OF_BLOCK": "Nombre de Positions",
      "TOTAL_TRIPS_MADE": "Total de voyages",
      "FIRST_DEPARTURE_TIME": "Heure de départ initiale",
      "DUPLICATE_TIMETABLES": "Horaires en double",
      "TOTAL_KMS_TRAVELED": "Nombre Total de KMs Parcourus",
      "LAST_ARRIVAL_TIME": "Dernière Heure d'Arrivée",
      "TOTAL_TIME_ON_ROAD": "Temps Total sur la Route",
      "TOTAL_REST_TIME": "Temps Total de Repos",
      "PREP_TIME_GARAGE": "Temps de Préparation/Garage",
      "OUT_TRIP": "Voyage Sortant",
      "IN_TRIP": "Voyage Entrant",
      "REST_TIME": "Temps de Repos",
      "PREP_CLOSURE_TIME": "Temps de préparation/fermeture",
      "EMPTY_TRIP": "Voyage Vide",
      "LEGEND": "Légende",
      "PUBLISHED": "Publié",
      "DRAFT": "Définir comme brouillon",
      "TIMELINE": {
        "TITLE": "Détails de la ligne du temps",
        "FIELDS": {
          "NAME": "Nom de l'horaire (généré automatiquement)",
          "LINE": "Ligne",
          "LINE_DATE": "Date de la Ligne"
        }
      },
      "FIELDS": {
        "DATE_FROM": "Date du",
        "DATE_TO": "Date à"
      },
      "TABLE": {
        "TITLE": "Voyages en attente",
        "TRIP_NAME": "NOM DU VOYAGE",
        "START_TIME": "HEURE DE DÉBUT",
        "EST_END_TIME": "Heure de Fin Estimée",
        "RUNTIME": "DURÉE D'EXÉCUTION",
        "TYPE": "TYPE",
        "DIRECTION": "DIRECTION"
      },
      "BUTTONS": {
        "LOAD_TRIPS": "Charger les Voyages",
        "RELOAD_TRIPS": "Recharger les Voyages",
        "ADD_TO_BLOCK": "Ajouter une sélection à la position ",
        "MOVE_TO_BLOCK": "Déplacer la sélection vers la position "
      }
    },
    "SCHEDULE": {
      "SERVICES": {
        "SERVICE_CREATION_NAME": "Nom de création de service",
        "SERVICES": "#Services",
        "BLOCKS": "#POSITIONS",
        "LINE": "Ligne",
        "DATE_FROM": "Date de Début",
        "DATE_TO": "Date de Fin",
        "STATUS": "STATUT",
        "TITLE": "Création de Service",
        "DIVISION_METHOD": "Méthode de Division",
        "ASSIGN_TO_DIVISION": "Attribuer à la méthode de division",
        "SERVICE": "Service",
        "DURATION": "Durée",
        "FIELDS": {
          "LINE": "Ligne",
          "TYPE": "Type de ligne",
          "DATE_FROM": "Date de Début",
          "DATE_TO": "Date de Fin",
          "SELECT_LINE": "Sélectionner la Ligne",
          "PREP_TIME": "Temps de Préparation",
          "HANDOVER_TIME": "Temps de Remise"
        },
        "SERVICE_CONFIG": {
          "PARENT_TIMETABLE": "HORAIRE PRINCIPAL",
          "BLOCK_NAME": "NOM DE POSITION",
          "DATE": "DATE",
          "ASSIGNED_TO": "ATTRIBUÉ À",
          "TAB_1": "Récupération des Positions",
          "TAB_2": "Création de Services"
        },
        "TABLE": {
          "DAY": "Jour",
          "LINE_NAME": "Nom de la Ligne",
          "TRIPS": "Voyages",
          "BLOCKS": "Positions"
        },
        "FETCH": "Récupérer les horaires"
      },
      "EMPLOYEE_ROSTER": {
        "TITLE": "Planification des Employés",
        "CALENDAR": {
          "TITLE": "Équipes"
        },
        "PLAN_NAME": "NOM DU PLAN",
        "LAST_PLANNED_DATE": "PLANIFIÉ À PARTIR DE",
        "PLANNED_UNTIL": "PLANIFIÉ JUSQU'À",
        "SERVICES": "#SERVICES",
        "ROWS": "#RANGÉS",
        "STATUS": "STATUT",
        "MODAL": {
          "TITLE": "Attribuer des Employés"
        },
        "FIELDS": {
          "CONDUCTEUR": "Chauffeur",
          "RECEVEUR": "Receveur"
        }
      },
      "EMPLOYEE_ENGAGEMENT": {
        "TITLE": "Employés",
        "EXPORT_TITLE": "Are you sure you want to export?",
        "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Liste des Employés - {{date}} - {{time}}"
      },
      "PLANS": {
        "TITLE": "Détails du Plan",
        "NEW_PLANS": "Ajouter un Plan",
        "EDIT_PLANS": "Modifier le Plan",
        "PLANS_NAME": "Nom du Plan",
        "LAST_PLANNED_DATE": "Dernière Date Planifiée +1",
        "PLANNED_FROM": "Planifié à partir de",
        "PLANNED_UNTIL": "Planifié Jusqu'à",
        "TIME_FROM": "De l'heure",
        "TIME_TO": "À l'heure",
        "UNASSIGN": "Désassigner",
        "UNASSIGN_SERVICES": "Services non assignés",
        "STANDBY_TIME_TITLE": "Créer un Temps de Garde",
        "SERVICES": "#Services",
        "ASSIGN_SELECTED_SERVICES": "Assigner le service sélectionné à",
        "REASSIGN_SELECTED_SERVICES": "Réassigner le service sélectionné à",
        "ROWS": "#Rangés",
        "DESCRIPTION": "Description",
        "ENTER_PLANS_NAME": "Entrez le Nom du Plan",
        "PLANS_DESCRIPTION": "Description du Plan",
        "FINE_AMOUNT": "Montant de l'Amende",
        "ENTER_FINE_AMOUNT": "Entrez le Montant de l'Amende",
        "DATE_RANGE": "Plage de Dates",
        "ROW_PLANNING": "Planification par Rangée",
        "ROSTER_PLANNING_DETAILS": "Détails de la Planification des Services",
        "PLAN_NAME_AUTO_GENERATED": "Nom du Plan (Généré Automatiquement)",
        "LINE_TYPE": "Type de Ligne",
        "LAST_PLAN_DATE": "Dernière Date du Plan +1",
        "PLANNING_UNTIL": "Planification Jusqu'à",
        "DAY": "JOUR",
        "ROWS_TITLE": "Rangées",
        "ROW_TITLE": "Rangée",
        "DURATION": "DURÉE",
        "TIME_INTERVAL": "INTERVALLE DE TEMPS",
        "LINE_NAME": "NOM DE LA LIGNE",
        "BUTTONS": {
          "ASSIGN_TO_DIVISION": "Attribuer à la Méthode de Division",
          "LOAD_SERVICES": "Charger les Services",
          "SAVE": "Enregistrer et Afficher le Plan"
        }
      }
    },
    "TRIPS": {
      "TITLE": "Détails du Voyage",
      "LINE_DISPLAY": "Affichage de la ligne",
      "FIELDS": {
        "NAME": "Nom du Voyage ",
        "EST_DURATION": "Est. Durée",
        "DATE_AND_TIME": "Date et heure",
        "TRIP_DIRECTION": "Direction",
        "TYPE": "Type de ligne",
        "SELECT_PATTERN_TYPE": "Sélectionner le type de voyage",
        "DESCRIPTION": "Description ",
        "DIRECTION": "Direction du Voyage",
        "TRIP_DATE": "Date du voyage",
        "TRIP_TIME": "Heure du voyage",
        "SELECT_DIRECTION": "Sélectionner la direction du voyage",
        "GENERATED_TRIP_NAME": "Nom du voyage généré",
        "ESTIMATED_TRIP_RUNTIMES": "Durée estimée du voyage",
        "MINUTES": "Minutes",
        "LINE": "Ligne",
        "SELECT_LINE": "Sélectionner une ligne",
        "DUPLICATE": "Dupliquer",
        "MAP_VIEW": "Vue de la carte",
        "VIEW_ON_MAP": "Voir sur la carte",
        "DATE_FROM": "Date du",
        "DATE_TO": "Date à",
        "TRIP_TYPE": "Type de Voyage",
        "START_TIME": "Heure de Départ",
        "DRIVER_START_TIME": "Driving Start Time",
        "DRIVER_END_TIME": "Driving End Time",
        "END_TIME": "Heure de Fin",
        "INTERVAL_TIME": "Temps d'Intervalle",
        "CONFIRM": "Confirmer"
      },
      "CHOOSE_DEPARTURE": "Choisir des Heures de Départ Personnalisées",
      "TIMES_DELPER": "Assistant des Heures de Départ",
      "TIMES_HELPER_TOOLTIP": "En sélectionnant une heure de début, une heure de fin et un intervalle, vous pouvez générer des heures de départ régulièrement réparties. Des ajustements peuvent être apportés ultérieurement si nécessaire.",
      "SHOW": "Afficher",
      "DEPARTURES": "Départs",
      "ADD_DEPARTURE": "Ajouter l'heure de Départ",
      "CONFIRM_SUMMARY": "Confirmer le Récapitulatif",
      "GENERATE": "Générer",
      "COMMENCING": "Commence à",
      "CONCLUDING": "et se Termine à",
      "DAYS": "les Jours Sélectionnés",
      "TIMEFRAME": "dans la Période de",
      "TO": "à",
      "CREATION": "Attendre la Création de",
      "ACCORDINGLY": "en conséquence",
      "PLEASE_SELECT_WEEK_DAYS": "Veuillez sélectionner au moins un jour de la semaine"
    },
    "TRANSACTION": {
      "NAME": "Nom de la transaction",
      "TYPE": "Type de transaction",
      "DATE_RANG": "Plage de dates",
      "TIME_RANG": "Période de temps",
      "REPEAT": "Répétition"
    },
    "FRAUD_TYPES": {
      "TITLE": "Détails de l'Infraction",
      "NEW_FRAUD": "Ajouter une Infraction ",
      "EDIT_FRAUD": "Modifier la Violation",
      "FRAUD_NAME": "Nom de la Violation",
      "DESCRIPTION": "Description",
      "ENTER_FRAUD_NAME": "Entrer le Nom de la Violation",
      "FRAUD_DESCRIPTION": "Description de la Violation",
      "FINE_AMOUNT": "Montant de la Violation",
      "ENTER_FINE_AMOUNT": "Entrer le Montant de l'Amende"
    },
    "HOLIDAYS": {
      "TITLE": "Jours Fériés",
      "CREATE_HOLIDAY": "Ajouter un Jour Férié",
      "EDIT_HOLIDAY": "Modifier le Jour Férié",
      "HOLIDAY_NAME": "Nom du Jour Férié",
      "STARTING_DATE": "Date de Début",
      "ENDING_DATE": "Date de Fin",
      "DDD_AVAILABILITY": "Disponibilité DDD",
      "URBAN_AVAILABILITY": "Disponibilité Urbaine",
      "INTERCITY_AVAILABILITY": "Disponibilité Interurbaine",
      "AVAILABILITY": "Disponibilité",
      "DDD": "DDD",
      "URBAN_TRIPS": "Voyages Urbains",
      "INTERCITY_TRIPS": "Voyages Interurbains"
    },
    "VEHICLE_DISPATCHING": {
      "TITLE": "Répartition des Véhicules",
      "TIME": "Temps",
      "LINE_NAME": "Nom de la Ligne",
      "BLOCK_NAME": "Nom de la Position",
      "TEAM": "Équipe",
      "NUMBER_OF_TRIPS": "#Voyages",
      "NUMBER_OF_SERVICES": "#Services",
      "DATE": "Date",
      "BUS": "Bus",
      "TRIPS": "Voyages",
      "CONDUCTOR": "Receveur",
      "CONDUCTOR_NAME": "Nom du Receveur",
      "ATTENDANT_NAME": "Nom du Receveur",
      "SELECT_CONDUCTOR": "Sélectionner le Receveur",
      "ATTENDANT": "Receveur",
      "SELECT_ATTENDANT": "Sélectionner le Receveur",
      "REPLACEMENT_ATTENDANT": "Receveur de Remplacement",
      "REPLACEMENT_DRIVER": "Chauffeur de Remplacement",
      "REPLACEMENT_SECONDARY_DRIVER": "Chauffeur Secondaire de Remplacement",
      "DRIVER": "Chauffeur",
      "SELECT_DRIVER": "Sélectionner le Chauffeur",
      "SECONDARY_DRIVER": "Chauffeur Secondaire",
      "SELECT_SECONDARY_DRIVER": "Sélectionner le Chauffeur Secondaire",
      "ASSIGNMENTS": "Affectations",
      "REASSIGN": "Réaffecter",
      "START_TIME": "Heure de Début",
      "END_TIME": "Heure de Fin",
      "ASSIGN_BUS": "Affecter un Bus",
      "MAIN_BUS": "Bus Principal",
      "SELECT_MAIN_BUS": "Sélectionner le Bus Principal",
      "REPLACEMENT_BUS": "Bus de Remplacement",
      "SELECT_REPLACEMENT_BUS": "Sélectionner le Bus de Remplacement"
    },
    "CALENDAR": {
      "TODAY": "Aujourd'hui",
      "HOLIDAY": "Jour férié",
      "DAYS_OFF": "Jours de congé",
      "NO_ERRORS": "Aucune Erreur",
      "ERRORS": "Erreurs",
      "SERVICE_VIOLATION": "Service de Violation",
      "DAY_OF_VIOLATION": "Violation de congé",
      "HOURS_LESS_THEN_35": "Moins de 35 heures",
      "HOURS_MORE_THAN_48": "Plus de 48 heures",
      "MORE_THEN_ONE_DAY_OF_VIOLATION": "Plus d'un Jour de Repos",
      "NOT_ENOUGH_REST": "Pas Assez de Temps de Repos",
      "ZERO_DAY_OF_VIOLATION": "0 Jour de Repos",
      "DELETE_ROW": "Êtes-vous sûr de vouloir supprimer la ligne ?"
    },
    "NFC_CARDS": {
      "NFC_CARDS": "Cartes NFC",
      "CARD_DETAILS": "Détails de la Carte",
      "SERIAL_NUMBER_SIGN": "Numéro de Série",
      "SERIAL_NUMBER": "Numéro de Série",
      "TICKET_SERIAL_NUMBER": "Numéro de Série du Billet",
      "TRANSACTION_REFERENCE": "Référence de la Transaction",
      "ISSUED_DATE": "Date de Délivrance",
      "EXPIRY_DATE": "Date d'Expiration",
      "PASS_NAME": "Nom de l'Abonnement",
      "CARDHOLDER_NAME": "Nom du Titulaire de la Carte",
      "CUSTOMER_TYPE": "Type de Client",
      "CUSTOMER_STATUS": "Statut du Client",
      "CUSTOMER_NAME": "Nom du Client",
      "WALLET_BALANCE": "Solde du Portefeuille",
      "TRANSACTIONS_DONE": "Transactions Effectuées",
      "TRANSACTION_TYPE": "Type de Transaction",
      "BLOCKED_AMOUNT": "Montant Bloqué",
      "DATE": "Date",
      "TIME": "Heure",
      "AMOUNT": "Montant",
      "BALANCE": "Solde",
      "TOP_UP": "Recharge",
      "TIME_BASED_PASS": "Abonnement Basé sur le Temps",
      "FREE_PASS": "Abonnement Gratuit",
      "SHIPMENT": "Expédition",
      "TICKET": "Billet",
      "PASSES": "Abonnements",
      "PASS": "Abonnement",
      "SHIFT_CASH": "Espèces du Service",
      "URBAN": "Urbain",
      "INTERCITY": "Interurbain",
      "TOPUP": "Recharge",
      "FINE": "Amende",
      "CARD": "Carte",
      "CARD_ID": "ID de la Carte",
      "EMPLOYEE": "Employé",
      "STUDENT": "Étudiant",
      "REGULAR": "Régulier",
      "SPECIAL": "Spécial",
      "MILITARY": "Militaire",
      "NORMAL": "Normal",
      "RETIRED": "Retraité",
      "DDDEMPLOYEE": "Employé DDD",
      "FREELANCER": "Travailleur indépendant",
      "DESCRIPTION": "Description",
      "FEES": "Frais",
      "CURRENT_BALANCE": "Solde Actuel",
      "IMPORT_FILE_NAME": "modèle-importation-cartes-nfc",
      "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Cartes NFC - {{date}} - {{time}}",
      "NO_TAP_OUT": "Pas de Tap Out",
      "URBAN-TICKET": "Billet Urbain",
      "URBAN-UNBLOCK": "Déblocage Urbain",
      "URBAN-CARD": "Carte Urbaine",
      "INTERCITY-PASSENGER": "Passager Interurbain",
      "INTERCITY-TICKET": "Billet Interurbain",
      "UNBLOCK": "Débloquer"
    },
    "DEVICES": {
      "DEVICES": "Dispositifs",
      "NEW_DEVICES": "Ajouter un Dispositif",
      "EDIT_DEVICES": "Modifier le Dispositif",
      "DEVICE_ID": "Identifiant du Dispositif",
      "ENTER_DEVICE_ID": "Entrer l'Identifiant du Dispositif",
      "DEVICE_TYPE": "Type de Dispositif",
      "SELECT_DEVICE_TYPE": "Sélectionner le Type de Dispositif",
      "TYPE": "Type",
      "LOCATION": "Emplacement",
      "INSERT_LOCATION": "Insérer l'Emplacement",
      "ENTER_LOCATION": "Entrer l'Emplacement",
      "DEVICE_LOCATION": "Emplacement du Dispositif",
      "SELECT_DEVICE_LOCATION": "Sélectionner l'Emplacement du Dispositif",
      "SELECT_USER": "Sélectionner l'Utilisateur",
      "SELECT_VEHICLE": "Sélectionner le Véhicule",
      "DESCRIPTION": "Description",
      "MORE_DESCRIPTION": "Plus d'Informations sur le Dispositif"
    },
    "VEHICLES": {
      "LISTING": "Liste des Véhicules",
      "ADD_VEHICLE": "Ajouter un Véhicule",
      "EDIT_VEHICLE": "Modifier le Véhicule",
      "VEHICLE_DETAILS": "Détails du Véhicule",
      "VEHICLE_NAME": "Nom du Véhicule",
      "ENTER_VEHICLE_NAME": "Entrer le Nom du Véhicule",
      "LICENSE_NUMBER": "Numéro de Licence",
      "MANUFACTURER": "Fabricant",
      "SELECT_MANUFACTURER": "Sélectionner le Fabricant",
      "ENTER_MANUFACTURER": "Entrer le Nom du Fabricant",
      "MODEL_YEAR": "Année du Modèle",
      "TYPE": "Type",
      "SELECT_TYPE": "Sélectionner le Type",
      "CAPACITY": "Capacité",
      "ENTER_CAPACITY": "Entrer la Capacité",
      "COLOR": "Couleur",
      "SELECT_COLOR": "Sélectionner la Couleur",
      "CREATION_DATE": "Date de Création",
      "VEHICLE_TYPE": "Type du Véhicule",
      "VEHICLE_INTERNAL_ID": "ID Interne du Véhicule",
      "ENTER_VEHICLE_INTERNAL_ID": "Entrer l'ID Interne du Véhicule",
      "PLATE_NUMBER": "Numéro de Plaque",
      "ENTER_PLATE": "Entrer le Numéro de Licence",
      "VIN_NUMBER": "Numéro VIN",
      "ENTER_VIN_NUMBER": "Entrer le numéro VIN",
      "MODELYEAR": "Modèle/Année",
      "ENTER_MODELYEAR": "Entrer le modèle/année"
    },
    "NOTIFICATIONS": {
      "HEADING": "Notifications",
      "CREATE": "Ajouter une Notification",
      "STATISTICS": "Statistiques des Notifications",
      "TEMPLATE": "Modèles de notifications",
      "DETAILS": "Détails des Notifications",
      "AUDIENCE": "Audience des Notifications",
      "TITLE": "Titre",
      "ENTER_TITLE": "Entrez le Titre",
      "DESCRIPTION": "Description",
      "DATE_AND_TIME": "Date et Heure",
      "USER": "Utilisateur",
      "INSERT": "Insérer",
      "TARGET_AUDIENCE": "Public Cible",
      "SELECT_TARGET_AUDIENCE": "Sélectionner le Public Cible",
      "SELECT_DATA": "Sélectionner les Données",
      "SEND_NOW": "Envoyer Maintenant",
      "SCHEDULE_FOR_LATER": "Programmer pour Plus Tard",
      "START_DATE": "Date de Début",
      "TIME": "Heure",
      "ALL_DRIVERS": "Tous les Chauffeurs",
      "ALL_ATTENDANTS": "Tous les Receveurs",
      "ALL_INSPECTORS": "Tous les Contrôleurs",
      "ALL_SALES_AGENT": "Tous les Agents de point de ventes",
      "ALL_COMMERCIAL_SALES_AGENT": "Tous les Agents de ventes commerciales",
      "SELECTED_LINES": "Lignes ciblées",
      "SELECTED_USERS": "Utilisateurs ciblés",
      "GENERAL_BROADCAST": "Diffusion générale",
      "TEMPLATES": [
        {
          "TITLE": "Alerte de Maintenance",
          "DESCRIPTION": "Veuillez noter qu'une maintenance programmée aura lieu demain [from - to]. Vos services pourraient être temporairement indisponibles."
        },
        {
          "TITLE": "Mise à Jour des Politiques",
          "DESCRIPTION": "Veuillez noter que nos politiques d'entreprise ont été mises à jour. Consultez les nouvelles politiques sur le portail pour rester informé."
        },
        {
          "TITLE": "Session de Formation",
          "DESCRIPTION": "Une session de formation obligatoire sur le nouveau système de billetterie se tiendra le [Date & Time] à [Location]."
        },
        {
          "TITLE": "Interruption de Service",
          "DESCRIPTION": "Veuillez vous assurer que tous les protocoles de sécurité sont suivis pendant vos services. Votre sécurité et celle de nos passagers est notre priorité absolue."
        },
        {
          "TITLE": "Nouveaux Abonnements",
          "DESCRIPTION": "De nouveaux abonnements sont disponibles pour les passagers pour [selected months]. Informez vos clients de consulter le site web pour plus de détails."
        },
        {
          "TITLE": "Calendrier des jours fériés",
          "DESCRIPTION": "Veuillez prendre connaissance des changements de calendrier des jours fériés. Consultez le calendrier mis à jour sur le portail et planifiez en conséquence."
        },
        {
          "TITLE": "Mise à Jour du Système",
          "DESCRIPTION": "Une mise à jour du système sera effectuée ce soir à minuit. Veuillez enregistrer votre travail et vous déconnecter avant cette heure pour éviter toute perte de données."
        },
        {
          "TITLE": "Rappel de Réunion",
          "DESCRIPTION": "Un rappel pour la réunion prévue avec l'équipe des opérations [Date & Time]. Votre présence est requise."
        },
        {
          "TITLE": "Barrage Routier en Avant",
          "DESCRIPTION": "Veuillez noter qu'il y a un barrage routier sur [Route Name] en raison de travaux de construction. Prévoyez des retards et planifiez votre voyage en conséquence."
        },
        {
          "TITLE": "Barrage Routier d'Urgence",
          "DESCRIPTION": "En raison d'un accident, il y a un barrage routier d'urgence dans [Area Name]. La route devrait être dégagée dans environ [Number of hours]."
        },
        {
          "TITLE": "Fermeture Temporaire",
          "DESCRIPTION": "Veuillez noter que [Area Name] est temporairement fermé pour un événement public. Suivez les panneaux de déviation affichés pour des itinéraires alternatifs."
        },
        {
          "TITLE": "Barrage Routier pour Travaux",
          "DESCRIPTION": "Il y a un barrage routier prévu dans [Area Name] pour des réparations de route. Le blocage sera en place [From - To]."
        },
        {
          "TITLE": "Barrage Routier par la Police",
          "DESCRIPTION": "Une enquête policière en cours cause un barrage routier dans [Area Name]. Prévoyez des retards et des déviations de circulation."
        },
        {
          "TITLE": "Inspections aux Heures de Pointe",
          "DESCRIPTION": "Concentrez-vous sur la validation des billets et des cartes pendant les heures de pointe aujourd'hui. Un trafic important est attendu sur [Route Names]."
        },
        {
          "TITLE": "Augmentation des Inspections Nécessaires",
          "DESCRIPTION": "En raison d'incidents récents de fraude, des inspections accrues des billets et des cartes sont nécessaires sur toutes les lignes du soir. Assurez-vous de mener des vérifications approfondies."
        },
        {
          "TITLE": "Notification de Réajustement des Prix",
          "DESCRIPTION": "Veuillez noter qu'il y aura un réajustement des prix de nos services [starting next quarter]. Informez vos clients et adressez toutes leurs préoccupations."
        },
        {
          "TITLE": "Indisponibilité Temporaire de la Carte",
          "DESCRIPTION": "En raison d'une mise à niveau du système, l'émission des cartes sera temporairement indisponible le Friday from 2 PM to 6 PM]. Planifiez vos activités en conséquence."
        },
        {
          "TITLE": "Restriction du Numéro de Série de la Carte",
          "DESCRIPTION": "Ceci est pour vous informer que le numéro de série de la carte suivant [insert card serial number] est interdit d'utilisation avec effet immédiat. Veuillez vous assurer que cette carte n'est pas émise aux clients ni utilisée pour des transactions."
        },
        {
          "TITLE": "Annonce Importante",
          "DESCRIPTION": "Nous avons une annonce importante : [Announcement Details] Veuillez vous assurer que cette information est communiquée à toutes les parties concernées et respectée comme requis."
        },
        {
          "TITLE": "Confirmation du Nombre de Passagers",
          "DESCRIPTION": "Confirmez que le nombre de passagers dans [Bus 9] correspond à la collecte des tarifs. Signalez immédiatement toute divergence."
        }
      ]
    },
    "PASSENGER_NOTIFICATIONS": {
      "HEADING": "Notifications aux Passagers",
      "CREATE": "Ajouter une Notification aux Passagers",
      "STATISTICS": "Statistiques des Notifications aux Passagers",
      "TEMPLATE": "Modèles de Notifications aux Passagers",
      "DETAILS": "Détails des Notifications aux Passagers",
      "AUDIENCE": "Audience des Notifications",
      "TITLE": "Titre",
      "ENTER_TITLE": "Entrer un Titre",
      "DESCRIPTION": "Description",
      "DATE_AND_TIME": "Date et Heure",
      "USER": "Utilisateur",
      "INSERT": "Insérer",
      "TARGET_AUDIENCE": "Public Cible",
      "SELECT_TARGET_AUDIENCE": "Sélectionner le Public Cible",
      "SELECT_DATA": "Sélectionner les Données",
      "SEND_NOW": "Envoyer Maintenant",
      "SCHEDULE_FOR_LATER": "Planifier pour Plus Tard",
      "START_DATE": "Date de Début",
      "TIME": "Heure",
      "ALL_DRIVERS": "Tous les Chauffeurs",
      "ALL_ATTENDANTS": "Tous les Receveurs",
      "ALL_INSPECTORS": "Tous les Contrôleurs",
      "ALL_SALES_AGENT": "Tous les Agents de Point de Ventes",
      "ALL_COMMERCIAL_SALES_AGENT": "Tous les Agents de Vente Commerciaux",
      "SELECTED_LINES": "Lignes Ciblées",
      "SELECTED_USERS": "Utilisateurs Ciblés",
      "GENERAL_BROADCAST": "Diffusion générale",
      "TEMPLATES": [
        {
          "TITLE": "Alerte de Maintenance",
          "DESCRIPTION": "Veuillez noter qu'une maintenance programmée aura lieu demain [from - to]. Vos services pourraient être temporairement indisponibles."
        },
        {
          "TITLE": "Mise à Jour des Politiques",
          "DESCRIPTION": "Veuillez noter que nos politiques d'entreprise ont été mises à jour. Consultez les nouvelles politiques sur le portail pour rester informé."
        },
        {
          "TITLE": "Session de Formation",
          "DESCRIPTION": "Une session de formation obligatoire sur le nouveau système de billetterie se tiendra le [Date & Time] à [Location]."
        },
        {
          "TITLE": "Interruption de Service",
          "DESCRIPTION": "Veuillez vous assurer que tous les protocoles de sécurité sont suivis pendant vos services. Votre sécurité et celle de nos passagers est notre priorité absolue."
        },
        {
          "TITLE": "Nouveaux Abonnements",
          "DESCRIPTION": "De nouveaux abonnements sont disponibles pour les passagers pour [selected months]. Informez vos clients de consulter le site web pour plus de détails."
        },
        {
          "TITLE": "Calendrier des jours fériés",
          "DESCRIPTION": "Veuillez prendre connaissance des changements de calendrier des jours fériés. Consultez le calendrier mis à jour sur le portail et planifiez en conséquence."
        },
        {
          "TITLE": "Mise à Jour du Système",
          "DESCRIPTION": "Une mise à jour du système sera effectuée ce soir à minuit. Veuillez enregistrer votre travail et vous déconnecter avant cette heure pour éviter toute perte de données."
        },
        {
          "TITLE": "Rappel de Réunion",
          "DESCRIPTION": "Un rappel pour la réunion prévue avec l'équipe des opérations [Date & Time]. Votre présence est requise."
        },
        {
          "TITLE": "Barrage Routier en Avant",
          "DESCRIPTION": "Veuillez noter qu'il y a un barrage routier sur [Route Name] en raison de travaux de construction. Prévoyez des retards et planifiez votre voyage en conséquence."
        },
        {
          "TITLE": "Barrage Routier d'Urgence",
          "DESCRIPTION": "En raison d'un accident, il y a un barrage routier d'urgence dans [Area Name]. La route devrait être dégagée dans environ [Number of hours]."
        },
        {
          "TITLE": "Fermeture Temporaire",
          "DESCRIPTION": "Veuillez noter que [Area Name] est temporairement fermé pour un événement public. Suivez les panneaux de déviation affichés pour des itinéraires alternatifs."
        },
        {
          "TITLE": "Barrage Routier pour Travaux",
          "DESCRIPTION": "Il y a un barrage routier prévu dans [Area Name] pour des réparations de route. Le blocage sera en place [From - To]."
        },
        {
          "TITLE": "Barrage Routier par la Police",
          "DESCRIPTION": "Une enquête policière en cours cause un barrage routier dans [Area Name]. Prévoyez des retards et des déviations de circulation."
        },
        {
          "TITLE": "Inspections aux Heures de Pointe",
          "DESCRIPTION": "Concentrez-vous sur la validation des billets et des cartes pendant les heures de pointe aujourd'hui. Un trafic important est attendu sur [Route Names]."
        },
        {
          "TITLE": "Augmentation des Inspections Nécessaires",
          "DESCRIPTION": "En raison d'incidents récents de fraude, des inspections accrues des billets et des cartes sont nécessaires sur toutes les lignes du soir. Assurez-vous de mener des vérifications approfondies."
        },
        {
          "TITLE": "Notification de Réajustement des Prix",
          "DESCRIPTION": "Veuillez noter qu'il y aura un réajustement des prix de nos services [starting next quarter]. Informez vos clients et adressez toutes leurs préoccupations."
        },
        {
          "TITLE": "Indisponibilité Temporaire de la Carte",
          "DESCRIPTION": "En raison d'une mise à niveau du système, l'émission des cartes sera temporairement indisponible le Friday from 2 PM to 6 PM]. Planifiez vos activités en conséquence."
        },
        {
          "TITLE": "Restriction du Numéro de Série de la Carte",
          "DESCRIPTION": "Ceci est pour vous informer que le numéro de série de la carte suivant [insert card serial number] est interdit d'utilisation avec effet immédiat. Veuillez vous assurer que cette carte n'est pas émise aux clients ni utilisée pour des transactions."
        },
        {
          "TITLE": "Annonce Importante",
          "DESCRIPTION": "Nous avons une annonce importante : [Announcement Details] Veuillez vous assurer que cette information est communiquée à toutes les parties concernées et respectée comme requis."
        },
        {
          "TITLE": "Confirmation du Nombre de Passagers",
          "DESCRIPTION": "Confirmez que le nombre de passagers dans [Bus 9] correspond à la collecte des tarifs. Signalez immédiatement toute divergence."
        }
      ]
    },
    "COLLECTION_REVENUE": {
      "TITLE": "Revenu de la collecte",
      "CARDS": "Cartes",
      "TICKETS": "Billets",
      "TRANSACTION_ID": "ID de transaction",
      "NFC_CARD_NUMBER": "Numéro de carte NFC",
      "PASS_NAME": "Nom de l'Abonnement",
      "PASS_TYPE": "Type d'Abonnement",
      "PASS_VALIDITY": "Validité de l'Abonnement",
      "TRIP_NAME": "Nom du Voyage",
      "LINE_NAME": "Nom de la ligne",
      "BUS_NAME": "Nom du bus",
      "START_DATE_TIME": "Date et heure de début",
      "START_STOP": "Arrêt de départ",
      "END_DATE_TIME": "Date et heure de fin",
      "END_STOP": "Arrêt de fin",
      "BLOCKED_AMOUNT": "Montant bloqué",
      "TAPPED_OUT": "Décompté",
      "TRIP_FARE": "Tarif du Voyage",
      "FARE_COLLECTED": "Tarif Collecté",
      "DATE_TIME_OF_COLLECTION": "Date et heure de la collecte",
      "AMOUNT_COLLECTED": "Montant collecté",
      "PAPER_TICKET_NUMBER": "Numéro de billet papier"
    },
    "ERROR_PAGE": {
      "TITLE": "Page non trouvée",
      "TITLE_500": "Erreur interne du serveur",
      "TITLE_ACCESS_DENIED": "Accès refusé",
      "TITLE_SOMETHING_WENT_WRONG": "Quelque chose s'est mal passé",
      "TITLE_INTERNAL_SERVER": "Erreur interne du serveur",
      "DESCRIPTION": "Le lien que vous avez suivi peut être brisé ou la page a peut-être été supprimée.",
      "DESCRIPTION_500": "Une erreur s'est produite. Veuillez réessayer ultérieurement.",
      "DESCRIPTION_ACCESS_DENIED": "Vous n'avez pas la permission de voir cette page.",
      "DESCRIPTION_SOMETHING_WENT_WRONG": "Il semble que quelque chose se soit mal passé. Nous travaillons dessus.",
      "BACK_TO_HOMEPAGE": "Retour à la page d'accueil"
    }
  },
  "COMMON": {
    "TEXT": {
      "URBAN": "Urbain",
      "INTERCITY": "Interurbain",
      "ACTIVE": "Actif",
      "INACTIVE": "Inactif",
      "DRAFT": "Brouillon",
      "PUBLISHED": "Publié",
      "PENDING": "En Attente",
      "IN_PROGRESS": "En Cours",
      "SENT": "Envoyé",
      "SCHEDULED": "Programmé",
      "SETTLED": "Réglé",
      "UNSETTLED": "Non Réglé",
      "DAMAGED": "Endommagé",
      "OBSOLETE": "Obsolète",
      "INREPAIR": "En Réparation",
      "DONE": "Terminé",
      "ANONYMOUS": "Anonyme",
      "BLACKLISTED": "Mis sur Liste Noire",
      "NEW": "Nouveau",
      "ASSIGNED": "Assigné",
      "NOT_ASSIGNED": "Non Assigné",
      "READY": "Prêt",
      "CANCELLED": "Annulé",
      "SEARCH": "Recherche",
      "DATE": "Date ",
      "DEPARTURE_TIME": "Heure de départ",
      "START_DATE": "Date de début",
      "END_DATE": "Date de fin",
      "START_TIME": "Heure de début",
      "END_TIME": "Heure de fin",
      "SELECT_START_TIME": "Sélectionner l'heure de début",
      "SELECT_END_TIME": "Sélectionner l'heure de fin",
      "ADD_CUSTOM_TIME": "Ajouter une heure personnalisée 00:00",
      "ENDS_ON": "Se termine le",
      "SELECT_DATE": "Sélectionner la date",
      "NEVER_ENDS": "Ne se termine jamais",
      "OUT_OF_SERVICE": "Hors Service",
      "OUTOFSERVICE": "Hors Service",
      "MAINTENANCE": "Maintenance",
      "DURATION": "Durée",
      "MONDAY": "Lundi",
      "TUESDAY": "Mardi",
      "WEDNESDAY": "Mercredi",
      "THURSDAY": "Jeudi",
      "FRIDAY": "Vendredi",
      "SATURDAY": "Samedi",
      "SUNDAY": "Dimanche",
      "SECONDS": "Secondes",
      "VALID_HOURS": "Veuillez saisir des heures (0-23) et des minutes (0-59) valides.",
      "TIME_FORMAT": "Veuillez ajouter l'heure dans ce format : 00:00",
      "OCCURRENCE": "Occurrence",
      "NO_TRIPS_ADDED": "Aucun Voyage Ajouté",
      "STATUS": "Statut",
      "LINE": "Ligne",
      "DATE_FROM": "Date de",
      "DATE_TO": "Date à",
      "BASED_ON": "Basé sur",
      "LOCATIONS": "Emplacements",
      "COUNTRY": "Pays",
      "ZONES_AVAILABILITY": "Disponibilité des zones",
      "MODULE": "Module",
      "DIRECTION": "Direction",
      "PASS_CATEGORY": "Catégorie de l'Abonnement",
      "CITY": "Ville",
      "ROLE": "Rôle",
      "VEHICLE": "Véhicule",
      "CONDUCTEUR_RECEVEUR": "Chauffeur / Receveur",
      "TYPE_PLACEHOLDER": "Type",
      "FROM_DATE": "De la date",
      "TO_DATE": "À la date",
      "TYPE": "Type",
      "SELECTED_PATTERNS_OF_SAME_TYPE": "Les modèles sélectionnés du même type ne peuvent pas se chevaucher",
      "REPEATED_WEEKDAY": "on weekdays ",
      "REPEATED_WEEKEND": "on weekends ",
      "ONE_TIME": "Une fois ",
      "DAILY": "Tous les jours ",
      "CUSTOM": "date personnalisée ",
      "ON_MONDAY": "le Lundi ",
      "ON_TUESDAY": "le Mardi ",
      "ON_WEDNESDAY": "le Mercredi ",
      "ON_THURSDAY": "le Jeudi ",
      "ON_FRIDAY": "le Vendredi ",
      "ON_SATURDAY": "le Samedi ",
      "ON_SUNDAY": "le Dimanche ",
      "AND": " et ",
      "ON": "sur"
    },
    "VALIDATION": {
      "NOT_FOUND": "Non trouvé",
      "REQUIRED": "Requis",
      "INVALID_FORMAT": "Format non valide",
      "INVALID_DATE_RANGE": "Plage de dates non valide",
      "INVALID_PASSWORD_FORMAT": "Format de Mot de Passe Incorrect",
      "USER_ID_VALIDATION": "L'identifiant de l'utilisateur doit comporter 6 à 8 caractères",
      "START_END_STOPS_REQUIRED": "Les arrêts de début et de fin sont obligatoires !",
      "INVALID_STOPS_DATA": "Données d'arrêt non valables ! Veuillez saisir les données complètes",
      "INVALID_BLOCKS_DATA": "Données de positions non valides!Veuillez saisir les données complètes",
      "INVALID_PATTERNS_DATA": "Données de modèles non valides! Veuillez saisir les données complètes",
      "PATTERNS_REQUIRED": "Les modèles sont obligatoires !",
      "INVALID_EMAIL_FORMAT": "Format d'e-mail non valide",
      "ENTER_REQUIRED_FIELDS": "Veuillez saisir les champs obligatoires !",
      "MAX_DISCOUNT_VALUE": "La valeur de la remise doit être comprise entre 0 et 100",
      "MAX_CAPACITY_VALUE": "La valeur de la capacité doit être comprise entre 0 et 100",
      "DRIVERS_ERROR": "Ce chauffeur est déjà assigné à une autre service",
      "DRIVERS_ERROR_THIS": "Ce chauffeur est déjà assigné à ce service",
      "CONDUCTORS_ERROR": "Ce receveur est déjà assigné à un autre service",
      "CONDUCTORS_ERROR_THIS": "Ce receveur est déjà assigné à ce service"
    }
  },
  "GENERAL": {
    "BUTTONS": {
      "SELECT": "Sélectionner",
      "NEW": "Nouveau",
      "REFRESH": "Actualiser",
      "ANONYMOUS": "Anonyme",
      "BLACKLISTED": "Liste noire",
      "ASSIGNED": "Assigné",
      "VIEW_ON_MAP": "Voir sur la carte",
      "CANCEL": "Annuler",
      "APPLY": "Appliquer",
      "INSERT": "Insérer",
      "CONFIRM": "Confirmer",
      "LEAVE_PAGE": "Quitter la Page",
      "FILTERS": "Filtres",
      "ACTIONS": "Actions",
      "EXPIRED": "Expiré",
      "UPCOMING": "A venir",
      "TODAY": "Aujourd'hui",
      "TOMORROW": "Demain",
      "YESTERDAY": "Hier",
      "LAST_7_DAYS": "Les 7 derniers jours",
      "CURRENT": "En cours",
      "PAST": "Passé",
      "NEXT": "Suivant",
      "CLOSE": "Fermer",
      "SUBMIT": "Soumettre",
      "DELETE": "Supprimer",
      "SAVE": "Sauvegarder",
      "UPDATE": "Mettre à jour",
      "RESET": "Réinitialiser",
      "ADD": "Ajouter",
      "EDIT": "Modifier",
      "CREATE": "Créer",
      "ACTIVATE": "Activer",
      "DEACTIVATE": "Désactiver",
      "PENDING": "En attente",
      "DAMAGED": "Endommagé",
      "OBSOLETE": "Obsolète",
      "OUT_OF_SERVICE": "Hors service",
      "INREPAIR": "En Réparation",
      "MAINTENANCE": "Maintenance",
      "DUPLICATE": "Dupliquer",
      "DRAFT": "Définir comme brouillon",
      "SAVE_DRAFT": "Enregistrer comme brouillon",
      "SAVE_PENDING": "Définir comme En Attente",
      "SAVE_CHANGES": "Enregistrer les modifications",
      "PUBLISH": "Publier",
      "READY": "Prêt",
      "MARK_AS_READY": "Marquer comme Prêt",
      "UNASSIGN": "Annuler l'attribution",
      "SELECT_ALL": "Sélectionner tout",
      "UNSELECT_ALL": "Désélectionner tout",
      "SAVE_DRAFT_GRAPH": "Enregistrer comme brouillon et voir le graphique",
      "DOWNLOAD_TEMPLATE": "Télécharger le modèle",
      "DOWNLOAD_SELECTED_ROWS": "Télécharger les lignes sélectionnées",
      "DOWNLOAD_FILTERED_ROWS": "Télécharger les lignes filtrées",
      "IMPORT": "Importer",
      "EXPORT": "Exporter",
      "SET_AS_DRAFT": "Définir comme brouillon",
      "SET_AS_PENDING": "Définir comme en Attente",
      "SAVE_CONFIGURE": "Enregistrer et Configurer les Services",
      "VIEW_ALL_ZONES": "Afficher toutes les Zones"
    }
  },
  "MSGS": {
    "GENERAL": {
      "INVALID_PHONE_NUMBER": "Numéro de téléphone invalide",
      "REQUIRED_EMAIL": "L'adresse e-mail est requise",
      "SOMETHING_WRONG": "Un problème s'est produit",
      "SELECT_ROW": "Veuillez sélectionner au moins une ligne.",
      "SELECT_FILTER": "Veuillez sélectionner au moins un filtre.",
      "CONFIRM_DEPARTURE_TIMES": "Veuillez confirmer les heures de départ pour continuer",
      "CONFIRM_PUBLISH_SERVICES": "La publication d'une création de service est irréversible ; la création de service ne pourra pas être modifiée par la suite ! Les nouveaux horaires créés ultérieurement dans cette plage de dates ne seront plus inclus.",
      "DRAFT_TIMETABLES-DETECTED": "Nous détectons des Brouillon d'horaires dans la même plage de dates. En les publiant, ces horaires ainsi que les nouveaux ne seront plus inclus.",
      "INVALID_FILE": "Type de fichier non valide ! Seuls les formats jpg, jpeg, png et pdf sont autorisés.",
      "INVALID_IMAGE": "Type d'image non valide ! Seuls les formats jpg, jpeg, png sont autorisés.",
      "DONE": "Terminé",
      "ALL_ZONES": "Toutes les zones",
      "ARE_YOU_SURE": "Êtes-vous sûr de vouloir effectuer cette action ?",
      "NO_RESULTS_FOUND": "Aucun résultat trouvé",
      "NO_TRANSACTION_EXISTS": "Aucune transaction n'a été trouvée !",
      "NO_RESULTS_FOUND_GENERIC": "Enregistrement inexistant! Cliquez sur Nouveau ou affinez votre recherche.",
      "FILE_TOO_LARGE": "Le fichier sélectionné est trop volumineux. Veuillez sélectionner un fichier de moins de 200 Ko",
      "IMAGE_TOO_LARGE": "L'image sélectionnée est trop grande. Veuillez sélectionner une image de moins de 2 Mo",
      "STATUS_UPDATED_SUCCESS": "Statut mis à jour avec succès",
      "LOADING": "Chargement en cours, veuillez patienter",
      "START_DATE_ERROR": "La date de début doit être antérieure à la date de fin",
      "START_TIME_ERROR": "L'heure de début doit être antérieure à l'heure de fin",
      "END_TIME_ERROR": "L'heure de fin doit être postérieure à l'heure de début",
      "INVALID_END_TIME": "Heure de fin invalide",
      "INVALID_START_TIME_DRIVING": "Il ne doit y avoir aucun intervalle de conduite entre l'heure de fin et l'heure de début",
      "INVALID_START_TIME_DRIVING_REST_TIME": "L'heure de début de conduite doit correspondre à l'heure de début du prochain voyage",
      "INVALID_START_TIME": "Heure de début invalide",
      "START_END_TIME_SAME": "L'heure de début et l'heure de fin ne peuvent pas être identiques",
      "YES": "OUI",
      "NO": "NON",
      "SEGMENT": "Section",
      "STOP": "Arrêt",
      "BLOCKS_MISSING": "Les positions de voyage sont requis",
      "UNASSIGN_ROWS": "Êtes-vous sûr de vouloir désaffecter ?",
      "MARK_PLAN_READY_CONFIRMATION": "Êtes-vous sûr de vouloir marquer ce plan comme prêt ?",
      "UNSAVED_CHANGES": "Êtes-vous sûr de vouloir quitter l'écran sans enregistrer les modifications ?",
      "VIEW_MAP_MIN_STOPS_ERROR": "Il doit y avoir au moins 2 arrêts pour afficher la carte",
      "DUPLICATE_END_TIME": "Données invalides ! Deux services ne doivent pas avoir la même heure de fin",
      "DUPLICATE_START_TIME": "Données invalides ! Deux services ne doivent pas avoir la même heure de début",
      "INVALID_START_END_TIME": "L'heure de début ou l'heure de fin ne peut pas être '00:00' dans un service",
      "INVALID_START_TIME_OVERLAP": "L'heure de début des services ne doit pas se chevaucher",
      "SERVICE_EXCEEDS_10_HRS": "La durée du service dépasse 10 heures.",
      "END_TIME_AFTER_START_TIME": "L'heure de fin d'un service ne peut pas être postérieure à l'heure de début du service suivant",
      "INVALID_START_TIME_DIFFERENCE": "Il doit y avoir au moins une minute de différence entre l'heure de fin du service précédent et l'heure de début du service suivant",
      "START_END_TIME_OVERLAP_TRIPS": "Il ne doit y avoir aucun chevauchement entre l'heure de début ou l'heure de fin du service et les voyages existants",
      "SHOW_DEPARTURES_VALIDATION_ERROR": "Entrée invalide : l'heure de début, l'heure de fin ou l'intervalle sont manquants ou non valides."
    },
    "DEVICES": {
      "DELETE_DEVICE_TYPE": "Êtes-vous sûr de vouloir supprimer ce dispositif ?",
      "CREATE_DEVICE_TYPE": "Dispositif créé avec succès",
      "SAVE_AS_DRAFT_DEVICE_TYPE": "Dispositif enregistré en tant que brouillon avec succès",
      "UPDATE_DEVICE_TYPE": "Détails du dispositif mis à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour le dispositif",
      "UNABLE_TO_CREATE": "Impossible de créer le dispositif",
      "DELETE_DEVICE_TYPE_SUCCESS": "Dispositif supprimé avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer le dispositif",
      "UPDATE_DEVICE_TYPE_STATUS": "Le(s) appareil(s) sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_DEVICE_TYPE_STATUS": "Impossible de mettre à jour le statut du dispositif"
    },
    "VEHICLE_DISPATCHING": {
      "UPDATE_VEHCILE_ASSIGNMENT": "Attribution du véhicule ajoutée avec succès",
      "UPDATE_REPLACEMENT_VEHCILE_ASSIGNMENT": "Attribution du véhicule de remplacement ajoutée avec succès",
      "UPDATE_REPLACEMENT_ASSIGNMENT": "Attribution de remplacement mise à jour avec succès",
      "SAME_VEHICLES": "Le même véhicule ne peut pas être attribué en tant que remplacement. Veuillez sélectionner un véhicule différent",
      "SELECT_DRIVER": "Veuillez sélectionner un chauffeur ou un chauffeur secondaire pour continuer",
      "SELECT_DRIVER_CONDUCTOR": "Veuillez sélectionner un chauffeur ou un receveur pour continuer",
      "ERROR_DRIVERS": "Les mêmes chauffeurs ne peuvent pas être attribués en tant que remplacement. Veuillez sélectionner un chauffeur différent"
    },
    "USERS": {
      "EDIT_SUPER_ADMIN": "Le super admin ne peut pas être modifié",
      "DELETE_TEXT": "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
      "CREATE_TEXT": "Utilisateur créé avec succès",
      "SAVE_AS_DRAFT_TEXT": "Utilisateur enregistré avec succès en tant qu'en attente",
      "UPDATE_TEXT": "Données de l'utilisateur mises à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour l'utilisateur",
      "DELETE_TEXT_SUCCESS": "Utilisateur supprimé avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer l'utilisateur",
      "UPDATE_TEXT_STATUS": "Le(s) utilisateur(s) sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_TEXT_STATUS": "Impossible de mettre à jour le statut de l'utilisateur",
      "UPDATE_PASSWORD": "Mise à jour réussie du mot de passe de l'utilisateur",
      "UNABLE_TO_UPDATE_PASSWORD": "Impossible de mettre à jour le mot de passe de l'utilisateur",
      "DELETE_USER": "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
      "CREATE_USER": "Utilisateur créé avec succès",
      "SAVE_AS_DRAFT_USER": "Utilisateur sauvegardé comme brouillon avec succès",
      "UPDATE_USER_PROFILE": "Le profil de l'utilisateur a été mis à jour avec succès",
      "UPDATE_PROFILE": "Profil mis à jour avec succès ! Veuillez actualiser la page ou vous déconnecter et vous reconnecter pour voir les modifications",
      "UNABLE_TO_UPDATE_PROFILE": "Impossible de mettre à jour le profil de l'utilisateur",
      "UPDATE_USER_ADDRESS": "L'adresse de l'utilisateur a été mise à jour avec succès",
      "UNABLE_TO_UPDATE_ADDRESS": "Impossible de mettre à jour l'adresse de l'utilisateur",
      "UPDATE_USER_DETAILS": "Données de l'utilisateur mises à jour avec succès",
      "UNABLE_TO_UPDATE_DETAILS": "Impossible de mettre à jour les coordonnées de l'utilisateur",
      "UPDATE_USER_DOCUMENTS": "Document de l'utilisateur mis à jour avec succès",
      "UPDATE_UPLOAD_USER_DOCUMENTS": "Les documents de l'utilisateur ont été mis à jour et téléchargés avec succès",
      "UNABLE_TO_UPDATE_DOCUMENTS": "Impossible de mettre à jour les documents de l'utilisateur",
      "UNABLE_TO_CREATE": "Impossible de créer un utilisateur",
      "DELETE_DOCUMENT_SUCCESS": "Document supprimé avec succès",
      "UNABLE_TO_DELETE_DOCUMENT": "Impossible de supprimer le document",
      "DOWNLOAD_DOCUMENT_SUCCESS": "Document téléchargé avec succès",
      "UNABLE_TO_DOWNLOAD_DOCUMENT": "Impossible de télécharger le document",
      "DELETE_USER_SUCCESS": "Utilisateur supprimé avec succès",
      "UPDATE_USER_STATUS": "Le(s) utilisateur(s) sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_USER_STATUS": "Impossible de mettre à jour le statut de l'utilisateur",
      "UNABLE_TO_DOWNLOAD_TEMPLATE": "Impossible de télécharger le modèle",
      "UNABLE_TO_EXPORT": "Impossible d'exporter des utilisateurs",
      "UNABLE_TO_EXPORT_NFC": "Impossible d'exporter les cartes NFC",
      "ONLY_EXCEL_FILE_ERROR": "Type de fichier non valide ! Seuls les fichiers .xls et .xlsx sont autorisés.",
      "ONLY_CSV_FILE_ERROR": "Type de fichier invalide ! Seuls les fichiers .csv sont autorisés.",
      "SELECT_FILE": "Veuillez choisir un fichier.",
      "IMPORT_USERS": "Le processus d'importation a commencé. Vous serez informé par e-mail dès qu'il sera terminé.",
      "UNABLE_TO_IMPORT": "Le processus d'importation n'a pas pu être lancé. Réviser le fichier soumis",
      "UNABLE_TO_DELETE_SUPER_ADMIN": "Un ou plusieurs utilisateurs sélectionnés ont le rôle de 'Super Admin'. Les utilisateurs avec le rôle 'Super Admin' ne peuvent pas être supprimés",
      "OWN_PROFILE_DELETION": "Vous ne pouvez pas désactiver ou supprimer votre profil utilisateur",
      "USER_HAS_NO_ACTIVITIES": "L'utilisateur n'a aucune activité",
      "SUPER_ADMIN_STATUS_CHANGE": "L'utilisateur de ce rôle ne peut pas modifier le statut de Super-Administrateur"
    },
    "TRANSACTION_SETTLEMENT": {
      "FILTERS_REQUIRED_MESSAGE": "Veuillez spécifier les filtres nécessaires pour générer le rapport."
    },
    "ROLES": {
      "DELETE_ROLES": "Êtes-vous sûr de vouloir supprimer ce rôle ?",
      "CREATE_TEXT": "Rôle créé avec succès",
      "SAVE_AS_DRAFT_TEXT": "Rôle sauvegardé comme brouillon avec succès",
      "CREATE_TEXT_PERMISSION": "Permission créée avec succès",
      "SAVE_AS_DRAFT_TEXT_PERMISSION": "Permission sauvegardée comme brouillon avec succès",
      "CREATE_ROLE_FIRST": "Créer d'abord un rôle pour lui attribuer des autorisations ",
      "UPDATE_TEXT_PERMISSION": "Détail de l'autorisation mis à jour avec succès",
      "UPDATE_TEXT": "Détail du rôle mis à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour un rôle",
      "UNABLE_TO_CREATE": "Impossible de créer un rôle",
      "UNABLE_TO_UPDATE_PERMISSION": "Impossible de mettre à jour une autorisation",
      "UNABLE_TO_CREATE_PERMISSION": "Impossible de créer une autorisation",
      "DELETE_TEXT_SUCCESS": "Rôle supprimé avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer un rôle",
      "UPDATE_TEXT_STATUS": "Le(s) rôle(s) sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_TEXT_STATUS": "Impossible de mettre à jour le statut du rôle"
    },
    "VEHICLES": {
      "SELECT_ROW": "Veuillez sélectionner au moins une ligne.",
      "CREATE_TEXT": "Véhicule créé avec succès",
      "SAVE_AS_DRAFT_TEXT": "Véhicule sauvegardé comme brouillon avec succès",
      "UPDATE_TEXT": "Détails du véhicule mis à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour le véhicule",
      "UNABLE_TO_CREATE": "Impossible de créer un véhicule",
      "DELETE_TEXT_SUCCESS": "Véhicule supprimé avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer le véhicule",
      "UPDATE_TEXT_STATUS": "Le(s) véhicule(s) sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_TEXT_STATUS": "Impossible de mettre à jour le statut du véhicule",
      "DELETE_MESSAGE": "Êtes-vous sûr de vouloir supprimer ce véhicule ?",
      "MODELYEAR": "La valeur doit être comprise entre {{yearThirtyYearsAgo}} et {{currentYear}}"
    },
    "STOPS": {
      "STOPS_ZONE_TOOLTIP": "Les arrêts n'appartenant pas aux sous-zones ne peuvent être utilisés que pour les lignes interurbaines",
      "DELETE_STOP": "Êtes-vous sûr de vouloir supprimer cet arrêt ?",
      "CREATE_STOP": "Arrêt créé avec succès",
      "SAVE_AS_DRAFT_STOP": "Arrêt sauvegardé comme brouillon avec succès",
      "UPDATE_STOP": "Détails de l'arrêt mis à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour l'arrêt",
      "UNABLE_TO_CREATE": "Impossible de créer un arrêt",
      "DELETE_STOP_SUCCESS": "Arrêt supprimé avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer l'arrêt",
      "UPDATE_STOP_STATUS": "Le(s) arrêt(s) sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_STOP_STATUS": "Impossible de mettre à jour le statut de l'arrêt",
      "UNABLE_TO_DOWNLOAD_TEMPLATE": "Impossible de télécharger le modèle",
      "UNABLE_TO_EXPORT": "Impossible d'exporter les arrêts",
      "ONLY_EXCEL_FILE_ERROR": "Type de fichier non valide ! Seuls les fichiers .xls et .xlsx sont autorisés.",
      "ONLY_CSV_FILE_ERROR": "Type de fichier invalide ! Seuls les fichiers .csv sont autorisés.",
      "SELECT_FILE": "Veuillez choisir un fichier.",
      "IMPORT_STOPS": "Le processus d'importation a commencé. Vous serez informé par e-mail dès qu'il sera terminé.",
      "UNABLE_TO_IMPORT": "Le processus d'importation n'a pas pu être lancé. Réviser le fichier soumis"
    },
    "SEGMENTS": {
      "SAME_STOPS": "Vous ne pouvez pas créer de section avec les mêmes arrêts",
      "SAME_PATTERNS": "Vous ne pouvez pas créer de section avec les mêmes modèles",
      "DELETE_SEGMENT": "Êtes-vous sûr de vouloir supprimer cette section?",
      "CREATE_SEGMENT": "Section créé avec succès",
      "SAVE_AS_DRAFT_SEGMENT": "Section sauvegardée comme brouillon avec succès",
      "UPDATE_SEGMENT": "Mise à jour des détails de la section réussie",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour la section",
      "UNABLE_TO_CREATE": "Impossible de créer une section",
      "DELETE_SEGMENT_SUCCESS": "Section supprimée avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer la section",
      "UPDATE_SEGMENT_STATUS": "La(es) section(s) sélectionnée(s) a/ont été mise(s) à jour avec succès",
      "UNABLE_TO_UPDATE_SEGMENT_STATUS": "Impossible de mettre à jour le statut de la section",
      "OVER_LAPPING_PATTERNS": "Deux modèles normaux qui se chevauchent ne peuvent pas être ajoutés."
    },
    "PATTERNS": {
      "DELETE_SYSTEM_PATTERN": "Les modèles du système ne peuvent pas être supprimés",
      "DELETE_SYSTEM_PATTERN_ERROR": "Les modèles du système ne peuvent pas être supprimés ou mis à jour",
      "DELETE_PATTERN": "Êtes-vous sûr de vouloir supprimer ce modèle ?",
      "CREATE_PATTERN": "Modèle créé avec succès",
      "SAVE_AS_DRAFT_PATTERN": "Patron sauvegardé comme brouillon avec succès",
      "UPDATE_PATTERN": "Les détails du modèle ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour le modèle",
      "UNABLE_TO_CREATE": "Impossible de créer un modèle",
      "DELETE_PATTERN_SUCCESS": "Le modèle a été supprimé avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer le modèle",
      "UPDATE_PATTERN_STATUS": "Le(s) modèle(s) sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_PATTERN_STATUS": "Impossible de mettre à jour le statut du modèle ",
      "TIME_VALIDATION": "L'heure de fin ne doit pas être antérieure à l'heure de début"
    },
    "URBAN_FARES": {
      "DELETE_URBAN_FARE": "Êtes-vous sûr de vouloir supprimer ce tarif urbain ?",
      "CREATE_URBAN_FARE": "Tarif urbain créé avec succès",
      "SAVE_AS_DRAFT_URBAN_FARE": "Tarif urbain sauvegardé comme brouillon avec succès",
      "UPDATE_URBAN_FARE": "Détail du tarif urbain mis à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour le tarif urbain",
      "UNABLE_TO_CREATE": "Impossible de créer un tarif urbain",
      "DELETE_URBAN_FARE_SUCCESS": "Tarif urbain supprimé avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer un tarif urbain",
      "UPDATE_URBAN_FARE_STATUS": "Le(s) tarif(s) urbain(s) sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_URBAN_FARE_STATUS": "Impossible de mettre à jour le statut du tarif urbain",
      "FARE_DUPLICATED": "Le tarif a été dupliqué avec succès",
      "UNABLE_TO_DUPLICATE_FARE": "Impossible de dupliquer le tarif."
    },
    "FRAUD_TYPES": {
      "DELETE_FRAUD_TYPE": "Êtes-vous sûr de vouloir supprimer ce type de violation ?",
      "CREATE_FRAUD_TYPE": "Type de violation créé avec succès",
      "SAVE_AS_DRAFT_FRAUD_TYPE": "Type de violation enregistré en tant que brouillon avec succès",
      "UPDATE_FRAUD_TYPE": "Détails du type de violation mis à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour le type de violation",
      "UNABLE_TO_CREATE": "Impossible de créer le type de violation",
      "DELETE_FRAUD_TYPE_SUCCESS": "Type de violation supprimé avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer le type de violation",
      "UPDATE_FRAUD_TYPE_STATUS": "Le(s) type(s) de violation sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_FRAUD_TYPE_STATUS": "Impossible de mettre à jour le statut du type de violation"
    },
    "HOLIDAYS": {
      "DELETE_HOLIDAYS": "Êtes-vous sûr de vouloir supprimer ce jour férié ?",
      "CREATE_HOLIDAYS": "Jour férié créé avec succès",
      "SAVE_AS_DRAFT_HOLIDAYS": "Jour férié enregistré en tant que brouillon avec succès",
      "UPDATE_HOLIDAYS": "Détails du jour férié mis à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour le jour férié",
      "UNABLE_TO_CREATE": "Impossible de créer le jour férié",
      "DELETE_HOLIDAYS_SUCCESS": "Jour férié supprimé avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer le jour férié",
      "UPDATE_HOLIDAYS_STATUS": "Le(s) jour(s) férié(s) sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_HOLIDAYS_STATUS": "Impossible de mettre à jour le statut du jour férié"
    },
    "PASSES_FARES": {
      "DELETE_PASSES_FARE": "Êtes-vous sûr de vouloir supprimer cet abonnement?",
      "CREATE_PASSES_FARE": "L'abonnement a été créé avec succès",
      "SAVE_AS_DRAFT_PASSES_FARE": "Enregistrement de l'abonnement en tant que brouillon réussi",
      "UPDATE_PASSES_FARE": "Mise à jour des détails de l'abonnement réussie",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour l'abonnemnet",
      "UNABLE_TO_CREATE": "Impossible de créer un abonnement",
      "DELETE_PASSES_FARE_SUCCESS": "Abonnement supprimé avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer l'abonnement",
      "UPDATE_PASSES_FARE_STATUS": "Le(s) abonnement(s) sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_PASSES_FARE_STATUS": "Impossible de mettre à jour le statut de l'abonnement"
    },
    "INTERCITY_FARES": {
      "DELETE_INTERCITY_FARE": "Êtes-vous sûr de vouloir supprimer ce tarif interurbain ?",
      "CREATE_INTERCITY_FARE": "Tarif interurbain créé avec succès",
      "SAVE_AS_DRAFT_INTERCITY_FARE": "Tarif interurbain sauvegardé comme brouillon avec succès",
      "UPDATE_INTERCITY_FARE": "Détail du tarif interurbain mis à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour le tarif interurbain",
      "UNABLE_TO_CREATE": "Impossible de créer un tarif interurbain",
      "DELETE_INTERCITY_FARE_SUCCESS": "Tarif interurbain supprimé avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer le tarif interurbain",
      "UPDATE_INTERCITY_FARE_STATUS": "Le(s) tarif(s) interurbain(s) sélectionné(s) a/ont été mis à jour avec succès",
      "UNABLE_TO_UPDATE_INTERCITY_FARE_STATUS": "Impossible de mettre à jour le statut du tarif interurbain",
      "SELECT_LINE": "Veuillez sélectionner une ligne",
      "LOAD_VARIATIONS": "Veuillez charger les variations !",
      "NO_STOPS": "Vous ne pouvez pas ajouter de tarif pour cette ligne car il n'y a pas d'arrêts"
    },
    "LINES": {
      "START_STOP_STATION_ERROR": "Il est conseillé de définir l'arrêt de départ comme une station",
      "END_STOP_STATION_ERROR": "Il est conseillé de définir l'arrêt d'arrivée comme une station",
      "START_END_OUT_IN_ERROR": "L'arrêt de départ pour le trajet sortant doit être l'arrêt d'arrivée pour le trajet entrant",
      "END_START_OUT_IN_ERROR": "L'arrêt d'arrivée pour le trajet sortant doit être l'arrêt de départ pour le trajet entrant",
      "SAME_IN_STOPS": "Une ligne ne peut pas avoir le même arrêt ajouté plusieurs fois",
      "SAME_OUT_STOPS": "Une ligne ne peut pas avoir le même arrêt ajouté plusieurs fois",
      "DELETE_LINE": "Êtes-vous sûr de vouloir supprimer cette ligne ?",
      "CREATE_LINE": "Ligne créée avec succès",
      "SAVE_AS_DRAFT_LINE": "Ligne sauvegardée comme brouillon avec succès",
      "CREATE_LINE_FIRST": "Sauvegarder d'abord la ligne pour mettre à jour ses détails",
      "UPDATE_LINE": "Mise à jour des détails de la ligne réussie",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour la ligne",
      "UNABLE_TO_CREATE": "Impossible de créer une ligne",
      "DELETE_LINE_SUCCESS": "Ligne supprimée avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer la ligne",
      "UPDATE_LINE_STATUS": "Le(s) ligne(s) sélectionnée(s) a/ont été mise(s) à jour avec succès",
      "UNABLE_TO_UPDATE_LINE_STATUS": "Impossible de mettre à jour le statut de la ligne",
      "OCCURS_FROM_NEVER_ENDS": "Se produit {{occurrence}} à partir de {{from}} ({{fromTime}} - {{toTime}}), et n'a jamais de fin",
      "OCCURS_FROM_UNTIL": "Se produit {{occurrence}} à partir de {{from}} ({{fromTime}} - {{toTime}}), jusqu'à {{to}}"
    },
    "TRIPS": {
      "DELETE_TRIP": "Êtes-vous sûr de vouloir supprimer ce voyage?",
      "CREATE_TRIP": "Voyage créé avec succès",
      "CREATE_TRIPS": "Voyages créés avec succès",
      "SAVE_AS_DRAFT_TRIP": "Voyage sauvegardé en tant que brouillon avec succès",
      "UPDATE_TRIP": "Détails du voyage mis à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour le voyage ",
      "UNABLE_TO_CREATE": "Impossible de créer un voyage ",
      "UNABLE_TO_CREATES": "Impossible de créer des voyages",
      "DELETE_TRIP_SUCCESS": "Suppression du voyage réussie",
      "UNABLE_TO_DELETE": "Impossible de supprimer le voyage ",
      "UPDATE_TRIP_STATUS": "Le statut du(des) voyage(s) sélectionné(s) a été mis à jour avec succès",
      "UNABLE_TO_UPDATE_TRIP_STATUS": "Impossible de mettre à jour le statut du voyage ",
      "TRIP_DUPLICATED": "Voyage dupliqué avec succès",
      "UNABLE_TO_TRIP_DUPLICATE": "Impossible de dupliquer le voyage ",
      "TRIP_TIME_ERROR": "Format d'heure non valide. Veuillez saisir l'heure au format hh:mm, où les heures sont comprises entre 00 et 23 et les minutes entre 00 et 59.",
      "DEPARTURE_TIME_EXISTS_ALREADY": "L'heure de départ existe déjà",
      "DEPARTURE_TIMES_MISSING": "Veuillez entrer les heures de départ",
      "CREATE_DUPLICATE_TRIPS": "Succès : {{tripsCount}} sur {{totalTripsCount}} ont été créés, car les {{duplicateCount}} restants existent déjà !",
      "CREATE_WITHOUT_DUPLICATE_TRIPS": "Succès : {{tripsCount}} voyages sont actuellement en cours de création. Veuillez vérifier de nouveau dans quelques instants"
    },
    "NOTIFICATIONS": {
      "DELETE_NOTIFICATION": "Êtes-vous sûr de vouloir supprimer cette notification ?",
      "CREATE_NOTIFICATION": "Notification créée avec succès",
      "CREATE_NOTIFICATIONS": "Notifications créées avec succès",
      "SAVE_AS_DRAFT_NOTIFICATION": "Notification enregistrée en tant que brouillon avec succès",
      "UPDATE_NOTIFICATION": "Détails de la notification mis à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour la notification",
      "UNABLE_TO_CREATE": "Impossible de créer la notification",
      "UNABLE_TO_CREATES": "Impossible de créer les notifications",
      "DELETE_NOTIFICATION_SUCCESS": "Notification supprimée avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer la notification",
      "UPDATE_NOTIFICATION_STATUS": "Le statut des notifications sélectionnées a été mis à jour avec succès",
      "UNABLE_TO_UPDATE_NOTIFICATION_STATUS": "Impossible de mettre à jour le statut de la notification"
    },
    "TIMETABLE": {
      "CREATE": "Horaire créé avec succès",
      "INVALID_DATE": "La Date de Fin ne peut pas être supérieure à la Date de Début",
      "INVALID_WEEKDAY": "Sélection invalide",
      "OCCURRENCES_MISSING_OR_NOT_COMPATIBLE": "Les occurrences sont soit manquantes, soit incompatibles avec la plage de dates sélectionnée.",
      "NO_RESULT_FOUND": "No trips available for the selected Line on that date",
      "SAVE_AS_DRAFT": "Enregistrement de l'horaire en tant que brouillon réussi",
      "UNABLE_TO_CREATE": "Impossible de créer un voyage ",
      "UPDATE_TIMETABLE_STATUS": "Le statut des horaires sélectionnés a été mis à jour avec succès",
      "UNABLE_TO_UPDATE_STATUS": "Impossible de mettre à jour le statut du voyage ",
      "DUPLICATE_SINGLE": "Un seul horaire peut être sélectionné pour être dupliqué",
      "DUPLICATE_TIMETABLE_SELECT": "Veuillez sélectionner un horaire",
      "DUPLICATE_SUCCESS": "Horaire dupliqué avec succès !",
      "NO_TRIPS": "Aucun Voyage Ajouté",
      "TRIPS_OVERLAPPING": "Chevauchement de voyages détecté!",
      "INVALID_WEEKDAYS": "Veuillez sélectionner au moins un jour de la semaine",
      "PUBLISH_TIMETABLE": "La publication d'un horaire est irréversible, l'horaire ne peut plus être modifié par la suite !",
      "PUBLISH_TIMETABLE_TRIPS": "La publication d'un horaire est irréversible ; celui-ci ne pourra plus être modifié par la suite ! Tous les trajets créés qui ne sont pas assignés à des positions ne seront plus utiles, veuillez donc les supprimer ou les assigner."
    },
    "PLAN": {
      "CREATE": "Plan créé avec succès",
      "SERVICE_ASSIGN_TO_ROW": "Services assignés à la rangée avec succès",
      "SERVICE_UNASSIGN_TO_ROW": "Services désassignés de la rangée avec succès",
      "INVALID_DATE": "La \"Planification Jusqu'à\" ne peut pas être antérieure à la date de la dernière planification",
      "SAVE_AS_DRAFT": "Plan enregistré en tant que brouillon avec succès",
      "UNABLE_TO_CREATE": "Impossible de créer le plan",
      "UPDATE_STATUS": "Le statut du ou des forfaits sélectionnés a été mis à jour avec succès",
      "UNABLE_TO_UPDATE_STATUS": "Impossible de mettre à jour le statut du trajet",
      "DUPLICATE_SINGLE": "Seul un seul plan peut être sélectionné pour être dupliqué",
      "DUPLICATE_SELECT": "Veuillez sélectionner un plan",
      "SELECT_ONE_SERVICE": "Veuillez sélectionner au moins un service",
      "PLAN_LIMIT": "Vous êtes autorisé à planifier uniquement pour les deux prochains mois à la fois",
      "TIME_LIMIT": "L'heure de fin doit être supérieure à l'heure de début.",
      "SERVICE_OVERLAP": "Les horaires des services ne peuvent pas se chevaucher.",
      "INTERCITY_TO_URBAN_ASSIGNMENT_ERROR": "Problème avec l'attribution de service de l'interurbain à l'urbain",
      "OVERLAPPING_PAYLOAD_SHIFTS_ERROR": "Un service existe déjà pour les paramètres sélectionnés",
      "DATE_RANGE_ERROR": "Problème avec la plage de dates sélectionnée",
      "OVERLAPPING_EXISTING_SHIFTS_ERROR": "Les postes de service ne peuvent pas se chevaucher",
      "DUPLICATE_SUCCESS": "Plan dupliqué avec succès !"
    },
    "SCHEDULE_SERVICE_GROUP": {
      "ADD_SERVICE_GROUP": "Groupe de services ajouté avec succès",
      "ADD_SERVICE_GROUP_ERROR": "Impossible d'ajouter le groupe de services",
      "EDIT_SERVICE_GROUP": "Groupe de services modifié avec succès",
      "EDIT_SERVICE_GROUP_ERROR": "Impossible de modifier le groupe de services",
      "DELETE_SERVICE_GROUP": "Groupe de services supprimé avec succès",
      "DELETE_SERVICE_GROUP_ERROR": "Impossible de supprimer le groupe de services"
    },
    "NFC_CARDS": {
      "DELETE_CARD": "Êtes-vous sûr de vouloir supprimer cette carte NFC ?",
      "CREATE_CARD": "Carte NFC créée avec succès",
      "SAVE_AS_DRAFT_CARD": "Carte NFC enregistrée en tant que brouillon avec succès",
      "UPDATE_CARD": "Détails de la carte mis à jour avec succès",
      "UNABLE_TO_UPDATE": "Impossible de mettre à jour la carte",
      "UNABLE_TO_CREATE": "Impossible de créer la carte",
      "DELETE_CARD_SUCCESS": "Carte supprimée avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer la carte",
      "UPDATE_CARD_STATUS": "La(s) carte(s) sélectionnée(s) a/ont été mise(s) à jour avec succès",
      "UNABLE_TO_UPDATE_CARD_STATUS": "Impossible de mettre à jour le statut de la carte",
      "DELETE_MSG": "Seules les cartes avec le statut 'Nouveau' peuvent être supprimées"
    },
    "ZONE": {
      "INVALID_SUBZONE": "Veuillez ajouter une Sous-Zone",
      "INVALID_ZONE": "Veuillez ajouter une zone",
      "DELETE_ZONE": "Êtes-vous sûr de vouloir supprimer cette Zone ?",
      "DELETE_SUB_ZONE": "Êtes-vous sûr de vouloir supprimer cette sous-zone ?",
      "DELETE_ZONE_SUCCESS": "Zone supprimée avec succès",
      "UNABLE_TO_DELETE": "Impossible de supprimer la zone",
      "MSG_MAX_ZONE_ALLOWED": "Le nombre maximum de points autorisés est de 70"
    }
  },
  "BREADCRUMBS": {
    "REPORTS": {
      "CUSTOMERS_REPORT": "Rapport Clients",
      "FINANCES_REPORT": "Rapport financier",
      "LINE_COMPARISON_REPORT": "Rapport de Comparaison des Lignes",
      "RIDERSHIP_REPORT": "Rapport de Fréquentation",
      "VEHICLE_REPORT": "Rapports Véhicules",
      "BUS_CONDUCTOR_REPORT": "Rapport du Receveur",
      "SALES_AGENT_REPORT": "Rapport de l'Agent de Point de Vente",
      "POS_OPERATOR_REPORT": "Rapport de l'Opérateur POS",
      "SHIPMENT_REPORT": "Rapport d'Expédition"
    },
    "USER_MANAGEMENT": {
      "TITLE": "Gestion des utilisateurs",
      "USER_LISTING": "Utilisateurs",
      "ADMIN_USER": "Utilisateur Administrateur",
      "APP_USER": "Utilisateur de l'Application",
      "ADD_USER": "Ajouter un utilisateur",
      "EDIT_USER": "Modifier un utilisateur"
    },
    "DASHBOARD": {
      "TITLE": "Tableau de bord"
    },
    "PROFILE": {
      "TITLE": "Profil"
    },
    "SYSTEM_LOGS": {
      "TITLE": "Journal du système"
    },
    "E_TICKETING": {
      "TITLE": "Collecte Automatique des Tarifs",
      "TITLE_MINI": "CAT",
      "PASSES_MANAGEMENT": "Gestion des abonnements",
      "CREATE_PASS": "Ajouter un nouvel abonnement",
      "PASS_DETAIL": "Détails de l'abonnement",
      "INTERCITY_FARES": "Tarifs interurbains",
      "ADD_INTERCITY_FARE": "Ajouter les tarifs interurbains",
      "DETAIL_INTERCITY_FARE": "Détails des tarifs interurbains",
      "URBAN_FARES": "Tarifs urbains",
      "ADD_URBAN_FARE": "Ajouter les tarifs urbains",
      "DETAIL_URBAN_FARE": "Détails des tarifs urbains",
      "ZONE_LISTING": "Zones",
      "ADD_ZONE": "Ajouter une zone",
      "DETAIL_ZONE": "Détail de la zone",
      "FRAUDS": "Infractions et Amendes",
      "NEW_FRAUD": "Ajouter une Infraction",
      "FRAUD_DETAILS": "Détails de l'Infraction",
      "TRANSACTION_SETTLEMENT": "Règlement des Transactions",
      "TRANSACTION_SETTLEMENT_DETAILS": "Détails du Règlement des Transactions"
    },
    "ROLES_AND_PERMISSIONS": {
      "TITLE": "Rôles et Autorisations",
      "ACTIVE_ROLE_LIST": "Liste des rôles actifs",
      "CREATE_ROLE": "Ajouter un rôle",
      "EDIT_ROLE": "Modifier un rôle"
    },
    "NETWORK_AND_PLANNING": {
      "TITLE": "Réseau",
      "STOPS": "Arrêt",
      "ADD_STOP": "Ajouter un arrêt",
      "EDIT_STOP": "Modifier un arrêt",
      "SEGMENTS": "Sections",
      "ADD_SEGMENT": "Ajouter une section",
      "EDIT_SEGMENT": "Modifier une section",
      "PATTERNS": "Modèles ",
      "ADD_PATTERN": "Ajouter un Modèle",
      "EDIT_PATTERN": "Modifier un Modèle",
      "LINES": "Lignes",
      "ADD_LINE": "Ajouter une ligne",
      "EDIT_LINE": "Modifier une ligne",
      "TRIPS": "Voyages",
      "ADD_TRIP": "Ajouter un voyage",
      "EDIT_TRIP": "Modifier un voyage",
      "ADD_TRIP_BULK": "Ajouter un Voyage Groupé",
      "TIMETABLE": "Horaires",
      "ADD_TIMETABLE": "Ajouter un horaire",
      "EDIT_TIMETABLE": "Modifier l'horaire"
    },
    "SCHEDULE": {
      "TITLE": "Planification",
      "SERVICE": "Création de services",
      "ADD_SERVICE": "Ajouter la création de services",
      "EDIT_SERVICE": "Modifier la création de service",
      "EMPLOYEE_ROSTER": "Liste de la Planification des Employés",
      "HOLIDAYS": "Jours fériés",
      "VEHICLE_DISPATCHING": "Répartition des Véhicules",
      "VEHICLE_DISPATCHING_DETAIL": "Détail"
    },
    "ROSTER": {
      "SERVICE_ROSTER_LISTING": "Liste des Planifications des Services"
    },
    "SCHEDULE_EMPLOYEE_ENGAGEMENT": "Engagement des Employés",
    "COLLECTION_REVENUE": "Revenu de la collecte",
    "FLEET_MANAGEMENT": {
      "TITLE": "Gestion de flotte",
      "VEHICLES_MANAGEMENT": "Gestion des véhicules"
    }
  },
  "PAGINATION": {
    "SHOWING": "Affichage de",
    "TO": "à",
    "OF": "sur",
    "ENTERIES": "entrées"
  },
  "MENU": {
    "Main": "Principal",
    "DASHBOARD": "Tableau de bord",
    "SYSTEM_LOGS": "Journal du système",
    "MODULE": "MODULE",
    "SETTINGS": "Paramètres",
    "CUSTOMER": "Client",
    "FINANCES": "Finances",
    "LINE_COMPARISON": "Comparaison des Lignes",
    "RIDERSHIP": "Fréquentation",
    "VEHICLE": "Véhicule",
    "BUS_CONDUCTOR": "Receveur",
    "SALES_AGENT": "Agent de Point de Ventes",
    "POS_OPERATOR": "Opérateur POS",
    "SHIPMENT": "Expédition",
    "REPORTS": "Rapports",
    "ROLES_AND_PERMISSIONS": "Rôles et autorisations",
    "COLLECTION_REVENUE": "Revenu de la collecte",
    "USER_MANAGEMENT": "Gestion des utilisateurs",
    "ADMIN_USERS": "Utilisateurs administrateurs",
    "APP_USERS": "Utilisateurs de l'application",
    "FARES": "Tarifs",
    "RUNTIME_PATTERNS": "Modèles d'exécution",
    "TIMETABLE": "Horaires",
    "E_TICKETING": "Collection Automatique Des Tarifs",
    "E_TICKETING_MINI": "CAT",
    "URBAN_FARE": "Tarifs urbains",
    "LINES": "Lignes",
    "SEGMENTS": "Sections",
    "NETWORK_AND_PLANNING": "Réseau",
    "STOPS": "Arrêts",
    "TRIPS": "Voyages ",
    "ZONES": "Zones",
    "SCHEDULE": "Planification",
    "SCHEDULE_SERVICES": "Création de Services",
    "SCHEDULE_EMPLOYEE_ROSTER": "Planification des Employés",
    "SCHEDULE_EMPLOYEE_ENGAGEMENT": "Engagement des employés",
    "INTERCITY_FARE": "Tarifs interurbains",
    "PASS_FARE": "Abonnements et Tarifs Spéciaux",
    "FRAUDS_AND_FINES": "Infractions et Amendes",
    "HOLIDAYS": "Jours fériés",
    "PLANS": "Planification des Services",
    "ADD_SERVICE_PLANS": "Planification des Services",
    "VEHICLE_DISPATCHING": "Répartition des Véhicules",
    "NFC_CARDS": "Cartes NFC",
    "DEVICES": "Dispositifs",
    "FLEET_MANAGEMENT": "Gestion de flotte",
    "VEHICLES": "Véhicules",
    "LIVE_TRACKING": "Suivi en direct",
    "NOTIFICATIONS": "Notifications",
    "PASSENGER_NOTIFICATIONS": "Notifications aux Passagers",
    "APP_NOTIFICATIONS": "Notifications de l'Application",
    "SYSTEM_SETTINGS": "Paramètres du Système",
    "URBAN_NETWORK_AND_PLANNING": "Réseau Urbain et Planification",
    "INTERCITY_NETWORK_AND_PLANNING": "Réseau Interurbain et Planification",
    "FLEET_MANAGEMENT_SETTINGS": "Gestion de la Flotte",
    "GENERAL_SETTINGS": "Général",
    "TRANSACTION_SETTLEMENT": "Règlement des Transactions",
    "DYNAMIC_TABLES": "Tables Dynamiques"
  },
  "TABLE": {
    "BASED_ON": "Basé sur",
    "PERMISSIONS": "Autorisations"
  },
  "CONSTANTS": {
    "DEACTIVATED": "Désactivé",
    "DELETED": "Supprimé",
    "ADMIN_USER": "Admin User",
    "APP_USER": "App User",
    "STOP": "Arrêt",
    "STATION": "Station",
    "SEGMENT": "Section",
    "URBAN": "Urbain",
    "INTERCITY": "Interurbain",
    "SINGLE": "Individuel",
    "BULK": "Groupé",
    "IN": "Entrée",
    "OUT": "Sortie",
    "ONBOARD": "À bord",
    "HANDHELD": "Portable",
    "COMMERCIAL": "Commercial",
    "POINT_OF_SALES": "Point de Vente",
    "FX925": "Valideur FX925",
    "FX205": "Mobile/Android FX205",
    "FX408": "Tablette/Android FX408",
    "INNER_HANOVER_DISPLAY": "Affichage intérieur Hanover",
    "OUTER_HANOVER_DISPLAY": "Affichage extérieur Hanover",
    "LAPTOP": "Ordinateur Portable",
    "CAMERA": "Caméra",
    "THERMAL_PRINTER": "Imprimante thermique",
    "CARD_PRINTER": "Imprimante de cartes",
    "FREE": "Abonnement Gratuit",
    "SPECIAL": "Abonnement Spécial",
    "TIME_BASED": "Abonnement Basé sur le Temps",
    "NORMAL_PRIORITY": "Priorité Normale",
    "SYSTEM": "Système",
    "HIGH_PRIORITY": "Haute Priorité",
    "ONE_TIME": "Une fois",
    "REPEATED_WEEKDAY": "Répéter les jours de la semaine",
    "REPEATED_WEEKEND": "Répéter les week-ends",
    "DAILY": "Tous les jours",
    "CUSTOM": "Date personnalisée",
    "STATUS": {
      "ACTIVE": "Actif",
      "INACTIVE": "Inactif",
      "DRAFT": "Brouillon",
      "PENDING": "En Attente",
      "PUBLISHED": "Publié",
      "IN_PROGRESS": "En cours",
      "READY": "Prêt",
      "CANCELLED": "Annulé",
      "DONE": "Terminé",
      "OUTOFSERVICE": "Hors service",
      "MAINTENANCE": "Maintenance",
      "SETTLED": "Réglé",
      "UNSETTLED": "Non réglé",
      "SENT": "Envoyé"
    },
    "ROLE": {
      "DRIVER": "Chauffeur",
      "ATTENDANT": "Receveur"
    }
  }
}
